<template>
    <v-sheet elevation="2">
        <v-toolbar elevation="0" color="primary" dark>
            <v-toolbar-title>My orders</v-toolbar-title>
            <v-spacer/>
            <v-toolbar-items>
                <v-btn @click="getOrders" text>
                    <v-icon left>mdi-refresh</v-icon>
                    Refresh
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <div v-if="orders.length === 0" class="pa-16 text-center">
            <v-icon x-large>mdi-exclamation-thick</v-icon>
            <p>No orders placed yet.<br>Your orders and details will be visible here, once you've placed your first order(s)</p>
        </div>
        <v-data-table
            v-else
            :items="orders"
            :headers="headers"
            mobile-breakpoint="0"
            sort-by="createdDate"
            sort-desc
            class="transparent"
            dense
        >
            <template v-slot:item.details="{item}">
                <user-order-details-dialog :order="item"/>
            </template>

            <template v-slot:item.name="{item}">
                {{item.domain}}
            </template>

            <template v-slot:item.createdDate="{item}">
                {{ddmmyyyy(item.createdDate)}}
            </template>
            <template v-slot:item.status="{item}">
                <v-chip :color="statusColor(item.status).color" :dark="statusColor(item.status).dark" x-small class="my-0">{{item.status}}</v-chip>
            </template>
            <template v-slot:item.domain="{item}">
                {{item.domain}}
            </template>

            <template v-slot:item.amount="{item}">
                {{item.articles.length}}
            </template>

            <template v-slot:item.price="{item}">
                {{toLocaleCurrency(item.orderPrice) }}
            </template>

            <template v-slot:item.orderedBy="{item}">
                <span style="white-space: nowrap;text-overflow: ellipsis;">{{item.orderedBy}}</span>
            </template>
            <template v-slot:item.id="{item}">
                <span style="white-space: nowrap;text-overflow: ellipsis;">KB-{{item.id}}</span>
            </template>

            <template v-slot:expanded-item="{item}">
                <td colspan="6" class="pt-4 pb-4">
                    <v-sheet class="pl-8 pr-8 transparent">
                        <v-row
                            v-for="(article,idx) in item.articles"
                            :key="'article-'+idx"
                        >
                            <v-col cols="12" class="py-0"><span>Article {{idx + 1}}</span></v-col>

                            <v-col cols="4" class="pt-0 pb-0">
                                <v-text-field
                                    v-model="article.anchor"
                                    placeholder="Anchor (keyword)"
                                    hide-details
                                    :solo-inverted="$vuetify.theme.dark"
                                    dense
                                    flat
                                    readonly
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="3" class="pt-0 pb-0">
                                <v-text-field
                                    v-model="article.url"
                                    placeholder="URL"
                                    hide-details
                                    :solo-inverted="$vuetify.theme.dark"
                                    dense
                                    flat
                                    readonly
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3" class="pt-0 pb-0">
                                <v-text-field
                                    v-model="article.publishDate"
                                    hide-details
                                    readonly
                                    :solo-inverted="$vuetify.theme.dark"
                                    dense
                                    flat
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </td>
            </template>

        </v-data-table>
    </v-sheet>
</template>

<script>
    import userManager from "@/services/userManager";
    import api from "@/services/api";
    import UserOrderDetailsDialog from "@/components/orders/userOrderDetailsDialog";

    export default {
        name: "userOrderHistory",
        components: {UserOrderDetailsDialog},
        data() {
            return {
                orders: [],
                headers: [
                    {text: '', value: 'details', width: 115, sortable: false},
                    {text: 'Status', value: 'status', width: 100},
                    {text: 'Domain', value: 'name'},
                    {text: 'Articles', value: 'amount', align: 'center', width: 108},
                    {text: 'Price', value: 'price', align: 'right', width: 120},
                    {text: 'Order date', value: 'createdDate', width: 115},
                    {text: 'Order id', value: 'id', width: 115},
                ],
                orderExpandArticleHeaders: [
                    {text: '', value: 'index', width: '20px', sortable: false},
                    {text: 'Anchor **', value: 'anchor'},
                    {text: 'URL', value: 'url'},
                    {text: 'Desired publish date', value: 'publishDate'},
                    {text: '', value: 'remove'},
                ],
                cvr: '',
                statusMessage: {
                    received: 'Received',
                    processing: 'Processing',
                    published: 'Published',
                },
                selectedOrder: {},
            }
        },
        methods: {
            getUser() {
                userManager.getUser(this.$store.state.auth.user.id)
                    .then(response => {
                        this.user = response.data
                    })
                    .catch(err => {
                        this.$store.dispatch('toasts/toastError', err.response.message)
                    })
            },
            getOrders() {
                api.get(`/orders/${this.$store.state.auth.user.id}`).then(response => {
                    this.orders = response.data
                })
            },
            yyyymmdd(date) {
                let [dd, mm, yyyy] = date.split('-')
                return `${yyyy}-${mm}-${dd}`
            },
            ddmmyyyy(date) {
                let [yyyy, mm, dd] = date.split(' ')[0].split('-')
                return `${dd}-${mm}-${yyyy}`
            },
            translateStatus(item) {
                return this.statusMessage[item.status]
            },
            toLocaleCurrency(number) {
                return (number * this.$store.state.exchangeRate[this.$store.state.currencyCode]).toLocaleString(this.$store.state.locale, {
                    style: 'currency',
                    currency: this.$store.state.currencyCode
                })
            },
            statusColor(status) {
                const colors = {
                    received: {
                        color: 'orange darken-4',
                        dark: true
                    },
                    processing: {
                        color: 'blue darken-1',
                        dark: true
                    },
                    published: {
                        color: 'green darken-1',
                        dark: true
                    },
                }
                return colors[status]
            },
        },
        mounted() {
            this.getOrders()
        }
    }
</script>

<style scoped>

</style>