<template>
    <v-sheet elevation="2">
        <v-toolbar dark color="primary" elevation="0" dense>
            <v-toolbar-title>Domain export</v-toolbar-title>
            <v-spacer/>
            <div>
                <v-btn color="primary lighten-1" dark @click="getDomains()">
                    Fetch domains
                </v-btn>
            </div>
        </v-toolbar>
        <div class="pa-4">
            <v-row>
                <v-col cols="12" lg="6" xl="3">
                    <v-row>
                        <v-col cols="6">
                            <v-text-field dense outlined hide-details v-model="options.price[0]" label="KB Price (low)"/>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field dense outlined hide-details v-model="options.price[1]" label="KB Price (high)"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field dense outlined hide-details v-model="options.purchasePrice[0]" label="purchasePrice (low)"/>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field dense outlined hide-details v-model="options.purchasePrice[1]" label="purchasePrice (high)"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field dense outlined hide-details v-model="options.domainRating[0]" label="domainRating (low)"/>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field dense outlined hide-details v-model="options.domainRating[1]" label="domainRating (high)"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field dense outlined hide-details v-model="options.organicTraffic[0]" label="Traffic (low)"/>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field dense outlined hide-details v-model="options.organicTraffic[1]" label="Traffic (high)"/>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" lg="6" xl="3">
                    <v-row>
                        <v-col cols="12">
                            <v-combobox
                                :items="countries"
                                v-model="options.countries"
                                small-chips
                                clearable
                                multiple
                                dense
                                outlined
                                hide-details
                                :disabled="loading"
                                label="Target country"
                                item-text="name"
                                item-value="name"
                                prepend-inner-icon="mdi-web"
                                :return-object="false"
                            >
                                <template v-slot:selection="{ attrs, item, select, selected }">
                                    <v-chip
                                        v-bind="attrs"
                                        small
                                        @click="select"
                                        class="mb-1"
                                        :disabled="loading"
                                        label
                                        outlined
                                        close
                                        v-if="typeof countries.find(x => x.country == item) == 'object'"
                                        @click:close="removeLanguageFromFilter(item)"
                                    >
                                        <img
                                            :src="'https://cdn.kaboozt.com/media/img/flags/4x3/'+countries.find(x => x.country == item).code.toLowerCase()+'.svg'"
                                            :alt="item.country" height="10px" style="border:1px solid #dadada;"/>

                                        <span class="ml-1">{{ item }}</span>
                                    </v-chip>
                                </template>
                            </v-combobox>
                        </v-col>
                    </v-row>
                    <v-row>

                        <v-col cols="12">
                            <v-text-field clearable readonly v-model="options.updatedDate" dense outlined hide-details label="Updated after">
                                <template v-slot:append>
                                    <date-time-picker @chosenDate="setUpdatedDate"/>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" lg="6" xl="3">
                    <v-row>
                        <v-col cols="12">
                            <v-text-field dense outlined hide-details v-model="options.itemsPerPage" label="Rows to fetch"/>
                        </v-col>
                        <v-col cols="12">
                            <v-select dense hide-details outlined v-model="options.sortBy[0]" :items="sortByOptions" label="Sort by"/>
                        </v-col>
                        <v-col cols="12">
                            <v-checkbox dense hide-details v-model="options.sortDesc[0]" label="Sort low -> high"/>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" lg="6" xl="3">
                    <v-row>
                        <v-col cols="12">
                            <v-btn dark color="secondary" @click="exportToCSV()" class="ml-2 float-right" :disabled="domains.length === 0">
                                Download CSV
                            </v-btn>
                        </v-col>
                        <v-col cols="12">
                            <v-btn dark color="teal" @click="exportToExcel()" class="ml-2 float-right" :disabled="domains.length === 0">
                                Download spreadsheet (xlsx)
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <v-alert type="warning" v-if="options.itemsPerPage >= 1000" icon="mdi-alert" dense class="mt-4">Caution: Fetching more than 1000 rows and having "show table" on, may crash your browser</v-alert>
                    <v-checkbox v-model="showTable" label="Show table (may lag your pc)" dense hide-details/>
                </v-col>
            </v-row>
            <v-divider class="my-4"/>
            <p v-if="!loading && domains.length === 0">Click "Fetch domains"</p>
            <p v-if="!loading && domains.length > 0">{{domains.length}} domains loaded</p>
            <v-progress-linear
                v-if="loading"
                indeterminate
                color="cyan"
            ></v-progress-linear>
            <v-simple-table dense v-if="showTable">
                <thead>
                <tr>
                    <th
                        v-for="header in _headers"
                    >{{header.text}}
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="domain in domains"
                    :key="'domain-'+domain.id"
                >
                    <td
                        style="max-width:250px"
                        v-for="(value,property) in domain"
                        :key="'val-'+domain.id+'-'+property"
                    >{{value}}
                    </td>
                </tr>
                </tbody>
            </v-simple-table>
            <!--v-data-table
                :items="domains"
                :headers="_headers"
                :loading="loading"
                :options.sync="options"
                dense
            /-->
        </div>
    </v-sheet>
</template>

<script>
    import api from "@/services/api";
    import {write, utils} from 'xlsx';
    import DateTimePicker from "@/components/dateTimePicker/dateTimePicker";

    export default {
        name: "domainExport",
        components: {DateTimePicker},
        data() {
            return {
                domains: [],
                showTable: false,
                loading: false,
                sortByOptions: ["casinoPrice", "cbdPrice", "cryptoPrice", "datingPrice", "domain", "domainRating", "eroticPrice", "kbPrice", "loanPrice", "morningScore", "organicTraffic", "purchasePrice", "referringDomains",],
                options: {
                    search: null,
                    categories: [],
                    specials: [],
                    price: [],
                    purchasePrice: [],
                    domainRating: [],
                    referringDomains: [],
                    organicTraffic: [],
                    countries: [],
                    languages: [],
                    updatedDate: null,
                    page: 1,
                    itemsPerPage: 100,
                    sortBy: ['purchasePrice'],
                    sortDesc: [false],
                    mustSort: false,
                    multiSort: false,
                },
                languages: [],
                countries: [],
                attrs: {
                    class: 'mb-6',
                    boilerplate: true,
                    elevation: 2,
                },

            }
        },
        methods: {
            getDomains() {
                this.loading = true
                api.get(`sites/export`, {
                    params: this.options
                }).then(response => {
                    this.domains = response.data.items
                    this.loading = false
                })
                    .catch(err => {
                        this.$store.dispatch('toasts/toastError', err.data.message)
                    })
            },
            setUpdatedDate(e) {
                this.options.updatedDate = e
            },
            convertArrayToCSV(data) {
                const header = Object.keys(data[0]).join(',');
                const rows = data.map(obj => Object.values(obj).join(','));
                return [header, ...rows].join('\n');
            },
            downloadCSV(csvContent, fileName) {
                const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
                const link = document.createElement('a');
                link.setAttribute('href', encodedUri);
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
            exportToCSV() {
                const data = this.domains
                const csvContent = this.convertArrayToCSV(data);
                this.downloadCSV(csvContent, 'data.csv');
            },
            exportToExcel() {
                const data = this.domains

                const worksheet = utils.json_to_sheet(data);
                const workbook = utils.book_new();
                utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
                const excelBuffer = write(workbook, {type: 'array', bookType: 'xlsx'});
                this.downloadExcel(excelBuffer, 'data.xlsx');
            },
            downloadExcel(buffer, fileName) {
                const data = new Blob([buffer], {type: 'application/octet-stream'});
                const url = URL.createObjectURL(data);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },

            getLanguages() {
                api.get('/sites/languages')
                    .then(response => {
                        this.languages = response.data
                    })
            },
            getCountries() {
                api.get('/sites/countries')
                    .then(response => {
                        this.countries = response.data
                    })
            },
            removeLanguageFromFilter(item) {
                this.options.languages = this.options.languages.filter(x => x !== item)
                this.getDomains()
            },
            removeCountryFromFilter(item) {
                this.options.countries = this.options.countries.filter(x => x !== item)
                this.getDomains()
            },
        },
        mounted() {
            this.getLanguages()
            this.getCountries()
        },
        computed: {
            _headers() {
                if (this.domains.length > 0) {
                    const firstObject = this.domains[0];
                    return Object.keys(firstObject).map(name => ({text: name, value: name}));
                }
                return [];
            }
        }
    }
</script>

<style scoped>

</style>