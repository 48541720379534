import './registerServiceWorker'
import 'tiptap-vuetify/dist/main.css'
import Vue from 'vue'
import VueMeta from 'vue-meta'
import routerService from "@/router/routerService";
import store from './store'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import 'vuetify/dist/vuetify.min.css'
import mixins from './mixins/mixins'
import {initializeApp} from 'firebase/app';
import {TiptapVuetifyPlugin} from 'tiptap-vuetify'
import VueI18n from 'vue-i18n'
import './ext/ext.js'
import "inter-ui/inter.css";
import './plugins/static/css/custom-styling.css'

initializeApp({
    apiKey: "AIzaSyBXBF0q-BV0fyjsS8bYvljM_oNClZrCQ5Y",
    authDomain: "kaboozt-1a3bc.firebaseapp.com",
    projectId: "kaboozt-1a3bc",
    storageBucket: "kaboozt-1a3bc.appspot.com",
    messagingSenderId: "1099371626721",
    appId: "1:1099371626721:web:62198a056874ebc98377a0"
})

Vue.config.productionTip = false

Vue.use(VueMeta)
Vue.use(VueI18n)
Vue.config.productionTip = false
Vue.mixin(mixins)
Vue.use(TiptapVuetifyPlugin, {
    vuetify,
    iconsGroup: 'mdi'
})
Vue.use(vuetify, {
    treeShake: true
})
export const bus = new Vue()

routerService
    .then(router => {
        new Vue({
            router,
            store,
            vuetify,
            render: h => h(App),
        }).$mount('#app')
    })