<template>
    <v-container class="pt-8" fluid>
        <v-card class="glass--bg mx-auto" max-width="800px">
            <v-card-title>Settings</v-card-title>
            <v-card-text>
                <user-domains-dropdown/>
                domains loaded: {{$store.state.backlinkModule.referringDomains.length}}
                <v-list>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Theme</v-list-item-title>
                            <span class="caption font-italic grey--text">Light / dark</span>
                        </v-list-item-content>
                        <v-list-item-action>
                            <theme-switch/>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Currency</v-list-item-title>
                            <span class="caption font-italic grey--text">Choose currency - Rates other than DKK are indicative and subject to change at purchase.</span>
                        </v-list-item-content>
                        <v-list-item-action>
                            <currency-switch/>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Locale</v-list-item-title>
                            <span class="caption font-italic grey--text">Change number formats</span>
                        </v-list-item-content>
                        <v-list-item-action>
                            <locale-switch/>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Contact e-mail</v-list-item-title>
                            <span class="caption font-italic grey--text">Your login user will remain the same</span>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-text-field
                                v-model="contactMail"
                                dense
                            />
                        </v-list-item-action>
                    </v-list-item>
                    <!--v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Your domains</v-list-item-title>
                            <span class="caption font-italic grey--text">Domains you work on. Will show up on the domain filter.</span>
                        </v-list-item-content>
                        <v-list-item-action>
                            <user-domains-dropdown/>
                        </v-list-item-action>
                    </v-list-item-->
                </v-list>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    import ThemeSwitch from "@/components/UI/themeSwitch";
    import api from "@/services/api";
    import userManager from "@/services/userManager";
    import UserDomainsDropdown from "@/components/sites/backlinkProfile/userDomainsDropdown";
    import LocaleSwitch from "@/components/locales/localeSwitch";
    import CurrencySwitch from "@/components/locales/currencySwitch";

    export default {
        name: "Settings",
        components: {CurrencySwitch, LocaleSwitch, UserDomainsDropdown, ThemeSwitch},
        data() {
            return {
                timeout: false
            }
        },
        methods: {
            updateContactMail(evt) {
                if (evt !== this.$store.state.auth.user.contactMail) {
                    userManager.updateSetting({
                        property: 'contactMail',
                        value: evt,
                    })
                }
            }
        },
        computed: {
            contactMail: {
                get() {
                    return this.$store.state.auth.user.settings.contactMail;
                },
                set(evt) {
                    clearTimeout(this.timeout)
                    this.timeout = setTimeout(() => {
                        this.$store.state.auth.user.settings.contactMail = evt
                        this.updateContactMail(evt)
                    }, 500);
                }
            }
        },
        mounted() {


        }
    }
</script>

<style scoped>

</style>