<template>
    <v-card>
        <v-toolbar dark dense class="purple" elevation="0">
            <v-toolbar-title>
            <span>
                <v-icon dark class="mr-2">mdi-new-box</v-icon>
                New domains
            </span>
            </v-toolbar-title>
            <v-spacer/>
            <span class="caption ml-2" v-if="domains.length >=1">+{{domains[0].newDomains}} last 7 days</span>
        </v-toolbar>

        <v-card-text class="px-0">
            <v-data-table
                class="transparent"
                :headers="headers"
                :items="domains"
                :dense="true"
                :loading="loading"
                :items-per-page="10"
                :hide-default-footer="true"
            >
                <template #item.image="{ item }">
                    <v-img :src="item.image" width="50" height="50"/>
                </template>
                <template v-slot:item.domain="{item}">
                    {{item.domain.replace('www.','').replace('http://','').replace('https://','').rtrim('/')}}
                </template>
                <template #item.kbPrice="{item}">
                    <span style="font-variant-numeric: tabular-nums;">{{toLocaleCurrency(item.kbPrice)}}</span>
                </template>
                <template #item.buy="{ item }">
                    <add-to-cart-button :orderItem="item"/>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
    import AddToCartButton from "@/components/cart/addToCartButton";
    import api from "@/services/api";

    export default {
        name: "newDomainsWidget",
        components: {AddToCartButton},
        data() {
            return {
                domains: [],
                loading: false,
                headers: [
                    {text: "", value: "buy", sortable: false},
                    {text: "Domain", value: "domain"},
                    {text: "Price", value: "kbPrice"},
                    {text: "DR", value: "domainRating"},
                ],
            };
        },
        created() {
            this.getNewDomains();
        },
        methods: {
            getNewDomains() {
                this.loading = true;
                api.get("/sites/new").then((response) => {
                    this.domains = response.data;
                    this.loading = false;
                });
            },
            toLocaleCurrency(number) {
                return (number * this.$store.state.exchangeRate[this.$store.state.currencyCode]).toLocaleString(this.$store.state.locale, {
                    style: 'currency',
                    currency: this.$store.state.currencyCode,
                    maximumFractionDigits: 0
                })
            },
        },
    }
</script>

<style scoped>

</style>