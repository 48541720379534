import api from "./api";

class uploadService {
    async upload(uploadArea, file, onUploadProgress) {
        return await new Promise((resolve, reject) => {
            let ret
            let formData = new FormData();
            formData.append('file', file)
            api.post('/file-manager/' + uploadArea, formData, {
                headers: {
                    "content-type": "multipart/form-data"
                },
                onUploadProgress
            })
                .then(response => {
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }

    async uploadBlogImage(file, onUploadProgress) {
        return await new Promise((resolve, reject) => {
            let ret
            let formData = new FormData()
            formData.append('file', file)
            api.post('blog/media/images', formData, {
                headers: {
                    "content-type": "multipart/form-data"
                },
                onUploadProgress
            })
                .then(response => {
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }

    async uploadFile(type, identifier, file, onUploadProgress) {
        return await new Promise((resolve, reject) => {
            let formData = new FormData()
            formData.append('file', file)
            formData.append('type', type)
            formData.append('identifier', identifier)
            api.post('file-manager/upload', formData, {
                header: {
                    "content-type": "multipart/form-data"
                },
                onUploadProgress
            })
                .then(response => {
                    resolve(response)
                })
        })
    }

    async importCSV(file, provider, test, onUploadProgress) {
        return await new Promise((resolve, reject) => {
            let formData = new FormData()
            formData.append('importCSV', file);
            formData.append('provider', JSON.stringify(provider))
            if (test) {
                formData.append('test', true)
            }
            api.post('domains/import', formData, {
                header: {
                    'content-type': 'multipart/form-data'
                },
                onUploadProgress
            })
                .then(response => {
                    if (typeof (response.data.error) === 'undefined') {
                        resolve(response)
                    }
                    reject(response)
                })

        })
    }
}

export default new uploadService()