import axios from 'axios'
import store from '../store'

let api = axios.create({
    baseURL: 'https://api.kaboozt.com/',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Referrer-Policy": "no-referrer"
    }
})

api.interceptors.request.use(
    config => {
        config.headers.common["locale"] = store.state.locale
        const user = JSON.parse(localStorage.getItem("user"))
        if (user) {
            config.headers.common["Authorization"] = 'Bearer ' + user.token
        }
        return config
    },
    error => {
        console.log('requestError:', error)
    }
)
api.interceptors.response.use(response => {
        if (response.status === 498) {
            alert('ARGH')
        }
        switch (response.status) {
            case 200:
            case 201:
                return Promise.resolve(response)
            default:
                return Promise.reject(response)
        }
    }, error => {
        switch (true) {
            case error.response.status === 401 && error.response.data.message === "auth/not-logged-in":
                return Promise.reject(error.response)
            case error.response.status === 401:
                window.location.pathname !== "/login" && store.dispatch('toasts/toastError', 'You are not authorized to access this page')
                if (error.response.data.message === 'auth/user-inactive') {
                    store.dispatch('toasts/toastError', 'Din bruger er ikke længere aktiv')
                }
                return Promise.reject(error.response)
            case error.response.status === 404:
                store.dispatch('toasts/toastError', '404: Page not found')
                return Promise.reject(error.response)
            case error.response.status === 498:
                store.dispatch('toasts/toastWarning', 'Din login-session er udløbet.')
                store.dispatch('auth/logout')
                return Promise.reject(error.response)
            default:
                return Promise.reject(error.response)
        }
    }
)


export default api