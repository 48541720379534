<template>
    <div v-if="$store.state.auth.status.loggedIn">
        <v-alert
            v-if="alert.message != ''"
            :type="alert.type"
            tile
            dense
            class="ma-0"
        >
            {{alert.message}}
        </v-alert>
    </div>
</template>

<script>
    import api from "@/services/api";

    export default {
        name: "siteWideAlert",
        data() {
            return {
                alert: {
                    type: 'info',
                    message: '',
                }
            }
        },
        methods: {
            getAlerts() {
                api.get('/alerts').then(response => {
                    this.alert = response.data
                })
            }
        },
        mounted() {
            this.getAlerts()
        }
    }
</script>

<style scoped>

</style>