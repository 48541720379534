<template>
    <v-container id="termsConditions" fluid class="pt-12">
        <v-row>
            <v-col cols="12" xl="6" lg="6" class="ma-auto">
                <h1><strong>Terms and Conditions</strong></h1>
                <p>These terms and conditions outline the rules and regulations for the use of Kaboozt's Website, located at Kaboozt.com.</p>
                <p>By accessing this website, we assume you accept these terms and conditions. Do not continue to use Kaboozt if you do not agree to take all the terms and conditions stated on this page.</p>
                <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "”Your" refers to you, the person log on this website and compliant to the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services, in accordance with and subject to, prevailing law of Netherlands. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.</p>
                <p>&nbsp;</p>
                <h2><strong>Cookies</strong></h2>
                <a href="https://kaboozt.com/cookies">Cookie Policy.</a>
                <p>&nbsp;</p>
                <h2><strong>Prices and terms of payment</strong></h2>
                <p>All trades are in DKK and exclusive of Danish VAT 25 %.</p>

                <p>Please note that the prices listed are subject to fluctuations, as certain publishers may wish to verify both the content and pricing prior to publication. As a result, the total amount reflected on your invoice might not match the initially listed prices. In the event of significant discrepancies, we will proactively reach out to you to explore alternative solutions, such as identifying comparable domains for publishing.
                    Consequently, the price reflected in your shopping cart, as well as the amounts shown in "Order Received" and "Order Confirmation" notifications or emails, may differ from the final invoice price. We value full transparency and your understanding that this is a dynamic pricing environment. We will remain committed to delivering the highest standards of service.</p>

                <p>If the client needs another currency at the invoice Kaboozt reserves the right to regulate the price according to Danish VAT.</p>
                <p>Kaboozt terms of payment are net 7 days.</p>
                <p>If the client exceeds the payment term, Kaboozt reserves the right to charge additional fees in accordance with Danish law.</p>
                <p>&nbsp;</p>
                <h2><strong>Validity of links</strong></h2>
                <p>Kaboozt guarantee a validity for two years (24 months) of any link purchased at <a href="http://Kaboozt.com" rel="noopener noreferrer nofollow">Kaboozt.com</a></p>
                <p>*Some publishers have another policy of validity, in these cases Kaboozt has the responsibility to inform the client before the purchase is approved.</p>
                <p>&nbsp;</p>
                <h2><strong>License</strong></h2>
                <p>Unless otherwise stated, Kaboozt and/or its licensors own the intellectual property rights for all material on Kaboozt. All intellectual property rights are reserved. You may access this from Kaboozt for your own personal use subjected to restrictions set in these terms and conditions.</p>
                <p>You must not:</p>
                <ul>
                    <li><p>Republish material from Kaboozt</p></li>
                    <li><p>Sell, rent or sub-license material from Kaboozt</p></li>
                    <li><p>Reproduce, duplicate or copy material from Kaboozt</p></li>
                    <li><p>Redistribute content from Kaboozt</p></li>
                </ul>
                <p>This Agreement shall begin on the date hereof.</p>
                <p>&nbsp;</p>
                <h2><strong>Hyperlinking to our content</strong></h2>
                <p>The following organizations may link to our website without prior written approval:</p>
                <ul>
                    <li><p>Government agencies</p></li>
                    <li><p>Search engines</p></li>
                    <li><p>News organizations</p></li>
                    <li><p>Online directory distributors may link to our website in the same manner as they hyperlink to the websites of other listed businesses</p></li>
                    <li><p>System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our website.</p></li>
                </ul>
                <p>These organizations may link to our home page, to publications or to other Website information as long as the link: </p>
                <ul>
                    <li>Is not in any way deceptive</li>
                    <li>Does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services</li>
                    <li>Fits within the context of the linking party’s site.</li>
                </ul>
                <p>We may consider and approve other link requests from the following types of organizations:</p>
                <ul>
                    <li><p>Commonly known consumer and/or business information sources</p></li>
                    <li><p>Dot.com community sites</p></li>
                    <li><p>Associations or other groups representing charities</p></li>
                    <li><p>Online directory distributors</p></li>
                    <li><p>Internet portals</p></li>
                    <li><p>Accounting, law and consulting firms</p></li>
                    <li><p>Educational institutions and trade associations.</p></li>
                </ul>
                <p>We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Kaboozt; and (d) the link is in the context of general resource information.</p>
                <p>These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.</p>
                <p>If you are one of the organizations listed above in paragraph 2, and are interested in linking to our website, you must inform us by sending an e-mail to Kaboozt. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our website, and a list of the URLs on our site to which you would like to link. The expected response time is 2-3 weeks.</p>
                <p>Approved organizations may hyperlink to our website as follows:</p>
                <ul>
                    <li><p>By use of our corporate name</p></li>
                    <li><p>By use of the uniform resource locator being linked to</p></li>
                    <li><p>By use of any other description of our website being linked to that makes sense within the context and format of content on the linking party’s site.</p></li>
                </ul>
                <p>No use of Kaboozt's logo or other artwork will be allowed for linking absent a trademark license agreement.</p>
                <p>&nbsp;</p>
                <h2><strong>iFrames</strong></h2>
                <p>Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our website.</p>
                <p>&nbsp;</p>
                <h2><strong>Content liability</strong></h2>
                <p>We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us against all claims that arise on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third-party rights.</p>
                <p>&nbsp;</p>
                <h2>Reservation of Rights</h2>
                <p>We reserve the right to request that you remove all links or any particular link to our website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it's linking policy at any time. By continuously linking to our website, you agree to be bound to and follow these linking terms and conditions.</p>
                <p>&nbsp;</p>
                <h2>Removal of links from our website</h2>
                <p>If you find any link on our website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links, but we are not obligated to or so or to respond to you directly.</p>
                <p>We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.</p>
                <p>&nbsp;</p>
                <h2>Disclaimer</h2>
                <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</p>
                <ul>
                    <li>Limit or exclude our or your liability for death or personal injury</li>
                    <li>Limit or exclude our or your liability for fraud or fraudulent misrepresentation</li>
                    <li>Limit any of our or your liabilities in any way that is not permitted under applicable law</li>
                    <li>Exclude any of our or your liabilities that may not be excluded under applicable law</li>
                    <li>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer:
                        <ol>
                            <li>Are subject to the preceding paragraph</li>
                            <li>Govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.</li>
                        </ol>
                    </li>
                </ul>
                <p>As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
                <p>&nbsp;</p>
                <h2><strong>Your Privacy</strong></h2>
                <p>Please read Privacy Policy and
                    <a href="https://kaboozt.com/cookies">Cookie Policy.</a>
                </p>
                <p>&nbsp;</p>
                <h2><strong>Contact us</strong></h2>
                <p>If you have any questions about our Terms and Conditions, please contact us:</p>
                <ul>
                    <li>By email: support@kaboozt.com</li>
                    <li>By visiting this page on our website: kaboozt.com</li>
                    <li>By phone number: +45 4242 1899</li>
                </ul>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "termsAndConditions"
    }
</script>

<style scoped>

</style>