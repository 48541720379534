import store from "./index";
import api from "../services/api";

const cart = JSON.parse(localStorage.getItem('cart'))
const initialState = cart
    ? {id: cart.id, orders: cart.orders, promo: '', userComment: cart.userComment}
    : {id: cartId, orders: [], promo: '', userComment: ''}

function cartId() {
    let u = this.$store.state.auth.user
    let d = new Date().toRegular()
    let ident = store.state.auth.user.uid + d.Y + d.m + d.d + d.H + d.i + d.s
    return ident
}

function randomId() {
    let r = (Math.random()).toString(36),
        a = (Math.random()).toString(36),
        n = (Math.random()).toString(36),
        d = r.substr(2, r.length - 2),
        o = a.substr(2, a.length - 2) + n.substr(2, n.length - 2),
        m = d + o
    return m;
}

export default {
    namespaced: true,
    state: initialState,
    mutations: {
        addToCart(state, product) {
            try {
                product.orderId = crypto.randomUUID()
                state.orders.push(product)
                localStorage.setItem('cart', JSON.stringify(state))
                store.dispatch('toasts/toastSuccess', `Added to cart`)
            } catch (e) {
                store.dispatch('toasts/toastWarning', 'Error adding to cart')
            }
        },
        removeFromCart(state, orderKey) {
            state.orders.splice(orderKey, 1)
            localStorage.setItem('cart', JSON.stringify(state))
        },
        emptyCart(state, payload) {
            if (!!payload) {
                state.orders = []
            } else if (confirm('Empty the cart?')) {
                state.orders = []
            }
            localStorage.setItem('cart', JSON.stringify(state))
        },
        addArticleToDomainOrder(state, orderIndex) {
            state.orders[orderIndex].articles.push({
                articleId: crypto.randomUUID(),
                name: '',
                anchor1: '',
                anchor2: '',
                url1: '',
                url2: '',
                keyword: '',
                file: '',
                image: '',
                logo: '',
                optionalData: {},
                userComment:'',
                publishDate: new Date().daysFromNow(3, true),
            })
            localStorage.setItem('cart', JSON.stringify(state))
        },
        removeArticleFromDomainOrder(state, payload) {
            if (state.orders[payload.orderIndex].articles.length >= 2) {
                state.orders[payload.orderIndex].articles.splice(payload.articleIndex, 1)
                localStorage.setItem('cart', JSON.stringify(state))
            }
        },
        setArticleDate(state, payload) {
            let orderIndex = state.orders.findIndex(x => x.orderId === payload.orderId)
            let articleIndex = state.orders[orderIndex].articles.findIndex(x => x.articleId === payload.articleId)
            state.orders[orderIndex].articles[articleIndex].publishDate = payload.newDate
            localStorage.setItem('cart', JSON.stringify(state))
        },
        addAttachment(state, payload) {
            let orderIndex = state.orders.findIndex(x => x.orderId === payload.orderId)
            state.orders[orderIndex].articles[payload.articleIndex][payload.type] = payload.file.data.location
            console.log(payload)
            if (payload.type === 'file') {
                state.orders[orderIndex].articles[payload.articleIndex].isFile = true
            }
            localStorage.setItem('cart', JSON.stringify(state))
        },
        addUnsplashAttachment(state, payload) {
            let orderIndex = state.orders.findIndex(x => x.orderId === payload.orderId)
            state.orders[orderIndex].articles[payload.articleIndex][payload.type] = payload.file.urls.regular
            state.orders[orderIndex].articles[payload.articleIndex].optionalData = {
                image: {
                    author: payload.file.user.name,
                    link: payload.file.user.links.html,
                    credit: 'Photo by <a href="' + payload.file.user.links.html + '?utm_source=unsplash&utm_medium=referral" target="_blank">' + payload.file.user.name + '</a> on <a href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral">Unsplash</a>'
                }
            }

            localStorage.setItem('cart', JSON.stringify(state))
        },
        removeUnsplashAttachment(state, payload) {
            let orderIndex = state.orders.findIndex(x => x.orderId === payload.orderId)
            state.orders[orderIndex].articles[payload.articleIndex][payload.type] = ''
            delete state.orders[orderIndex].articles[payload.articleIndex].optionalData.image
            localStorage.setItem('cart', JSON.stringify(state))
        },
        removeAttachment(state, payload) {
            let orderIndex = state.orders.findIndex(x => x.orderId === payload.orderId)
            state.orders[orderIndex].articles[payload.articleIndex][payload.type] = ''
            if (payload.type === 'file') {
                state.orders[orderIndex].articles[payload.articleIndex].isFile = false
            }
            localStorage.setItem('cart', JSON.stringify(state))
        },
        updateOrder(state, payload) {
            state = payload
            localStorage.setItem('cart', JSON.stringify(state))
        },
        confirmOrder(state) {

        }
    },
    actions: {
        addToCart({commit}, payload) {
            commit('addToCart', payload)
        },
        confirmOrder({commit, state}) {
            let payload = state
            if (typeof payload.user === 'undefined') {
                payload.user = {
                    id: store.state.auth.user.id,
                    name: store.state.auth.user.name,
                }
            }
            if (typeof payload.staffComment === 'undefined') {
                payload.staffComment = ''
            }
            return new Promise((resolve, reject) => {
                api.post('/orders/new', payload)
                    .then(response => {
                        switch (response.data.message) {
                            case 'orders/order-received':
                                store.dispatch('toasts/toastSuccess', 'Order received')
                                commit('emptyCart', true) // true === skip confirmation
                                resolve('order-confirmed')
                                break;
                            case 'orders/order-duplicate':
                                store.dispatch('toasts/toastError', 'Duplicate order detected')
                                break;
                            case 'orders/order-detail-duplicate':
                                store.dispatch('toasts/toastError', 'Duplicate article detected')
                                break;
                            default:
                                store.dispatch('toasts/toastError', 'Error placing order, please contact support@kaboozt.com, for assistance.')
                                break;
                        }
                        reject('order-failed')
                    })
                // let result = commit('confirmOrder')
            })
        },
        removeFromCart({commit, state}, orderItem) {
            let orderIndex = state.orders.findIndex(x => x.orderId === orderItem.orderId)
            commit('removeFromCart', orderIndex)
        },
        removeArticleFromDomainOrder({commit, state}, payload) {
            const orderIndex = state.orders.findIndex(x => x.orderId = payload.orderId)
            const articleIndex = state.orders[orderIndex].articles.findIndex(x => x.articleId === payload.articleId)
            commit('removeArticleFromDomainOrder', {orderIndex: orderIndex, articleIndex: articleIndex})
        },
        emptyCart({commit}, payload = false) {
            commit('emptyCart', payload)
        },
        addArticleToDomainOrder({commit, state}, payload) {
            let idx = state.orders.findIndex(x => x.id === payload.id)
            commit('addArticleToDomainOrder', idx)
        },
        setArticleDate({commit}, payload) {
            commit('setArticleDate', payload)
        },
        addAttachment({commit}, payload) {
            commit('addAttachment', payload)
        },
        removeAttachment({commit}, payload) {
            commit('removeAttachment', payload)
        },
        prepareOrder({commit}, payload) {
            commit('prepareOrder', payload)
        },
        updateOrder({commit}, payload) {
            commit('updateOrder', payload)
        },
        addUnsplashAttachment({commit}, payload) {
            commit('addUnsplashAttachment', payload)
        },
        removeUnsplashAttachment({commit}, payload) {
            commit('removeUnsplashAttachment', payload)
        },
    }
}