<template>
    <div class="fill-height d-flex flex-column align-items-space-between">
        <v-sheet class="orange darken-1 py-4 px-6 mb-2 flex-grow-1 d-flex flex-nowrap justify-content-center" style="align-items:center" elevation="2" dark rounded>
            <v-toolbar-title style="width:100%">
                <router-link :to="{path:'/profile'}" style="text-decoration: none;color:#fff">
                    <v-icon class="mr-2">mdi-timer-pause</v-icon>
                    <span style="font-size:18px;font-weight: bold">Awaiting confirmation</span>
                </router-link>
            </v-toolbar-title>
            <div class="text-center" style="font-size: 32px; font-weight: bold">
                {{received}}
            </div>
        </v-sheet>
        <v-sheet class="blue darken-1 py-4 px-6 mb-2 flex-grow-1 d-flex flex-nowrap justify-content-center" style="align-items:center" elevation="2" dark rounded>
            <v-toolbar-title style="width:100%">
                <router-link :to="{path:'/profile'}" style="text-decoration: none;color:#fff">
                    <v-icon class="mr-2">mdi-refresh</v-icon>
                    <span style="font-size:18px;font-weight: bold">Processing</span>
                </router-link>
            </v-toolbar-title>
            <div class="text-center" style="font-size: 32px; font-weight: bold">
                {{processing}}
            </div>
        </v-sheet>
        <v-sheet class="green darken-1 py-4 px-6 flex-grow-1 d-flex flex-nowrap justify-content-center" style="align-items:center" elevation="2" dark rounded>
            <v-toolbar-title style="width:100%">
                <router-link :to="{path:'/profile'}" style="text-decoration: none;color:#fff">
                    <v-icon class="mr-2">mdi-publish</v-icon>
                    <span style="font-size:18px;font-weight: bold">Published</span>
                </router-link>
            </v-toolbar-title>
            <div class="text-center" style="font-size: 32px; font-weight: bold">
                {{published}}
            </div>
        </v-sheet>
    </div>
</template>

<script>
    import api from "@/services/api";

    export default {
        name: "userOrders",
        data() {
            return {
                orders: []
            }
        },
        methods: {
            getOrders() {
                api.get('orders/' + this.$store.state.auth.user.id).then(response => {
                    this.orders = response.data
                })
            }
        },
        created() {
            this.getOrders()
        },
        computed: {
            received() {
                return this.orders.filter(x => {
                    return x.status === 'received'
                }).length
            },
            processing() {
                return this.orders.filter(x => {
                    return x.status === 'processing'
                }).length

            },
            published() {
                return this.orders.filter(x => {
                    return x.status === 'published'
                }).length

            }
        },
        watch: {
            '$router.currentRoute': {
                handler() {
                    if (this.$router.currentRoute.path === '/') {
                        this.getOrders()
                    }
                }
            }
        }

    }
</script>

<style scoped>

</style>