<template>
    <div>
        <div :class="!$vuetify.theme.dark ? 'white': ''" class="elevation-1" dense>
            <v-container fluid class=" d-flex align-items-center">
                <span class="font-weight-bold" style="font-size: 18px">Publishers <span style="font-size: 12px" class="mt-1 ml-2">({{totalItems.toLocaleString($store.state.locale)}})</span></span>
                <v-spacer/>
                <search-console-upload class="ml-2 hidden-sm-and-down" style="max-width:350px" @domainsChanged="reRender()"/>
                <user-domains-dropdown class="ml-2 hidden-sm-and-down" style="max-width:350px" @domainsChanged="reRender()"/>
            </v-container>
        </div>
        <v-container fluid v-if="$store.state.auth.status.authorized">
            <!--v-toolbar flat tile class="transparent">
                <v-toolbar-title>Publishers ({{totalItems.toLocaleString($store.state.locale)}})</v-toolbar-title>
                <v-spacer/>
                <search-console-upload class="ml-2 hidden-sm-and-down" style="max-width:350px" @domainsChanged="reRender()"/>
                <user-domains-dropdown class="ml-2 hidden-sm-and-down" style="max-width:350px" @domainsChanged="reRender()"/>
            </v-toolbar-->
            <site-overview :key="overviewKey" @publishersLoaded="setTotalItems"/>
        </v-container>
    </div>
</template>

<script>
    import SiteOverview from "@/components/sites/siteOverview";
    import SearchConsoleUpload from "@/components/sites/backlinkProfile/search-console/searchConsoleUpload";
    import UserDomainsDropdown from "@/components/sites/backlinkProfile/userDomainsDropdown";
    import PageHeader from "@/components/UI/elements/pageHeader";

    export default {
        name: "Market",
        components: {PageHeader, UserDomainsDropdown, SearchConsoleUpload, SiteOverview},
        data() {
            return {
                overviewKey: 0,
                totalItems: 0
            }
        },
        methods: {
            resetFilters() {
                console.log('reset')
            },
            reRender() {
                this.overviewKey = this.overviewKey + 10
            },
            setTotalItems(evt) {
                this.totalItems = evt
            }
        }
    }
</script>

<style scoped>

</style>