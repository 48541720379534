<template>
    <v-footer elevation="0" class="transparent mt-2">
        <v-spacer/>

        <a href="https://kaboozt.com/cookies" class="subtitle-2">Cookie Policy.</a>
        <span class="grey--text subtitle-2 mx-1">|</span>
        <router-link :to="{path:'/terms'}" class="subtitle-2">Terms and conditions</router-link>
        <span class="grey--text subtitle-2 mx-1">|</span>
        <span class="grey--text subtitle-2">&copy; Kaboozt ApS {{new Date().getFullYear()}}</span>
        <v-spacer/>
    </v-footer>
</template>

<script>

    export default {
        name: "siteFooter",
        data() {
            return {}
        }
    }
</script>

<style>
</style>