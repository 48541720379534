<template>
    <v-app-bar
        app
        clipped-left
        dark
        elevate-on-scroll
        elevation="4"
        height="64"
        class="topMenu"
    >
        <v-container class="d-flex align-items-center" fluid>
            <router-link
                link
                exact
                :to="{name:'Dashboard'}"
                active-class="white--text"
            >
                <img src="https://cdn.kaboozt.com//media/img/kaboozt%20logo_kb_stort_b.svg" alt="Dashboard" height="32px" style="    height: 32px;"/>
            </router-link>
            <div class="app-bar--menu hidden-sm-and-down">
                <router-link
                    v-for="(route,i) in $router.options.routes"
                    v-if="!route.meta.naviHide"
                    :key="i"
                    exact
                    link
                    :to="{path:route.path}"
                    class="nav--link"
                    active-class="white--text"
                >
                    <v-icon>{{route.meta.icon}}</v-icon>
                    {{route.meta.menuTitle}}
                </router-link>
            </div>
            <v-spacer/>
            <div class="app-bar--badges d-flex flex-nowrap align-items-center">
                <cart-badge/>
                <profile-badge/>
            </div>
            <v-app-bar-nav-icon
                class="hidden-md-and-up"
                @click="$store.state.nav.drawer.show = !$store.state.nav.drawer.show"
            />
        </v-container>
    </v-app-bar>
</template>

<script>
    import cartBadge from "../cart/cartBadge";
    import LoginDialog from "../user/loginDialog";
    import NotificationBadge from "../user/notificationBadge";
    import ProfileBadge from "../user/profileBadge";
    import LocaleSwitch from "@/components/locales/localeSwitch";
    import api from "@/services/api";
    import UserDomainsDropdown from "@/components/sites/backlinkProfile/userDomainsDropdown";

    export default {
        name: "appBar",
        data() {
            return {
                pageName: '',
                options: {
                    duration: 500,
                    easing: 'easeInOutCubic',
                },
                barBackground: 'menu',

            }
        },
        components: {UserDomainsDropdown, ProfileBadge, NotificationBadge, LoginDialog, cartBadge},
        methods: {
            getRoutes() {
                api.get('routes')
                    .then(response => {
                        this.routes = response.data
                    })
            }
        },
        computed: {
            currentCartCounter() {
                return this.$store.state.cartModule.orders.length
            },
        },
        mounted() {
        },
        watch: {
            '$router.options.routes': {
                handler() {
                    this.pageName = this.$router.currentRoute.name
                },
                deep: true,
                immediate: true
            },

        }
    }
</script>

<style>

    header.nav--top,
    header.nav--top > div {
        transition: 225ms ease;
    }

    header.nav--top {
        transition: 225ms ease;
    }

    /*
      header.nav--top.v-app-bar--is-scrolled,
      header.nav--top.the--app {
      }


      .align-items-center {
        align-items: center;
      }

      .logo--svg {
        padding: 12px;
      }
      */
    .topMenu .v-toolbar__content {

        background-color: rgb(31 41 55) !important;
    }

    .app-bar--menu {
        padding: 0 16px;
    }

    header .nav--link {
        font-family: 'Work Sans';
        display: inline-block;
        text-decoration: none;
        padding: 0 16px;
        line-height: 56px;
        font-weight: 500;
        font-size: 14px;
        color: rgb(209 213 219) !important;
        border-bottom: 3px solid rgba(0, 0, 0, 0);
        margin-bottom: -5px
    }

    .nav--link.router-link-exact-active {
        border-color: #fff;
    }

</style>
