<template>
    <v-switch
        v-model="switchState"
        @click="toggleDark"
    />
</template>

<script>
    import userManager from "@/services/userManager";

    export default {
        name: "themeSwitch",
        methods: {
            toggleDark: function () {
                this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
                userManager.updateSetting({
                    property: 'darkTheme',
                    value: this.$vuetify.theme.dark.toString()
                })
            }
        },
        computed: {
            switchState: {
                get() {
                    let isDark = this.$vuetify.theme.dark
                    return isDark
                },
                set() {
                    return
                }
            }
        }
    }
</script>

<style scoped>

</style>