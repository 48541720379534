<template>
    <div :class="!$vuetify.theme.dark ? 'white': ''" class="elevation-1" dense>
        <v-container fluid>
            <v-row dense>
                <v-col cols="6" class="justify-start"><span class="font-weight-bold" style="font-size: 18px" v-html="contentLeft"></span></v-col>
                <v-col cols="6" class="d-flex justify-end"><span class="font-weight-bold" style="font-size: 18px" v-html="contentRight"></span></v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: "pageHeader",
        props: ['content-left', 'content-right'],
    }
</script>

<style scoped>

</style>