import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import store from '@/store'
import {preset} from 'vue-cli-plugin-vuetify-preset-rally/preset'

Vue.use(Vuetify);
export default new Vuetify({
    preset,
    theme: {
        dark: false,
        themes: {
            light: {
                background:'#1f2937',
                accent: '#1dd4bb',
                error: '#e03947',
                warning: '#f99d56',
                menu: '#091e2e',

            },
            dark: {
                primary:'#1976d2',
                background:'#1976d2',
                //primary: '#fd6b28',
                //secondary: '#ee8e0a',
                accent: '#1dd4bb',
                error: '#e03947',
                warning: '#f99d56',
                menu: '#091e2e',
            },
        },
    }

});
