<template>
    <v-container id="termsConditions" class="py-12">
        <v-row>
            <v-col cols="12" xl="6" lg="6" class="pa-4 pb-12 mx-auto">
                <h1>{{content[locale].header}}</h1>
                <div class="CookieDeclaration"></div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

    export default {
        name: "Cookies",
        data() {
            return {
                content:{
                    'en-US':{
                        header:'Cookie policy',
                        cookieHash:'fe8fb515-e20b-436c-bd81-7deadac26247',
                    },
                    'da-DK':{
                        header:'Cookiepolitik',
                        cookieHash:'fe8fb515-e20b-436c-bd81-7deadac26247'
                    }
                }
            }
        },
        computed:{
           locale(){
               return typeof this.$store.state !== "undefined" ? this.$store.state.locale : 'en-US'
           }
        },
        mounted() {
            let cookiebotScript = document.createElement('script')
            let cookieDiv = document.getElementsByClassName('CookieDeclaration')[0]
            cookiebotScript.setAttribute('src', "https://consent.cookiebot.com/"+this.content[this.locale].cookieHash+"/cd.js")
            cookiebotScript.setAttribute('id', 'CookieDeclaration')
            cookieDiv.appendChild(cookiebotScript)
        }
    }
</script>

<style scoped>

</style>