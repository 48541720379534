<template>
    <v-card elevation="2">
        <v-toolbar color="orange" dark elevation="0"dense>
            <v-toolbar-title>News</v-toolbar-title>
        </v-toolbar>
        <v-list>
            <v-list-item three-line>
                <v-list-item-content>
                    <v-list-item-title>Feature: Save your filters</v-list-item-title>
                    <p>You can now set your filters in the Market, and click "Save current filters". These filters will be applied every time you load the Market, untill you clear them.</p>
                    <v-dialog v-model="lightboxVisible" max-width="480">
                        <v-card @click="lightboxVisible = false">
                            <v-img
                                class="mx-auto"
                                src="https://cdn.kaboozt.com//media/img/news/feature_filter_save.PNG"
                                alt="save filters in market"
                                width="100%"
                                height="auto"
                                contain
                            ></v-img>
                        </v-card>
                    </v-dialog>
                    <v-img
                        class="elevation-4 mx-auto my-4"
                        max-width="260px"
                        contain
                        src="https://cdn.kaboozt.com//media/img/news/feature_filter_save.PNG"
                        @click="lightboxVisible = true"
                    ></v-img>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-card>
</template>

<script>
    export default {
        name: "newsWidget",
        data() {
            return {
                lightboxVisible: false,
            };
        },
    }
</script>

<style scoped>
    .thumbnail {

    }
</style>