import api from "./api";
import {createUserWithEmailAndPassword, getAuth} from "firebase/auth";
import store from '@/store'

class userManager {
    activateUser(uid) {
        api.put(`user-management/users/${uid}/activate`)
            .then(response => {
                response.data.status === 200 && store.dispatch('toasts/toastSuccess', 'User activated')
            })
    }

    deactivateUser(uid) {
        api.put(`user-management/users/${uid}/deactivate`)
            .then(response => {
                response.data.status === 200 && store.dispatch('toasts/toastSuccess', 'User deactivated')
            })
    }

    createUser(user, firebaseInfo) {
        const data = {
            name: user.name,
            mail: user.mail,
            phone: user.mail,
            uid: firebaseInfo.user.uid,
            cvr: typeof user.company !== 'undefined' ? user.company.cvr : '',
            company: user.company.name
        }
        user.uid = firebaseInfo.user.uid
        return new Promise((resolve, reject) => {
            api.post('user-management/users', user)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.code)
                })
        })
    }

    deleteUser(uid) {
        return new Promise((resolve, reject) => {
            api.put(`user-management/users/${uid}/delete`)
                .then(response => {
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }

    async getUser(id) {
        return await new Promise((resolve, reject) => {
            api.get('users/' + id)
                .then(resolve)
                .catch(reject)
        })
    }

    createFirebaseUser(user) {
        return new Promise((resolve, reject) => {
            const auth = getAuth()
            createUserWithEmailAndPassword(auth, user.mail, user.pass)
                .then(response => {
                    this.createUser(user, response)
                        .then(localResponse => {
                            console.log('localResponse: ', localResponse)
                            resolve(localResponse)
                        })
                    store.dispatch('auth/passwordResetOnCreate', response.user.email)
                })
                .catch(error => {
                    switch (error.code) {
                        case 'auth/email-already-in-use':
                            store.dispatch('toasts/toastError', 'E-mail already in use')
                            this.errorMessage = 'E-mail already in use.'
                            this.userCreateLoad = false
                            break;
                        case 'auth/missing-email':
                            this.errorMessage = 'E-mail missing.'
                            this.userCreateLoad = false
                            break;
                        default:
                            break;
                    }
                })
        })
    }


    setUserRole(uid, role) {
        api.put(`user-management/roles/${uid}`, {role: role})
            .then(response => {
                if(response.data === true){
                    store.dispatch('toasts/toastSuccess', 'User role changed')
                }else if (response.data.message = 'user-management/unauthorized'){
                    store.dispatch('toasts/toastWarning', 'You do not have permission to set user role this high.')
                }
            })
            .catch(error => {
                switch (error.data.code) {
                    case 'user-management/user-unauthorized':
                        store.dispatch('toasts/toastError', 'Error 401: Unauthorized action.')
                        break;
                    default:
                        store.dispatch('toasts/toastError', 'Error')
                        break;
                }
            })
    }

    getRoles() {
        return new Promise((resolve, reject) => {
            api.get('user-management/roles')
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                    store.dispatch('toasts/toastError', error.message)
                })
        })
    }


    async getPermissions() {
        let permissions = []
        return await new Promise((resolve, reject) => {
            api.get('user-management/permissions')
                .then(response => {
                    if (response.data.status === 200) {
                        permissions = response.data.message
                    }
                    resolve(permissions)
                })
                .catch(error => {
                    reject(error)
                    store.dispatch('toasts/toastError', error.message)
                })
        })
    }

    addArea(payload) {
        return new Promise((resolve, reject) => {
            api.post('user-management/permissions', payload)
                .then(response => {
                    resolve(response.data)
                    store.dispatch('toasts/toastSuccess', 'Nyt område tilføjet')
                })
                .catch(error => {
                    reject(error)
                    store.dispatch('toasts/toastError', error.message)
                })
        })
    }

    changePermission(payload) {
        return new Promise((resolve, reject) => {
            api.put('user-management/permissions', payload)
                .then(response => {
                    resolve(response.data)
                    switch (response.data.message) {
                        case 'user-management/roles-updated':
                            store.dispatch('toasts/toastSuccess', 'Permissions updated')
                            break;
                        default:
                            break;
                    }

                })
                .catch(error => {
                    reject(error)
                    store.dispatch('toasts/toastError', error.message)
                })
        })
    }

    async updateSetting(setting) {
        return await new Promise((resolve, reject) => {
            api.put('user-management/settings', setting)
                .then(response => {
                    switch (response.data.message) {
                        case 'user-management/setting-changed':
                        case 'user-management/setting-created':
                            store.dispatch('auth/refreshUserInfo')
                            resolve(response)
                            break;
                        case 'user-management/error-creating-setting':
                        case 'user-management/error-updating-setting':
                            store.dispatch('toasts/toastError', 'Setting could not be changed')
                            reject()
                            break;
                        default:
                            store.dispatch('toasts/toastWarning', 'Unknown error')
                            reject()
                    }
                })
        })
            .catch(err => {
                console.log(err)
            })

    }

}

export default new userManager();