<template>
    <v-sheet class="mb-4" elevation="2">
        <v-toolbar elevation="0" color="primary"  dark>
            <v-toolbar-title>Contact info</v-toolbar-title>
        </v-toolbar>
        <div>
            <v-simple-table dense>
                <tbody>
                <tr>
                    <td>E-mail</td>
                    <td><a :href="'mailto:'+user.mail" style="text-decoration: none;">
                        <v-icon small>mdi-email-outline</v-icon>
                        {{user.mail}}</a></td>
                </tr>
                <tr>
                    <td>Phone</td>
                    <td><span>{{user.phone}}</span></td>
                </tr>
                <tr>
                    <td>Website</td>
                    <td><span>{{user.company.website}}</span></td>
                </tr>
                </tbody>
            </v-simple-table>
        </div>
    </v-sheet>
</template>

<script>
    export default {
        name: "userProfileInfo",
        props: ['user'],
        data() {
            return {}
        },
        methods: {}
    }
</script>

<style scoped>

</style>