<template>
  <terms-and-conditions/>
</template>

<script>
    import TermsAndConditions from "@/components/termsAndConditions";

    export default {
        name: "Terms",
        components: {TermsAndConditions}
    }
</script>

<style scoped>

</style>