import { render, staticRenderFns } from "./cartBadge.vue?vue&type=template&id=fde7a836&scoped=true&"
import script from "./cartBadge.vue?vue&type=script&lang=js&"
export * from "./cartBadge.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fde7a836",
  null
  
)

export default component.exports