<template>
    <v-card class="pa-2 fill-height" color=""  elevation="2" outlined>
        <v-toolbar dense elevation="0">
            <v-toolbar-title>
                <span class="text-h6">Customer Support</span>
            </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <p>If you need support or have questions, don't hesitate to reach out. Our team at Kaboozt is always ready to assist and ensure you get high-quality, timely content that drives results</p>
            <div>
                <v-icon>mdi-email-outline</v-icon>
                <a href="mailto:info@Kaboozt.com">info@Kaboozt.com</a>
            </div>
            <div class="my-3"></div>
            <div>
                <v-icon>mdi-phone-outline</v-icon>
                <a href="tel:+4542421899">+45 4242 1899</a>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: "supportWidget",
        data() {
            return {
                showSupportInfo: false
            }
        },
    }
</script>

<style scoped>
    a {
        margin-left: 10px;
    }
</style>
