<template>
    <v-app>
        <navigation-drawer v-if="$store.state.auth.user.authorized"/>
        <app-bar v-if="$store.state.auth.user.authorized"/>
        <v-main class="pt-16">
            <site-wide-alert/>
            <!--meta-toolbar/-->
            <router-view/>
        </v-main>
        <site-footer/>
        <snack-bar/>
    </v-app>
</template>

<script>

    import AppBar from "@/components/navigation/appBar";
    import NavigationDrawer from "@/components/navigation/navigationDrawer";
    import SiteFooter from "@/components/UI/siteFooter";
    import SnackBar from "@/components/toasts/toasts";
    import MetaToolbar from "@/components/UI/metaToolbar";
    import SiteWideAlert from "@/components/UI/widgets/siteWideAlert";
    import PageHeader from "@/components/UI/elements/pageHeader";

    export default {
        name: 'App',
        components: {
            PageHeader,
            SiteWideAlert,
            MetaToolbar,
            SnackBar,
            SiteFooter,
            NavigationDrawer,
            AppBar
        },
        data() {
            return {
                items: []
            }
        },
        computed: {
            breakpoint(){
                return this.$vuetify.breakpoint.name
            }
        },
        mounted() {
            this.loadSettings()
        },
        methods: {
            loadSettings() {
                let user = localStorage.getItem("user")
                if (user) {
                    user = JSON.parse(user)
                    this.$vuetify.theme.dark = user.settings.darkTheme === "true"
                } else if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
                    this.$vuetify.theme.dark = true
                    localStorage.setItem("darkTheme", this.$vuetify.theme.dark.toString())
                }

            },
        },

    };
</script>