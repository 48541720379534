<template>
    <div :style="typeof width !== 'undefined' ? 'width:'+width+'px' : ''" v-if="response.length >= 1">
        <v-card-text :style="typeof height !== 'undefined' ? 'height:'+height+'px' : 'height:400px'">
            <lineChart :data="chartData" :options="chartOptions"/>
        </v-card-text>
    </div>
    <div v-else>
        <p class="caption">Price history: no changes</p>
    </div>
</template>

<script>
    import {Line as lineChart, Bar as barChart} from 'vue-chartjs'
    import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, TimeScale, PointElement, LineElement, Title, Tooltip, Legend} from 'chart.js'
    import 'chartjs-adapter-date-fns';
    import api from "@/services/api";

    ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend, TimeScale)

    export default {
        name: "priceHistory",
        props: ['identifier', 'height', 'width'],
        components: {
            lineChart,
            barChart
        },
        data() {
            return {
                response: [],
            }
        },
        mounted() {
            this.getPriceChanges();
        },
        methods: {
            getPriceChanges() {
                api.get('/domains/price-changes/' + this.identifier).then(response => {
                    this.response = response.data
                })
            },
            structureJSONtoChartJS(jsonData) {
                let datasets = {};
                let minDate, maxDate;

                // Process JSON data into datasets
                jsonData.forEach(item => {
                    if (!datasets[item.columnName]) {
                        datasets[item.columnName] = {data: []};
                    }

                    let date = new Date(item.updatedDate);
                    let dateVal = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());

                    if (!minDate || dateVal < minDate) {
                        minDate = dateVal;
                    }
                    if (!maxDate || dateVal > maxDate) {
                        maxDate = dateVal;
                    }

                    datasets[item.columnName].data.push({
                        x: dateVal,
                        y: parseFloat(item.newValue)
                    });
                });

                // Fill in the gaps for every day between the first and last date for each key
                let labels = [];
                let previousValues = {};
                for (let d = minDate; d <= maxDate; d += 24 * 60 * 60 * 1000) {
                    labels.push(new Date(d));
                    for (let key in datasets) {
                        if (!datasets[key].data.some(item => item.x === d)) {
                            datasets[key].data.push({
                                x: d,
                                y: previousValues[key] || datasets[key].data[datasets[key].data.length - 1].y
                            });
                        }
                        previousValues[key] = datasets[key].data.find(item => item.x === d)?.y || null;
                    }
                }

                // Convert data to ChartJS format
                let chartJSDatasets = [];
                for (let key in datasets) {
                    chartJSDatasets.push({
                        label: key,
                        data: datasets[key].data.sort((a, b) => a.x - b.x), // Sort the data by date
                        fill: false,
                        backgroundColor: '#' + Math.floor(Math.random() * 16777215).toString(16),
                        tension: 0.1
                    });
                }
                return {labels: labels, datasets: chartJSDatasets};
            },

        },
        computed: {
            chartData() {
                return this.structureJSONtoChartJS(this.response); // Remove the object wrapping
            },
            chartOptions() {
                return {
                    scales: {
                        x: {
                            type: 'time',
                            time: {
                                unit: 'day',
                                displayFormats: {
                                    day: 'd/M Y'
                                }
                            }
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                }
            }
        }
    }
</script>