<template>
    <v-file-input
            v-model="file"
            accept=".csv"
            @change="convertToJSON"
            hide-details
            dense
            flat
            class="elevation-0"
            outlined
            prepend-icon=""
            :placeholder="$store.state.backlinkModule.referringDomains.length + ' backlinks  loaded'"
    >

        <template v-slot:prepend-inner>
            <v-tooltip bottom max-width="480px">
                <template v-slot:activator="{on, attrs}">
                    <img src="/img/google.svg" width="50px" class="mt-1" v-on="on" @click="guideDialog = !guideDialog"/>
                </template>
                Upload a Google Search Console backlink CSV to match up with our list of domains in the marketplace.<br>Click here to read the guide.
            </v-tooltip>
            <v-dialog v-model="guideDialog" max-width="640px">
                <v-sheet>
                    <v-toolbar color="primary" dark>
                        <v-toolbar-title>Search Console backlink upload guide</v-toolbar-title>
                        <v-spacer/>
                        <v-btn @click="guideDialog = false" icon>
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <div class="pa-4">
                        <ol>
                            <li>Log in to Google Search Console</li>
                            <li>Click "Links" in the left hand menu
                                <br>
                                <img src="https://cdn.kaboozt.com//media/img/gsc_step_1.PNG"/></li>
                            <li>Click "More" under "Top linking sites"</li>
                            <li>Click "Export" in the top right corner</li>
                            <li>Upload the CSV directly to Kaboozt</li>
                        </ol>
                    </div>
                    <v-footer>
                        <v-spacer/>
                        <v-btn @click="guideDialog = false" text right>Close</v-btn>
                    </v-footer>
                </v-sheet>
            </v-dialog>
        </template>
        <template v-slot:append>
            <v-btn @click="resetGSC" small icon v-if="$store.state.backlinkModule.referringDomains.length > 0">
                <v-icon small>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-file-input>
</template>

<script>
    import UploadFile from "@/components/fileManagement/uploadFile";

    export default {
        name: "searchConsoleUpload",
        components: {UploadFile},
        data() {
            return {
                guideDialog: false,
                file: null
            }
        },
        methods: {
            convertToJSON: async function () {
                if (!this.file) {
                    this.$store.dispatch('toasts/toastWarning', 'No file selected')
                    return
                }
                const csv = await this.readFile(this.file)
                const json = this.csvToJson(csv)
                this.$store.dispatch('backlinkModule/setSearchConsoleCSV', json)
                this.$emit('domainsChanged')

            },
            readFile: function (file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.onload = () => {
                        resolve(reader.result)
                    }
                    reader.onerror = reject
                    reader.readAsText(file)
                })
            },
            csvToJson: function (csv) {
                const lines = csv.trim().split('\n')
                const headers = lines.shift().split(',')
                const result = []
                for (const line of lines) {
                    const values = line.split(',')
                    const obj = {}
                    for (let i = 0; i < headers.length; i++) {
                        obj.refdomain = values[0]
                        obj.link_type = 'gscBacklink'

                    }
                    result.push(obj)
                }
                return result
            },
            resetGSC() {
                this.$store.dispatch('backlinkModule/unsetBacklinks')
                this.$emit('domainsChanged')
                this.file = null
            }
        }
    }
</script>

<style scoped>

</style>