import api from "../services/api"
import store from "./index"
import {bus} from "@/main";
import userManager from "@/services/userManager";

const refdomains = JSON.parse(localStorage.getItem('refdomains'))
const initial = refdomains ? refdomains : {referringDomainsLoading: false, referringDomains: [],}
initial.referringDomainsLoading = false

export default {
    namespaced: true,
    state: initial,
    mutations: {
        setDomains(state, payload) {
            if(typeof payload !== 'object'){
                return
            }
            state.referringDomains = payload
            localStorage.setItem('refdomains', JSON.stringify(state))
        },
        setLoadingStatus(state, status) {
            state.referringDomainsLoading = status
            localStorage.setItem('refdomains', JSON.stringify(state))
        },
        unsetBacklinks(state) {
            state.referringDomains = []
            localStorage.setItem('refdomains', JSON.stringify(state))
        }
    },
    actions: {
        getReferringDomains({commit}, domain) {
            commit('setLoadingStatus', true)
            if (domain == '') {
                commit('unsetBacklinks')
                return
            }
            api.get('/ahrefs/refdomains?domain=' + domain).then(response => {
                if (response.data.length > 0) {
                    commit('setDomains', response.data)
                    store.dispatch('toasts/toastSuccess', 'Referring domains loaded')
                } else {
                    if (response.data.message === "ahrefs/no-backlinks-found") {
                        commit('setDomains', [])
                        store.dispatch('toasts/toastWarning', 'Ahrefs does not have any registered LIVE backlinks for the domain: ' + domain)
                    }
                }
                commit('setLoadingStatus', false)
            })
                .catch(err => {
                    console.log(err)
                    commit('setLoadingStatus', false)
                })
        },

        setSearchConsoleCSV({commit}, payload) {
            commit('setDomains', payload)
            userManager.updateSetting({
                property: 'preferredDomain',
                value: ''
            })
        },
        unsetBacklinks({commit}) {
            commit('unsetBacklinks')
        }

    },
}