<template>
    <v-sheet elevation="2" v-if="discounts.length > 0">
        <v-toolbar elevation="0" color="primary" dark dense>
            <v-toolbar-title>My discounts</v-toolbar-title>
        </v-toolbar>
        <v-alert
            v-if="discounts.userDiscount >= 1"
            dense
            type="success"
            color="green"
            text
            class="my-4"
        >
            <span class="font-weight-bold">You have a {{ discounts.userDiscount }} % discount on all domains</span>
        </v-alert>
        <v-simple-table dense>
            <thead>
            <tr>
                <th>Domain specific discounts</th>
                <th>Discount % / Discount DKK</th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="discount in discounts.domainSpecific"
                :key="'discount-'+discount.id"
            >
                <td>{{discount.domain}}</td>
                <td style="font-variant-numeric: tabular-nums;">{{discountType(discount)}}</td>
            </tr>
            </tbody>
        </v-simple-table>
    </v-sheet>
</template>

<script>
    import api from "@/services/api";

    export default {
        name: "discountWidget",
        data() {
            return {
                discounts: [],

            }
        },
        methods: {
            getUserDiscounts() {
                api.get('users/' + this.$store.state.auth.user.id + '/discounts').then(response => {
                    this.discounts = response.data
                })
            },
            discountType(item) {
                return (item.discountFlat * 1) === 0 ? item.discountPercentage + ' %' : this.toLocaleCurrency(item.discountFlat)
            },
            toLocaleCurrency(number) {
                return (number * this.$store.state.exchangeRate[this.$store.state.currencyCode]).toLocaleString(this.$store.state.locale, {
                    style: 'currency',
                    currency: this.$store.state.currencyCode
                })
            },
        },
        mounted() {
            this.getUserDiscounts()
        }
    }
</script>

<style scoped>

</style>