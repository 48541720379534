<template>
    <v-sheet>
        <v-toolbar flat dark dense color="primary">
            <v-toolbar-title>Processing</v-toolbar-title>
            <v-spacer/>
            <v-btn text @click="refreshOrders">Refresh
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
        </v-toolbar>
        <v-data-table
            :items="orders"
            :headers="headersProcessing"
            item-key="articleId"
            class="py-4"
            sort-desc
            sort-by="createdDate"
            dense
        >
            <template v-slot:item.completeOrder="{item}">
                <complete-order :order="item"/>
            </template>
            <template v-slot:item.createdDate="{item}">
                {{formatDate(item.createdDate)}}
            </template>

            <template v-slot:item.amount="{ item }">
                {{ item.length }}
            </template>

            <template #item.orderId="{item}">
                <div style="overflow: hidden;text-overflow:ellipsis;white-space: nowrap">{{item.orderId}}</div>
            </template>

            <template v-slot:item.userName="{item}">
                <router-link
                    :to="{path:'/profile/'+item.userId}">
                    {{item.userName}}
                </router-link>
            </template>
        </v-data-table>
    </v-sheet>
</template>

<script>
    import api from "@/services/api";
    import CompleteOrder from "./completeOrder";
    import {bus} from "@/main";

    export default {
        name: "ordersProcessing",
        components: {CompleteOrder},
        data() {
            return {
                orders: [],
                headersProcessing: [
                    {text: ' ', value: 'completeOrder', width: 86, sortable: false},
                    {text: 'Order date', value: 'createdDate', width: 140},
                    {text: 'Created by', value: 'createdBy', width: 220},
                    {text: 'Client', value: 'userName', width: 220},
                    {text: 'Publish date', value: 'publishDate', width: 140},
                    {text: 'Domain', value: 'domain', width: 120},
                    {text: 'Price', value: 'price', align: 'right', width: 120},
                    {text: 'Order-id', value: 'orderId', width: '140px'},
                ],
            }
        },
        methods: {
            getOrders() {
                api.get('/orders/status/processing')
                    .then(response => {
                        this.orders = !response.data ? [] : response.data
                        this.$emit('ordersFetched', !response.data ? 0 : response.data.length)
                    })
                    .catch(() => {
                        this.$store.dispatch('toasts/toastError', 'User unauthorized')
                        this.$emit('ordersFetched', 'err')
                    })
            },
            formatDate(dateString) {
                const [Y, m, d] = dateString.split(' ')[0].split('-')
                return `${d}-${m}-${Y}`
            },
            refreshOrders() {
                bus.$emit('orderUpdate', true)
            },
        },
        mounted() {
            this.getOrders()
        },
        created() {
            bus.$on('orderUpdate', () => {
                this.getOrders()
            })
        },
        beforeDestroy() {
            bus.$off('orderUpdate')
        }
    }
</script>

<style scoped>
</style>