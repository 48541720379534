<template>
    <div>
        <v-dialog max-width="1140" v-model="reviewDialog">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-on="on"
                    @click="reviewDialog"
                    x-small
                    outlined
                >Review
                </v-btn>
            </template>
            <v-card class="glass--bg-overlay" elevation="0">
                <v-toolbar dense dark color="primary" elevation="0">
                    <v-toolbar-title>Approve order from user: {{article.userId}}</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <p style="font-size: 16px; font-weight:bolder" class="py-4">
                        Article on: {{article.domain}}
                        <v-btn icon x-small @click="newTab(article.domain)">
                            <v-icon x-small>mdi-open-in-new</v-icon>
                        </v-btn>
                        <br>
                        <span v-if="article.identifier !== null && article.identifier.indexOf('wd-') >= 0">This is a WeDigitize domain</span>
                    </p>
                    <v-row>
                        <v-col cols="12" xl="3" lg="3">
                            <div v-if="article.file !== null" class="d-flex justify-space-around" style="width:100%; ">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{on,attr}">
                                        <v-btn @click="newTab(article.file)" outlined small width="100%"
                                               style="overflow: hidden;text-overflow: ellipsis;justify-content: normal">
                                            <v-icon>mdi-download</v-icon>
                                            <span v-on="on">{{article.file}}</span>
                                        </v-btn>
                                    </template>
                                    {{article.file}}
                                </v-tooltip>
                            </div>
                            <upload-file
                                v-else
                                type="article"
                                :identifier="article.articleId"
                                :dialog-header="'' +
                                     '<p><strong>Document name must be:</strong> Domain - article<br>' +
                                     '<strong>Example:</strong> ' + article.domain + ' - ' + 'Article name</p>' +
                                     '<p><strong>Accepted file types:</strong> doc, docx </p>'"
                                @uploaded="addAttachment($event)"
                                @removeUpload="removeAttachment($event)"
                                :file-already-uploaded="article.file"
                                style="width:100%;"
                            />
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="article.anchor"
                                label="Anchor"
                                dense
                                @change="changed = true"
                            />
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="article.url"
                                label="URL"
                                dense
                                @change="changed = true"
                            />
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="article.publishDate"
                                label="Desired publish date"
                                dense
                                @change="changed = true"
                            />
                        </v-col>
                    </v-row>
                    <v-text-field label="Order comments (client receives this in an e-mail)"
                                  v-model="confirmComment"></v-text-field>
                    <v-card-actions>
                        <v-spacer/>
                        <v-btn
                            @click="confirmArticle()"
                            color="primary"
                            :dark="!(confirmationLoading || article.file === null)"
                            :loading="confirmationLoading"
                            :disabled="confirmationLoading || article.file === null"
                        >Approve order
                        </v-btn>
                        <v-btn outlined color="red" dark @click="cancelDialog = true">Cancel order</v-btn>
                    </v-card-actions>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="cancelDialog" max-width="460px" @click:outside="cancelDoubleCheck = false">
            <v-sheet>
                <v-toolbar color="red" dark flat>
                    <v-toolbar-title>Cancel this order?</v-toolbar-title>
                </v-toolbar>
                <div class="px-4 pt-4">
                    <p>Are you certain you wish to cancel this article?</p>
                    <p>The client will receive an e-mail with the cancellation as well.</p>
                </div>
                <div class="d-flex px-4 pb-4">
                    <v-spacer/>
                    <v-simple-checkbox v-model="cancelDoubleCheck" :value="cancelDoubleCheck"/>
                    <v-btn @click="cancelOrder(article.articleId)" :disabled="!cancelDoubleCheck" color="red"
                           :dark="cancelDoubleCheck" elevation="2">Yes I am sure
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>
    </div>
</template>

<script>
    import api from "../../services/api";
    import {bus} from "@/main";
    import UploadFile from "@/components/fileManagement/uploadFile";

    export default {
        name: "reviewOrder",
        components: {UploadFile},
        props: ['order'],
        data() {
            return {
                confirmComment: 'Your article has been approved',
                changed: false,
                originalOrder: [],
                article: [],
                confirmationLoading: false,
                reviewDialog: false,
                cancelDialog: false,
                cancelDoubleCheck: false,
                selectedOrder: {},
                orderDetailsDialog: false,
                panels: 0,
            }
        },
        methods: {
            confirmOrder() {
                this.confirmationLoading = true
                api.put('/orders/confirm', {
                    order: this.article,
                    comment: this.confirmComment,
                    changed: this.changed
                })
                    .then(response => {
                        if (response.data.status === 200) {
                            this.confirmationLoading = false
                            this.reviewDialog = false
                            if (response.data.message === "orders/confirmed") {
                                this.$emit('orderConfirmed')
                            }
                            this.$store.dispatch('toasts/toastSuccess', 'Article approved')
                            bus.$emit('orderUpdate', true)
                        }
                    })

            },
            addAttachment(file) {
                if (!file) {
                    return
                }
                this.article.file = file.data.location
                api.put('orders/article/' + this.article.articleId, {
                    file: this.article.file
                }).then(response => {
                    console.log(response)
                    switch (response.data.message) {
                        case 'orders/article-updated':
                            this.$store.dispatch('toasts/toastSuccess', 'File attached to article succesfully')
                            break;
                        default:
                            this.$store.dispatch('toasts/toastError', 'Fukken erra bra!')
                    }
                })
            },
            removeAttachment(file) {
                this.article.file.file = ''
            },
            cancelOrder(articleId) {
                this.cancelDialog = true
                console.log(articleId)
            },
            log(obj) {
                console.log(obj)
            },
            newTab(fileLocation) {
                window.open(fileLocation, '_blank');
            },
            confirmArticle() {

            },
            toLocaleCurrency(number) {
                return (number * this.$store.state.exchangeRate[this.$store.state.currencyCode]).toLocaleString(this.$store.state.locale, {
                    style: 'currency',
                    currency: this.$store.state.currencyCode
                })
            },
            statusColor(status) {
                const colors = {
                    received: {
                        color: 'orange darken-4',
                        dark: true
                    },
                    processing: {
                        color: 'blue',
                        dark: true
                    },
                    published: {
                        color: 'green',
                        dark: true
                    },
                }
                return colors[status]
            },
        },
        created() {
            this.article = this.order
        }
    }
</script>

<style scoped>

</style>