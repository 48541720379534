<template>
    <v-autocomplete
        :items="domains"
        placeholder="Client website"
        :value="value"
        hide-details
        dense
        flat
        outlined
        clearable
        @click:clear="$store.dispatch('backlinkModule/unsetBacklinks')"
        @change="setPreferredDomain"
        :loading="$store.state.backlinkModule.referringDomainsLoading"
    >
        <template v-slot:prepend-inner>
            <v-tooltip left>
                <template v-slot:activator="{on,attrs}">
                    <div class="mt-1 " style="white-space: nowrap;display:flex">
                        <img
                            v-if="$vuetify.theme.dark"
                            src="/img/ahrefs-logo.svg" height="14px"
                            v-on="on"
                            alt="ahrefs logo"
                        />
                        <img
                            v-else
                            src="/img/ahrefs-logo_dark.svg" height="14px"
                            v-on="on"
                            alt="ahrefs logo"
                        />
                        <span>&nbsp;|&nbsp;({{$store.state.backlinkModule.referringDomains.length}})</span>
                    </div>
                </template>
                <span>Load ahrefs data for your domain</span>
            </v-tooltip>
        </template>
        <template v-slot:item="{item,on,attrs}">
            <v-list-item
                dense
                ripple
                v-on="on"
            >
                <v-list-item-icon class="mt-2 ml-0">
                    <v-icon>mdi-web</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{item}}</v-list-item-title>
                <v-list-item-action class="ma-0">
                    <v-btn fab small text @click="removeUserDomain(item)">
                        <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </template>
        <template v-slot:prepend-item>
            <v-divider/>
            <v-dialog
                v-model="dialog"
                max-width="800px"
            >
                <template v-slot:activator="{on,attrs}">
                    <v-list-item
                        dense
                        ripple
                        v-on="on"
                        @mousedown.prevent
                        @click="dialog = !dialog"
                    >
                        <v-list-item-icon class="mt-2 mr-2 ml-0">
                            <v-icon>mdi-plus</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Add domain
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <v-sheet>
                    <v-toolbar color="primary" dark flat>
                        <v-toolbar-title>Add domain to your list</v-toolbar-title>
                        <v-spacer/>
                        <v-toolbar-items>
                            <v-btn @click="dialog = false" icon right>
                                <v-icon small>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <div class="pa-4">
                        <p>Add your own website to get backlink information from ahrefs to be shown in market.</p>
                        <p><strong>Example: </strong>mywebsite.com or www.mywebsite.com</p>
                        <p>Then hit save and chose your domain from the drop-down.</p>
                        <v-text-field
                            label="Type your URL"
                            :rules="rules"
                            v-model="newUrl"
                            @keydown.enter="addUserDomain"
                            autofocus
                        />
                    </div>
                    <v-footer>
                        <v-spacer/>
                        <v-btn color="primary" class="mr-3" @click="addUserDomain" :disabled="!isURL">Save</v-btn>
                        <v-btn outlined color="primary" @click="()=>{dialog = false; newUrl = ''}">Cancel</v-btn>
                    </v-footer>
                </v-sheet>
            </v-dialog>
        </template>
    </v-autocomplete>
</template>

<script>
    import userManager from "@/services/userManager";

    export default {
        name: "userDomainsDropdown",
        data() {
            return {
                domains: [],
                dialog: false,
                newUrl: "",
                rules: [
                    (value) => !!value || "Required.",
                    () => this.isURL || "URL is not valid"
                ],
                value: '',
            }
        },
        mounted() {
            if (typeof this.$store.state.auth.user.settings.preferredDomain !== 'undefined') {
                this.value = this.$store.state.auth.user.settings.preferredDomain
            } else {
                this.value = ''
            }
            this.domains = this.$store.state.auth.user.settings.myDomains.sort()
        },
        computed: {
            isURL() {
                let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                    '(\\#[-a-z\\d_]*)?$', 'i')
                return pattern.test(this.newUrl)
            },
        },
        methods: {
            addUserDomain() {
                let currentDomains = typeof this.$store.state.auth.user.settings.myDomains !== 'undefined' ? this.$store.state.auth.user.settings.myDomains : []
                if (!Array.isArray(currentDomains)) {
                    currentDomains = []
                }
                currentDomains.push(this.newUrl)

                userManager.updateSetting({
                    property: 'myDomains',
                    value: currentDomains
                })
                    .then(response => {
                        console.log(response)
                        switch (response.data.message) {
                            case "user-management/setting-created":
                            case "user-management/setting-changed":
                                this.$store.dispatch('toasts/toastSuccess', 'Domain added to your list')
                                this.newUrl = ''
                                this.dialog = false
                                break;
                            case  "user-management/error-creating-setting":
                            case "user-management/error-updating-setting":
                                this.$store.dispatch('toasts/toastWarning', 'Errors abound: Something went wrong trying to add the domain to your list')

                        }

                        this.domains = this.$store.state.auth.user.settings.myDomains.sort()
                    })

            },
            removeUserDomain(domain) {
                let currentDomains = typeof this.$store.state.auth.user.settings.myDomains !== 'undefined' ? this.$store.state.auth.user.settings.myDomains : []
                if (!Array.isArray(currentDomains)) {
                    currentDomains = []
                }
                let newDomains = currentDomains.filter(item => item !== domain)
                userManager.updateSetting({
                    property: 'myDomains',
                    value: newDomains
                }).then(()=>{
                    this.domains = this.$store.state.auth.user.settings.myDomains.sort()
                })
            },
            setPreferredDomain(domain) {
                userManager.updateSetting({
                    property: 'preferredDomain',
                    value: domain
                }).then(() => {
                    if (domain === null) {
                        this.$store.dispatch('backlinkModule/unsetBacklinks')
                        this.$emit('domainsChanged')
                    } else {
                        this.$emit('domainsChanged')
                        this.$store.dispatch('backlinkModule/getReferringDomains', domain)
                    }
                })
            }
        }
    }
</script>

<style>
    .v-select.v-input--dense .v-select__selection--comma {
        margin: 2px 4px 3px 0 !important;
    }
</style>