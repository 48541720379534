import Vue from 'vue'
import VueRouter from 'vue-router'
import api from "../services/api";
import store from '../store'
import Cookies from "@/views/Cookies";
import Terms from "@/views/Terms";
import Login from "@/views/Login";
import router from "@/router/index";

Vue.use(VueRouter)

class routerService {

    // Basic routes everybody has access to
    routes = [
        {
            path: '/cookies',
            name: 'Cookies',
            meta: {
                en: {
                    title: 'Cookie policy | Kaboozt',
                },
                da: {
                    title: 'Cookiepolitik | Kaboozt',
                },
                icon: 'mdi-cookie',
                naviHide: true,
                guarded: false,
                menuTitle: 'Cookie policy'
            },
            component: Cookies,
        },
        {
            path: '/terms',
            name: 'Terms',
            meta: {
                en: {
                    title: 'Terms and condition | Kaboozt',
                },
                da: {
                    title: 'Handelsbetingelser | Kaboozt',
                },
                icon: 'mdi-cookie',
                naviHide: true,
                guarded: false,
                menuTitle: 'Terms'
            },
            component: Terms,
        },
        {
            name: 'Login',
            path: '/login',
            meta: {
                en: {
                    title: 'Log in to Kaboozt',
                },
                da: {
                    title: 'Log ind på Kaboozt',
                },
                title: 'Login',
                icon: 'mdi-account',
                naviHide: true,
                guarded: false,
                menuTitle: ''
            },
            component: Login,
        },
        {
            name: 'Auth',
            path: '/auth',
            meta: {
                en: {
                    title: 'Auth | Kaboozt',
                },
                da: {
                    title: 'Auth | Kaboozt',
                },
                title: 'User authorization',
                icon: 'mdi-account',
                naviHide: true,
                guarded: false,
                menuTitle: 'Auth'
            },
            component: () => import('@/views/Auth.vue'),
        },
        {
            name: 'error404',
            path: '*',
            meta: {
                en: {
                    title: '404 | Page not found',
                },
                da: {
                    title: '404 | Siden blev ikke fundet',
                },
                title: '404 page',
                icon: 'mdi-error',
                naviHide: true,
                guarded: false,
                menuTitle: '404'
            },
            component: () => import('@/views/errorPages/error404.vue'),
        }
    ]

    /**
     * Create vue-router
     * @returns {Promise<router>}
     */
    constructor() {
        return new Promise((resolve, reject) => {
                // Load API routes
                this.loadRoutes()
                    .then(routes => {
                        return new VueRouter({
                            mode: 'history',
                            base: process.env.BASE_URL,
                            routes,
                        })
                    })
                    .then(router => {
                        // Secondary Navigation guard and redirects (primary on API)
                        router.beforeResolve(async (to, from, next) => {

                            await this.isAuthenticated(to, from)
                                .then(authorized => {
                                    switch (true) {
                                        case !store.state.auth.status.loggedIn && to.path === '*':
                                        case !authorized && to.path !== '/login':
                                            router.push('/login')
                                            break;
                                        case store.state.auth.status.loggedIn && to.path === '/login' || to.path === '/':
                                            router.push('/dashboard')
                                            break;
                                        case !store.state.auth.status.loggedIn && authorized && to.path === '/login':
                                        case store.state.auth.status.loggedIn && authorized && to.path !== '/login':
                                        case authorized && (to.path === '/login' || to.path === '/auth'):
                                            next()
                                            break;

                                        default:
                                            console.log('case: default')
                                            break;
                                    }
                                })
                                .catch(authorized => {
                                    console.log('catch:', authorized)
                                    router.push('/login')
                                })
                        })
                        resolve(router)
                    })
                    .catch(err => {
                        reject(err)
                    })
            }
        )
    }

    /**
     * Check route before entering, used in navigation guard beforeResolve
     * @param to
     * @param from
     * @returns {Promise<boolean>}
     */
    async isAuthenticated(to, from) {
        return await new Promise((resolve, reject) => {
            let route = to.matched[0].path === '*' ? 'routes/404' : 'routes' + to.matched[0].path
            if (route === 'routes//') {
                resolve(false)
            }
            api.get(route)
                .then(response => {
                    switch (response.data.message) {
                        case 'routes/authorized':
                            resolve(true)
                            break;
                        case 'routes/unauthorized':
                        default:
                            reject(false)
                            break;
                    }
                })
                .catch(err => {
                    // store.dispatch('auth/logout')
                    console.log('isAuthenticated catch', err)
                    if (route === 'routes/404') {
                        resolve(true)
                    }
                    resolve(false)
                })
        })
    }

    /**
     * Return index of route if already loaded
     * @param route
     * @returns {number}
     */
    hasRoute(route) {
        return this.routes.findIndex(x => x.name === route.name)
    }

    /**
     * Load routes from database
     * @returns {Promise<unknown>}
     */
    async loadRoutes() {
        return await new Promise((resolve, reject) => {
            api.get('routes')
                .then(response => {
                    response.data.forEach((obj, idx) => {
                        obj.component = () => import('@/views/' + obj.view + '.vue')
                        if (this.hasRoute(obj) >= 0) {
                            this.routes[this.hasRoute(obj)] = obj
                        } else {
                            this.routes.push(obj)
                        }
                    })
                    resolve(this.routes)
                })
                .catch(err => {
                    console.log(err)
                })
        })
    }
}

export default new routerService()