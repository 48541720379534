<template>
    <v-dialog
        v-model="dialog"
        max-width="480px"
        v-if="!uploadComplete"
    >
        <v-sheet>
            <v-toolbar color="primary" dark dense>
                <v-toolbar-title>
                    Upload file
                </v-toolbar-title>
                <v-spacer/>
                <v-toolbar-items>
                    <v-btn @click="dialog = false" icon>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
                <div class="text--primary" v-if="dialogHeader" v-html="dialogHeader"></div>
                <v-file-input
                    @change="selectFile"
                    dense
                    :error="message.length >= 1"
                    :messages="message"
                    placeholder="Browse files..."
                />
                <v-progress-linear :value="uploadProgress" v-if="uploading"></v-progress-linear>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-spacer/>
                <v-btn color="primary" @click="uploadFile">Upload</v-btn>
            </v-card-actions>
        </v-sheet>
        <template v-slot:activator="{ on }">
            <v-btn
                v-model="dialog"
                v-on="on"
                :disabled="buttonDisabled"
                color="primary"
                class="ma-auto"
                width="100%"
            >
                <v-icon left>{{buttonLogo}}</v-icon>
                {{buttonText}}
            </v-btn>
        </template>
    </v-dialog>
    <div v-else class="d-flex justify-space-around">
        <v-menu
            offset-y
            style="top: 38px;"
            min-width="300px"
            :close-on-content-click="false"
        >
            <template v-slot:activator="{on}">
                <v-btn v-on="on" icon >
                    <v-icon >mdi-paperclip</v-icon>
                </v-btn>
            </template>
            <v-sheet>
                <v-btn text width="100%" @click="resetUpload()">
                    <checkmark-animated :fail="true" :table-cell="true" class="ma-auto mx-1"/>
                    <span class="ml-2">Remove {{uploadedFile.location.split('/').pop().split('?').shift()}}</span>
                </v-btn>
            </v-sheet>
        </v-menu>
        <v-tooltip bottom>
            <template v-slot:activator="{on}">
                <div v-on="on" class="text-no-wrap" style="text-overflow: ellipsis;width: 100%;overflow: hidden;padding:6px 0;">{{uploadedFile.location.split('/').pop().split('?').shift()}}</div>
            </template>
            {{uploadedFile.location.split('/').pop().split('?').shift()}}
        </v-tooltip>
    </div>
</template>

<script>

    import uploadService from "@/services/uploadService";
    import CheckmarkAnimated from "@/components/UI/elements/checkmarkAnimated";

    export default {
        name: "uploadFile",
        props: ['type', 'identifier', 'dialog-header', 'label', 'logo', 'fileAlreadyUploaded', 'disabled'],
        components: {CheckmarkAnimated},
        data() {
            return {
                buttonLogo: 'mdi-upload',
                dialog: false,
                currentFile: false,
                message: '',
                uploading: false,
                uploadProgress: 0,
                uploadComplete: false,
                uploadedFile: {
                    location: ''
                },
                buttonText: 'Upload'
            }
        },
        mounted() {
            if (typeof this.fileAlreadyUploaded === 'string' && this.fileAlreadyUploaded.trim() !== "") {
                this.uploadComplete = true
                this.uploadedFile.location = this.fileAlreadyUploaded
            }

            if (typeof this.label === 'string') {
                this.buttonText = this.label
            }
            if (typeof this.logo === 'string') {
                this.buttonLogo = this.logo
            }
            console.log()
        },
        computed: {
            buttonDisabled() {
                return typeof this.$props.disabled !== 'undefined' ? this.$props.disabled : false
            }
        },
        methods: {
            deleteFile() {

            },
            selectFile(file) {
                this.uploadProgress = 0
                this.message = ""
                this.currentFile = file
            },
            resetUpload() {
                console.log('resetUpload()')
                this.$emit('removeUpload', this.uploadedFile)
                this.dialog = false
                this.currentFile = false
                this.message = ''
                this.uploading = false
                this.uploadProgress = 0
                this.uploadComplete = false
                this.uploadedFile = ''
            },
            uploadFile() {
                if (!this.currentFile) {
                    this.message = "Choose file for upload"
                    return
                }
                this.message = ""

                this.uploading = true
                uploadService.uploadFile(this.type, this.identifier, this.currentFile, (event) => {
                    this.uploadProgress = Math.round((100 * event.loaded) / event.total)
                })
                    .then(response => {
                        this.uploadProgress = 100
                        this.currentFile = false
                        this.uploading = false
                        console.log(response.data)
                        switch (true) {
                            case response.data.status === 200:
                                this.$store.dispatch('toasts/toastSuccess', 'File upload success')
                                this.$emit('uploaded', {data: response.data, identifier: this.identifier})
                                this.uploadComplete = true
                                this.dialog = false
                                this.uploadedFile = response.data
                                return
                            case response.data.status === 401:
                                this.message = 'Unauthorized action'
                                this.$store.dispatch('toasts/toastWarning', this.message)
                                break;
                            case response.data.status === 413:
                                this.message = 'File size too large'
                                this.$store.dispatch('toasts/toastError', this.message)
                                break;
                            case response.data.status === 403:
                                this.message = 'Invalid file type'
                                this.$store.dispatch('toasts/toastError', this.message)
                                break;
                            case response.data.status === 406 && response.data.message === 'file-manager/doc-content-invalid':
                            case response.data.status === 406 && response.data.message === 'file-manager/doc-title-invalid':
                                if (response.data.message === 'file-manager/doc-content-invalid') {
                                    this.message = 'Document content invalid. Did you upload an empty document?'
                                }
                                if (response.data.message === 'file-manager/doc-title-invalid') {
                                    this.message = 'Document filename malformed; rename the file and try again'
                                }
                                this.$store.dispatch('toasts/toastError', this.message)
                                break;
                            default:
                                this.$store.dispatch('toasts/toastError', response.data.message)
                                this.message = 'Unknown error, try again'
                        }
                        this.$emit('uploaded', false)
                    })
                    .catch(error => {
                        this.$store.dispatch('toasts/toastError', error.data.message)
                        this.message = error.data.message
                        this.currentFile = undefined
                    })
            }
        }
    }
</script>

<style>

    .fileName input {
        padding: 0 0 !important;
        font-size: 12px;
    }
</style>