<template>
    <div>
        <v-dialog
            v-model="dialog"
            transition="dialog-bottom-transition"
            width="860px"
            max-width="100%"
        >
            <v-card>
                <v-toolbar
                    color="primary"
                    dark
                    dense
                    tile
                    max-height="48px"
                >
                    <v-toolbar-title class="mx-auto" style="width:960px;">
                        Order
                    </v-toolbar-title>
                    <v-toolbar-items>
                        <v-btn
                            @click="dialog = false"
                            icon>
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text class="pt-6 pb-0 mb-0">
                    <p class="text--primary mb-0">Click expand
                        <v-icon>mdi-chevron-down</v-icon>
                        to customize each domain order.<br/>Upload articles or simply enter anchors and URLs, and we
                        will do the rest.
                    </p>
                </v-card-text>
                <v-data-table
                    class="mt-4 mb-12 transparent"
                    :items="currentOrder"
                    :headers="[
                               {text:'Domain',value:'domain',sortable:false},
                               {text:'Units',value:'units',width:'40px',sortable:false},
                               {text:'Price',value:'price',align:'right',width:'100px',sortable:false},
                               {text:'Sub total',value:'subTotal',align:'right',width:'100px',sortable:false},
                               {text:'',value:'remove',align:'right',sortable:false,width:'60px'},
                               ]"
                    :items-per-page="-1"
                    item-key="identifier"
                    hide-default-footer
                    show-expand
                    dense
                >
                    <template v-slot:item.kbPrice="{item}">
                        {{(item.kbPrice *
                        $store.state.exchangeRate[$store.state.currencyCode]).toLocaleString($store.state.locale,
                        {style:'currency',currency:$store.state.currencyCode,maximumFractionDigits: 2,}) }}
                    </template>

                    <template v-slot:item.remove="{item}">
                        <v-btn icon small @click="removeFromOrder(item)">
                            <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                    </template>

                    <template v-slot:item.units="{item}">
                        {{item.articles.length}}
                    </template>

                    <template v-slot:item.subTotal="{item}">
                        {{(item.articles.length * item.kbPrice *
                        $store.state.exchangeRate[$store.state.currencyCode]).toLocaleString($store.state.locale,
                        {style:'currency',currency:$store.state.currencyCode,maximumFractionDigits: 2,})}}
                    </template>

                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" class="pb-4 pt-6">
                            <v-row
                                v-for="(article,idx) in item.articles"
                                :key="item.identifier+'-article-'+idx"
                            >
                                <v-col cols="12" class="pt-0 pb-0 "><span class="subtitle-2">Article {{idx + 1}}</span>
                                </v-col>
                                <v-col cols="2" class="pt-0 pb-0 d-flex">
                                    <upload-file
                                        type="article"
                                        :identifier="domainIdent(item)"
                                        :dialog-header="'' +
                                     '<p><strong>Document name must be:</strong> Domain - article<br>' +
                                     '<strong>Example:</strong> ' + domainName(item.domain) + ' - ' + 'Article name</p>' +
                                     '<p><strong>Accepted file types:</strong> doc, docx </p>'"
                                        @uploaded="addAttachment(item,idx,$event)"
                                        @removeUpload="removeAttachment(item,idx,$event)"
                                        style="width:100%;"
                                    />
                                </v-col>
                                <v-col cols="4" class="pt-0 pb-0">
                                    <v-text-field
                                        v-model="article.anchor1"
                                        placeholder="Anchor (keyword)"
                                        hide-details
                                        :solo-inverted="$vuetify.theme.dark"
                                        dense
                                        flat
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="3" class="pt-0 pb-0">
                                    <v-text-field
                                        v-model="article.url1"
                                        placeholder="URL"
                                        hide-details
                                        :solo-inverted="$vuetify.theme.dark"
                                        dense
                                        flat
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="3" class="pt-0 pb-0">
                                    <v-text-field
                                        v-model="item.articles[idx].publishDate"
                                        hide-details
                                        readonly
                                        :solo-inverted="$vuetify.theme.dark"
                                        dense
                                        flat
                                    >
                                        <template v-slot:prepend-inner>
                                            <date-time-picker
                                                :dateOnly="true"
                                                :min="new Date().daysFromNow(3,true)"
                                                :preSelected="new Date().daysFromNow(3,true)"
                                                @chosenDate="setDate(item,idx,$event)"
                                                class="ma-auto"
                                            />
                                        </template>
                                        <template v-slot:append-outer>
                                            <v-btn x-small icon @click="removeArticleFromOrder(item,idx)"
                                                   class="ma-auto">
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-btn
                                        @click="addArticleToDomain(item)"
                                        color="green"
                                        outlined
                                        small
                                        width="100%"
                                        right
                                        dark
                                    >
                                        <v-icon left>
                                            mdi-plus
                                        </v-icon>
                                        Article
                                    </v-btn>
                                </v-col>
                            </v-row>

                        </td>

                    </template>

                </v-data-table>

                <v-alert
                    dense
                    type="info"
                    color="info"
                    text
                    class="mb-0"
                >Order customization also available in <span class="font-weight-bold">cart</span>
                </v-alert>

                <v-card-actions class="elevation-0 px-6 py-4" style="width:100%; max-width:100%;">

                    <div class="font-weight-bold px-4 py-2 ma-0 text-right">Total price:&nbsp; {{(totalPrice *
                        $store.state.exchangeRate[$store.state.currencyCode]).toLocaleString($store.state.locale,
                        {style:'currency', currency:$store.state.currencyCode})}}
                    </div>
                    <v-spacer/>
                    <v-btn
                        color="primary"
                        class="mr-4"
                        @click="addToCart"
                        :loading="placingOrder"
                    >
                        <v-icon left small>mdi-cart</v-icon>
                        Add to cart
                    </v-btn>
                    <v-btn
                        outlined
                        @click="dialog = false"
                    >
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-btn
            color="green"
            :dark="selected.length !== 0"
            x-small
            style="width:100%"
            @click="dialog = !dialog"
            :disabled="selected.length === 0"
        >
            <v-icon left x-small>mdi-cart</v-icon>
            Buy {{$props.selected.length}}
        </v-btn>
    </div>
</template>

<script>
    import UploadFile from "@/components/fileManagement/uploadFile";
    import DateTimePicker from "@/components/dateTimePicker/dateTimePicker";

    export default {
        name: "addMultipleToCart",
        components: {DateTimePicker, UploadFile},
        props: ['selected'],
        data() {
            return {
                dialog: false,
                currentOrder: [],
                placingOrder: false,
            }
        },
        methods: {
            log(obj) {
                console.log(obj)
            },
            domainIdent(item) {
                return crypto.randomUUID()
                /*
                let d = new Date().toRegular()
                return d.Y + d.m + d.d + d.H + d.i + d.s + '-' + item.identifier
                */
            },
            addAttachment(orderItem, articleIndex, file) {
                if (typeof file.data !== 'undefined') {
                    let domainIndex = this.currentOrder.findIndex(x => x.id === orderItem.id)
                    this.currentOrder[domainIndex].articles[articleIndex].isFile = true
                    this.currentOrder[domainIndex].articles[articleIndex].file = file.data.location
                }
            },
            removeAttachment(orderItem, articleIndex) {
                let domainIndex = this.currentOrder.findIndex(x => x.id === orderItem.id)
                this.currentOrder[domainIndex].articles[articleIndex].isFile = false
                this.currentOrder[domainIndex].articles[articleIndex].file = ''
            },
            removeFromOrder(orderItem) {
                this.$emit('domainRemoved', orderItem)
            },
            removeArticleFromOrder(orderItem, articleIndex) {
                let itemIdx = this.currentOrder.findIndex(x => x.id === orderItem.id)
                this.currentOrder[itemIdx].articles.length >= 2 && this.currentOrder[itemIdx].articles.splice(articleIndex, 1)
            },
            addToCart() {
                this.placingOrder = true
                this.currentOrder.forEach(order => {
                    this.$store.dispatch('cartModule/addToCart', order)
                })
                this.$emit('addedToCart')
                this.placingOrder = false
                this.currentOrder = []
                this.dialog = false
            },
            addArticleToDomain(orderItem) {
                let idx = this.currentOrder.findIndex(x => x.identifier === orderItem.identifier)
                this.currentOrder[idx].articles.push({
                    articleId: crypto.randomUUID(),
                    identifier: orderItem.identifier,
                    domain: orderItem.domain,
                    price: orderItem.kbPrice,
                    kbPrice: orderItem.kbPrice,
                    salesPrice: orderItem.kbPrice,
                    name: '',
                    anchor1: '',
                    anchor2: '',
                    url1: '',
                    url2: '',
                    keyword: '',
                    file: '',
                    image: '',
                    logo: '',
                    optionalData: {},
                    publishDate: new Date().daysFromNow(3, true),
                })
            },
            setDate(orderItem, articleIndex, date) {
                let idx = this.currentOrder.findIndex(x => x.identifier === orderItem.identifier)
                this.currentOrder[idx].articles[articleIndex].publishDate = date
            },
            domainName(url) {
                if (url !== '') {
                    return new URL('https://' + url).host
                }
                return ''
            }
        },
        mounted() {
        },
        beforeMount() {

        },
        computed: {
            totalPrice() {
                if (this.currentOrder.length === 0) {
                    return 0
                }
                let total = 0
                this.currentOrder.forEach(domain => {
                    total += domain.kbPrice
                })
                return total

            }
        },
        watch: {
            '$props.selected': {
                handler() {
                    let newOrder = []
                    this.selected.forEach(obj => {
                        let ai = this.currentOrder.findIndex(x => x.id === obj.id)
                        let domain = JSON.parse(JSON.stringify(obj))
                        if (ai < 0) {
                            domain.articles = [{
                                articleId: crypto.randomUUID(),
                                name: '',
                                anchor1: '',
                                anchor2: '',
                                url1: '',
                                url2: '',
                                keyword: '',
                                file: '',
                                image: '',
                                logo: '',
                                optionalData: {},
                                publishDate: new Date().daysFromNow(3, true),
                            }]
                            //this.currentOrder.push(obj)
                            newOrder.push(domain)
                        } else {
                            newOrder.push(this.currentOrder[ai])
                            //this.currentOrder.slice(ai, 1)
                        }

                        this.currentOrder = newOrder
                    })
                    if (this.selected.length === 0) {
                        this.currentOrder = []
                    }

                },
                immediate: true
            }
        }

    }
</script>

<style>
    .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
        box-shadow: none !important;
    }

    tr.v-data-table__expanded.v-data-table__expanded__content > td {
        /*border-radius: 5px;*/
    }

    .theme--dark tr.v-data-table__expanded.v-data-table__expanded__content > td {
        background: rgba(255, 255, 255, 0.03);
        border-top: 1px solid #434243 !important;
        border-bottom: 1px solid #434243 !important;
    }

    .theme--light tr.v-data-table__expanded.v-data-table__expanded__content > td {
        background: rgba(0, 0, 0, 0.03);
        border: 1px solid #dfdfdf !important;
        border-top: 1px solid #434243 !important;
        border-bottom: 1px solid #434243 !important;
    }
</style>