import axios from 'axios'
import store from '../store'

let stripeApi = axios.create({
    baseURL: 'https://api.stripe.com/v1',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Referrer-Policy": "no-referrer"
    }
})

stripeApi.interceptors.request.use(
    config => {
        config.headers.common["Authorization"] = 'Bearer ' + 'sk_test_51MTk0WE8TFmArBhDQbLbMfzLX3i94CKzSts8rqgfRM5h0uDZ0Mr7mQpZkGzZCHvp56bjYUDQGto8oP2La5J5NFFs00uCc3EFdv'
        config.headers.common["content-type"] = 'application/x-www-form-urlencoded';
        return config
    },
    error => {
        console.log('requestError:', error)
    }
)
stripeApi.interceptors.response.use(response => {
        switch (response.status) {
            case 200:
            case 201:
                return Promise.resolve(response)
            default:
                return Promise.reject(response)
        }
    }, error => {
        switch (error.response.status) {
            case 401:
                window.location.pathname !== "/" && store.dispatch('toasts/toastError', 'You are not authorized to access this page')
                if (error.response.data.message === 'USER_INACTIVE') {
                    store.dispatch('toasts/toastError', 'Din bruger er ikke længere aktiv')
                }
                return Promise.reject(error.response)
            case 404:
                store.dispatch('toasts/toastError', '404: Page not found')
                return Promise.reject(error.response)
            case 498:
                store.dispatch('toasts/toastWarning', 'Din login-session er udløbet.')
                store.dispatch('auth/logout')
                return Promise.reject(error.response)
            default:
                return Promise.reject(error.response)
        }
    }
)


export default stripeApi