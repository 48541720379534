import { render, staticRenderFns } from "./newsWidget.vue?vue&type=template&id=b9c66868&scoped=true&"
import script from "./newsWidget.vue?vue&type=script&lang=js&"
export * from "./newsWidget.vue?vue&type=script&lang=js&"
import style0 from "./newsWidget.vue?vue&type=style&index=0&id=b9c66868&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9c66868",
  null
  
)

export default component.exports