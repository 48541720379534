import api from "../services/api";
import store from "./index";
import AuthService from "../services/authService";
import {getAuth, sendPasswordResetEmail} from "firebase/auth";


const user = JSON.parse(localStorage.getItem('user'))
const initialState = user
    ? {status: {loggedIn: true, authorized: true}, user: user}
    : {status: {loggedIn: false, authorized: false}, user: {role: 0,},}

export default {
    namespaced: true,
    state: initialState,
    mutations: {
        loginAuthorized(state, user) {
            let userData = JSON.stringify(user)
            if (userData !== 'undefined') {
                state.status.loggedIn = true
                state.status.authorized = true
                localStorage.setItem('user', JSON.stringify(user))
                state.user = user
            }
        },
        loginUnauthorized(state, user) {
            state.status.loggedIn = true
            state.status.authorized = false
        },
        loginFailure(state) {
            state.status.loggedIn = false
            state.status.authorized = false
        },
        logout(state) {
            state.status.loggedIn = false
            state.status.authorized = false
            window.location.href = "/login"

        },
        validToken(state, response) {
        },
        tokenExpired(state, response) {
            state.status.loggedIn = true
            state.status.authorized = false
        },
        userRefresh(state, user) {
            let userData = JSON.stringify(user)
            if (userData !== 'undefined') {
                localStorage.setItem('user', JSON.stringify(user))
                state.user = user
            }
        }
    },
    actions: {
        async login({commit}, payload) {
            return await new Promise((resolve, reject) => {
                AuthService.login(payload)
                    .then(firebaseUser => {
                        api.post('auth/login', firebaseUser)
                            .then(user => {
                                commit("loginAuthorized", user.data)
                                resolve(user)
                            })
                            .catch(error => {
                                store.dispatch('toasts/toastWarning', 'Bruger har ikke adgang.')
                            })
                    })
                    .catch(error => {
                        commit('loginFailure')
                        reject(error)
                    })
            })
        },
        async refreshUserInfo({commit}) {
            return await new Promise((resolve, reject) => {
                api.get('auth/userRefresh')
                    .then(user => {
                        commit("userRefresh", user.data)
                        resolve(true)
                    })
            })
        },
        logout({commit}) {
            if (this.state.auth.status.loggedIn) {
                AuthService.logout()
                commit('logout')
            }
        },
        validateToken({commit}) {
            api.get('auth/poll')
                .then(response => {
                    commit('validToken', response.data)
                })
                .catch(response => {
                    if (response.status === 400) {
                        AuthService.logout()
                        commit('logout')
                    }
                })
        },
        sessionExpired({commit}) {
            this.dispatch('toasts/toastWarning', 'Session udløbet')
            AuthService.logout()
            commit('logout')
        },
        tokenStatusHandler({commit}, response) {
            console.log(response)
            switch (response.status) {
                case 400:
                    this.dispatch('toasts/toastError', 'ikke logget ind')
                    break;
                case 401:
                    this.dispatch('toasts/toastError', 'ikke logget ind')
                    break;
                case 403:
                    this.dispatch('toasts/snackWarning', 'Du har ikke rettigheder til denne handling')
                    break;
                case 404:
                    store.state.pageState.status = 404
                    break;
                case 498:
                    commit('tokenExpired')
                    this.dispatch('toasts/toastWarning', 'sessionExpired')
                    break;
                case 502:
                    console.log('Bad gateway')
                    break;
            }
        },
        async passwordResetOnCreate({commit}, email) {
            const auth = getAuth();
            await sendPasswordResetEmail(auth, email)
                .then((response) => {
                    return Promise.resolve(response)
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // ..
                    return Promise.reject(error.message)
                });
        },
        async sendPasswordReset({commit}, email) {
            const auth = getAuth();
            await sendPasswordResetEmail(auth, email)
                .then((response) => {
                    return Promise.resolve(response)
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // ..
                    return Promise.reject(error.message)
                });
        },
        setNewPassword({commit}, user) {
            AuthService.setNewPassword(user)
        }

    },
}