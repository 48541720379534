<template>
    <v-dialog
        v-model="displayModal"
        max-width="600px"
    >
        <template v-slot:activator="{on,attrs}">
            <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="displayModal = true"
            >
                <v-icon>mdi-image-plus</v-icon>
            </v-btn>
        </template>
        <v-card
            class="glass--bg-overlay"
        >
            <v-card-title>Upload logo
                <v-spacer/>
                <v-btn icon
                       @click="displayModal = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                Previously uploaded images will be deleted
                <v-file-input
                    @change="selectFile"
                >
                    <template v-slot:append-outer>
                        <v-btn
                            text
                            @click="upload()"
                        >
                            <v-icon>mdi-upload</v-icon>
                            Upload
                        </v-btn>
                    </template>
                </v-file-input>
                <div v-if="currentFile">
                    <div>
                        <v-progress-linear
                            :value="progress"
                            color="light-purple"
                            height="25"
                            stream
                            striped
                            rounded

                        >
                            <strong>{{ progress }} %</strong>
                        </v-progress-linear>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

    import uploadService from "../../services/uploadService";

    export default {
        name: "companyLogoUpload",
        data() {
            return {
                displayModal: false,
                currentFile: undefined,
                progress: 0,
                message: "",
                fileInfos: [],
                transferSuccess: false,
            }
        },
        mounted() {
        },
        methods: {
            selectFile(file) {
                this.progress = 0
                this.message = ""
                this.currentFile = file
            },
            upload() {
                if (!this.currentFile) {
                    this.message = "Choose an image to upload..."
                    return
                }

                this.message = ""

                uploadService.upload('logo', this.currentFile, (event) => {
                    this.progress = Math.round((100 * event.loaded) / event.total)
                })
                    .then(response => {
                        this.progress = 100
                        this.message = response.data.message
                        this.transferSuccess = true
                        this.$emit('uploadSuccess', true)
                    })
                    .catch(error => {
                        this.message = error.data.message
                        this.currentFile = undefined
                        this.transferSuccess = false
                    })
                    .finally(() => {
                        this.displayModal = false
                        this.currentFile = undefined
                        this.$store.dispatch('toasts/toastSuccess', this.message)
                    })
            }
        }
    }
</script>

<style scoped>

</style>