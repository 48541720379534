<template>
    <v-container class="pa-sm-1 pa-md-1 pa-lg-8 pa-xl-8 pt-sm-8 pt-md-8 pt-12">
        <v-row>
            <v-col v-if="!orderConfirmed" sm="12" md="10" lg="8" xl="8" class="mx-auto">
                <v-row>
                    <v-col>
                        <v-card
                            :loading="orderProcessing"
                            class="glass--bg"
                        >
                            <v-toolbar dense elevation="0" color="transparent">
                                <v-toolbar-title>
                                    <v-btn icon @click="$router.go(-1)">
                                        <v-icon>mdi-arrow-left</v-icon>
                                    </v-btn>
                                    Confirm order
                                </v-toolbar-title>
                                <v-spacer/>
                                <v-btn text small @click="$store.dispatch('cartModule/emptyCart')">
                                    <v-icon left>mdi-delete-empty</v-icon>
                                    Empty cart
                                </v-btn>
                            </v-toolbar>

                            <v-card-text>
                                <v-alert
                                    dense
                                    type="info"
                                    color="info"
                                    text
                                    class="mb-0"
                                >
                                    <span class="font-weight-bold">Before confirming!</span><br/> Make sure you have customized each domain by uploading articles and / or adding keywords and desired link URLs.
                                </v-alert>
                            </v-card-text>
                            <v-data-table
                                :items="$store.state.cartModule.orders"
                                :headers="headers"
                                mobile-breakpoint="0"
                                hide-default-footer
                                disable-sort
                                show-expand
                                single-expand
                                class="transparent"
                                :items-per-page="-1"
                            >

                                <template v-slot:item.domain="{item}">
                                    <td class="text-truncate text-no-wrap" style="max-width:600px" v-if="validOrders.indexOf(item.orderId) >= 0">
                                        <strong>{{item.domain}}</strong>
                                    </td>
                                    <td v-else class="text-truncate text-no-wrap" style="max-width:600px">
                                        <v-icon color="orange" class=" py-1 pl-0 mr-2" style="border-radius: 50%;">mdi-alert</v-icon>
                                        <strong>{{item.domain}}</strong>
                                    </td>
                                </template>

                                <template v-slot:item.amount="{item}">{{item.articles.length}}</template>

                                <template v-slot:item.kbPrice="{item}">{{toLocaleCurrency(item.kbPrice)}}</template>

                                <template v-slot:item.subtotal="{item}">{{toLocaleCurrency(item.kbPrice )}}</template>

                                <template v-slot:item.delete="{item}">
                                    <v-btn
                                        icon
                                        small
                                        @click="$store.dispatch('cartModule/removeFromCart',item)">
                                        <v-icon>mdi-trash-can</v-icon>
                                    </v-btn>
                                </template>

                                <template v-slot:expanded-item="{headers, item}">
                                    <td :colspan="headers.length" class="pa-2">
                                        <v-expansion-panels v-model="panels[$store.state.cartModule.orders.findIndex(x => x.orderId === item.orderId)]">
                                            <v-expansion-panel
                                                v-for="(article,idx) in item.articles"
                                                :key="'article-'+article.articleId"
                                                class="elevation-2 mt-0"
                                            >
                                                <v-expansion-panel-header class="font-weight-bold d-flex align-center justify-end dark-expansion-header py-2" color="primary" style="min-height:48px;">
                                                <span class="white--text">
                                                    {{item.domain}} | Article {{idx + 1}}
                                                </span>
                                                    <v-spacer/>
                                                    <div v-if="validArticles.indexOf(article.articleId) >= 0" class="flex-grow-0 mr-4">
                                                        <v-icon color="white" class="green pa-1" style="border-radius: 50%;">mdi-check</v-icon>
                                                    </div>
                                                    <div v-else class="flex-grow-0 mr-4">
                                                        <v-icon color="orange" class=" pa-1" style="border-radius: 50%;">mdi-alert</v-icon>
                                                        <span class="white--text">Required info missing</span>
                                                    </div>
                                                    <v-btn
                                                        icon
                                                        dark
                                                        @click="$store.dispatch('cartModule/removeArticleFromDomainOrder', {orderId:item.orderId,articleId:article.articleId} )"
                                                        :disabled="item.articles.length === 1"
                                                        class="flex-grow-0 ma-0">
                                                        <v-icon color="white">mdi-trash-can</v-icon>
                                                    </v-btn>
                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content class="pt-2">
                                                    <!--v-row>
                                                        <v-col cols="12" class="text-center pb-3">
                                                            <v-btn small text @click="$store.dispatch('cartModule/removeArticleFromDomainOrder', {orderId:item.orderId,articleId:article.articleId} )" :disabled="item.articles.length === 1" class="flex-grow-0 mb-2">
                                                                Remove article from order
                                                                <v-icon right>mdi-trash-can</v-icon>
                                                            </v-btn>
                                                            <v-divider/>
                                                        </v-col>
                                                    </v-row-->

                                                    <v-row dense>
                                                        <v-col cols="6">
                                                            <v-text-field
                                                                v-model="article.anchor1" label="Anchor 1"
                                                                placeholder="Anchor 1"
                                                                outlined
                                                                dense
                                                                hide-details
                                                                append-icon="mdi-anchor"
                                                                :error="errors[$store.state.cartModule.orders.findIndex(x => x.orderId === item.orderId)][idx].anchor1Error"
                                                            />
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-text-field
                                                                v-model="article.url1"
                                                                label="URL 1"
                                                                placeholder="URL 1"
                                                                outlined
                                                                dense
                                                                hide-details
                                                                :error="errors[$store.state.cartModule.orders.findIndex(x => x.orderId === item.orderId)][idx].url1Error"
                                                                append-icon="mdi-link"
                                                            >
                                                                <template v-slot:prepend-inner>
                                                                    <v-tooltip
                                                                        v-if="article.url1.length !== 0 && !validUrl(article.url1)">
                                                                        <template v-slot:activator="{on,attrs}">
                                                                            <v-icon>mdi-alert</v-icon>
                                                                        </template>
                                                                        Invalid URL
                                                                    </v-tooltip>
                                                                </template>
                                                            </v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row dense>
                                                        <v-col cols="6">
                                                            <v-text-field v-model="article.anchor2" label="Anchor 2" placeholder="Anchor 2" outlined dense hide-details append-icon="mdi-anchor"/>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-text-field
                                                                v-model="article.url2"
                                                                label="URL 2"
                                                                placeholder="URL 2"
                                                                outlined
                                                                dense
                                                                hide-details
                                                                :error="errors[$store.state.cartModule.orders.findIndex(x => x.orderId === item.orderId)][idx].url2Error"
                                                                append-icon="mdi-link"
                                                            >
                                                                <template v-slot:prepend-inner>
                                                                    <v-tooltip
                                                                        v-if="article.url2.length !== 0 && !validUrl(article.url2)">
                                                                        <template v-slot:activator="{on,attrs}">
                                                                            <v-icon>mdi-alert</v-icon>
                                                                        </template>
                                                                        Invalid URL
                                                                    </v-tooltip>
                                                                </template>
                                                            </v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row dense>
                                                        <v-col cols="6">
                                                            <v-text-field v-model="article.keyword" label="Focus keyword" placeholder="Focus keyword" outlined dense hide-details append-icon="mdi-bullseye"/>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-text-field
                                                                v-model="article.publishDate"
                                                                outlined
                                                                label="Desired publication date. No guarantees."
                                                                dense
                                                                hide-details
                                                                readonly
                                                            >
                                                                <template v-slot:append>
                                                                    <date-time-picker
                                                                        :dateOnly="true"
                                                                        :min="new Date().daysFromNow(20,true)"
                                                                        :preSelected="new Date().daysFromNow(20,true)"
                                                                        @chosenDate="$store.dispatch('cartModule/setArticleDate',{orderId:item.orderId,articleId:article.articleId,newDate:$event})"
                                                                        class="mt-1"
                                                                    />
                                                                </template>
                                                            </v-text-field>
                                                        </v-col>

                                                        <v-col cols="12">
                                                            <v-text-field
                                                                v-model="article.userComment"
                                                                :label="'Article comments ('+ article.userComment.length +'/ 2000)' "
                                                                :error="article.userComment.length > 2000"
                                                                placeholder="Article comments"
                                                                outlined
                                                                hide-details
                                                                dense
                                                                append-icon="mdi-comment"
                                                            />
                                                        </v-col>
                                                    </v-row>
                                                    <v-row dense>
                                                        <v-col cols="12" xl="6">
                                                            <upload-file
                                                                type="article"
                                                                logo="mdi-text-box-plus-outline"
                                                                label="Upload article"
                                                                :identifier="article.articleId"
                                                                :fileAlreadyUploaded="article.file"
                                                                :dialog-header="'' +
                                                                             '<p><strong>Document name must be:</strong> Domain - article<br>' +
                                                                             '<strong>Example:</strong> ' + domainName(item.domain) + ' - ' + 'Article name</p>' +
                                                                             '<p><strong>Accepted file types:</strong> doc, docx </p>'"
                                                                @uploaded="$store.dispatch('cartModule/addAttachment',{articleIndex:idx,file:$event,type:'file',orderId:item.orderId})"
                                                                @removeUpload="$store.dispatch('cartModule/removeAttachment',{articleIndex:idx,fileLocation:$event,type:'image',orderId:item.orderId})"
                                                            />
                                                        </v-col>
                                                        <v-col cols="12" xl="6">
                                                            <unsplash-gallery
                                                                @image-selected="$store.dispatch('cartModule/addUnsplashAttachment',{articleIndex:idx,file:$event,type:'image',orderId:item.orderId})"
                                                                @removeUpload="$store.dispatch('cartModule/removeUnsplashAttachment',{articleIndex:idx,fileLocation:$event,type:'image',orderId:item.orderId})"
                                                                :key="'us-'+article.articleId"
                                                                :disabled="article.image !== ''"
                                                                :imagePreChosen="typeof article.optionalData.image !== 'undefined' ? article.image : ''"
                                                            />
                                                        </v-col>
                                                    </v-row>
                                                    <v-row dense>
                                                        <v-col cols="12" xl="6">
                                                            <upload-file
                                                                type="articleLogo"
                                                                label="Upload logo"
                                                                :identifier="article.articleId"
                                                                :fileAlreadyUploaded="article.logo"
                                                                :dialog-header="'' +
                                                                             '<p><strong>Logo name should be the same as company name</strong><br>' +
                                                                             '<strong>Example:</strong>&nbsp;kaboozt-logo.jpg</p>' +
                                                                             '<p><strong>Accepted file types:</strong> jpg, png, gif</p>'"
                                                                @uploaded="$store.dispatch('cartModule/addAttachment',{articleIndex:idx,file:$event,type:'logo',orderId:item.orderId})"
                                                                @removeUpload="$store.dispatch('cartModule/removeAttachment',{articleIndex:idx,fileLocation:$event,type:'logo',orderId:item.orderId})"
                                                            />
                                                        </v-col>
                                                        <v-col cols="12" xl="6">
                                                            <upload-file
                                                                type="articleImage"
                                                                label="Upload image"
                                                                logo="mdi-image-plus-outline"
                                                                :identifier="article.articleId"
                                                                :fileAlreadyUploaded="typeof article.optionalData.image === 'undefined' ? article.image : '' "
                                                                :dialog-header="'' +
                                                                             '<p><strong>Image name must contain publication domain</strong><br>' +
                                                                             '<strong>Example:</strong> ' + domainName(item.domain) + '-' + 'flowers.jpg</p>' +
                                                                             '<p><strong>Accepted file types:</strong> jpg, png, gif</p>'"
                                                                @uploaded="$store.dispatch('cartModule/addAttachment',{articleIndex:idx,file:$event,type:'image',orderId:item.orderId})"
                                                                @removeUpload="$store.dispatch('cartModule/removeAttachment',{articleIndex:idx,fileLocation:$event,type:'image',orderId:item.orderId})"
                                                            />

                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="12"
                                                               v-if="errors[$store.state.cartModule.orders.findIndex(x => x.orderId === item.orderId)][idx].error">
                                                            <v-alert
                                                                type="warning"
                                                                dense
                                                            >
                                                                <span v-html="errors[$store.state.cartModule.orders.findIndex(x => x.orderId === item.orderId)][idx].message"/>
                                                            </v-alert>
                                                        </v-col>
                                                        <v-col cols="12" v-if="item.domain.indexOf('.dk') >= 0 && (article.image === '' && article === '')">
                                                            <v-alert type="warning" dense><span><strong>NB:</strong> Danish domains (.dk) require both image and logo upload, due to marketing laws.</span></v-alert>

                                                        </v-col>
                                                    </v-row>

                                                </v-expansion-panel-content>
                                            </v-expansion-panel>

                                            <v-btn
                                                @click="$store.dispatch('cartModule/addArticleToDomainOrder', item)"
                                                color="green"
                                                text
                                                width="100%"
                                                elevation="0"
                                                right
                                                dark
                                                class="my-4"
                                            >
                                                <v-icon left>
                                                    mdi-plus
                                                </v-icon>
                                                Add article to domain order
                                            </v-btn>
                                        </v-expansion-panels>
                                    </td>

                                </template>
                                <template v-slot:body.append="{item}">
                                    <tr>
                                        <td
                                            v-for="(header,i) in headers"
                                            :key="header+'-'+i"
                                            class="font-weight-bold"
                                            :class="i === headers.length - 1 ? 'text-right':''"
                                        >
                                            <span v-if="i === 0">
                                            <span class="grey--text">Vat</span>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon
                                                            color="grey"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            x-small
                                                        >mdi-information-variant
                                                        </v-icon>
                                                    </template>
                                                    <span>Danish VAT 25%</span>
                                                </v-tooltip>
                                            </span>
                                            <span v-if="i === headers.length - 1" class="font-weight-regular grey--text">{{(cartTotal * 0.25 * $store.state.exchangeRate[$store.state.currencyCode]).toLocaleString($store.state.locale, {style:'currency', currency:$store.state.currencyCode})}}</span>
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td
                                            v-for="(header,i) in headers"
                                            :key="header+'-'+i"
                                            class="font-weight-bold"
                                            :class="i === headers.length - 1 ? 'text-right':''"
                                        >
                                            <span v-if="i === 0">TOTAL</span>
                                            <span v-if="i === headers.length - 1" class="">{{((cartTotal * $store.state.exchangeRate[$store.state.currencyCode]) * 1.25).toLocaleString($store.state.locale, {style:'currency', currency:$store.state.currencyCode})}}</span>
                                        </td>
                                        <td></td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-card>
                        <v-alert type="warning" v-if="!allOrdersValid" dense class="mt-4">
                            <strong>One or more orders are missing required information. Please check your orders.</strong>
                        </v-alert>
                        <v-sheet class=" glass--bg d-flex pa-4 pb-0 mt-8">

                            <v-checkbox v-model="accept" class="mt-0">
                                <template v-slot:label>
                                    <div @click.stop="" class=" text--primary">
                                        I accept
                                        <a
                                            href="#"
                                            @click.prevent="terms = true"
                                            class="light-blue--text"
                                        >terms and conditions</a>
                                    </div>
                                </template>
                            </v-checkbox>
                            <v-dialog
                                v-model="terms"
                                width="70%"
                            >
                                <v-card>
                                    <v-card-text>
                                        <terms-and-conditions/>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="purple"
                                            @click="terms = false"
                                        >
                                            Ok
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-spacer/>
                            <v-btn
                                color="primary"
                                :disabled="!allOrdersValid || !accept"
                                :loading="orderProcessing"
                                @click="confirmOrder()">
                                Confirm order
                            </v-btn>
                        </v-sheet>
                    </v-col>
                </v-row>
                <v-divider class="mt-2 mb-5"/>
                <p class="caption">* Some publishers do not allow custom headlines.<br>
                    ** In some cases, certain keywords may have a negative effect on your website's link profile as anchor.
                    In these cases we may contact you to change anchor value if, in our oppinion, it may be detremental to your website.</p>
            </v-col>
            <v-col sm="12" md="10" lg="8" xl="8" class="mx-auto" v-if="orderConfirmed">
                <v-card class="glass--bg">
                    <v-card-text>
                        <v-list color="transparent">
                            <v-list-item>
                                <v-list-item-content class="text-center justify-center">
                                    <v-list-item-title>
                                        <v-list-item-avatar color="green" dark>
                                            <checkmark-animated :success="true" :x-small="true"/>
                                        </v-list-item-avatar>
                                        Order recieved
                                    </v-list-item-title>
                                    <p>And order confirmation has been sent to your contact e-mail:<br>
                                        {{$store.state.auth.user.contactMail}}
                                    <p/>
                                    <br/>
                                    <router-link link :to="{name:'MyPage'}">Profile</router-link>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import DateTimePicker from "../components/dateTimePicker/dateTimePicker";
    import TermsAndConditions from "@/components/termsAndConditions";
    import UploadFile from "@/components/fileManagement/uploadFile";
    import CheckmarkAnimated from "@/components/UI/elements/checkmarkAnimated";
    import UnsplashGallery from "@/components/UI/gallery/unsplashGallery";

    Date.prototype.daysFromNow = function (days) {
        let fn = new Date();
        fn.setDate(fn.getDate() + days);
        let month = (fn.getMonth() + 1).toString().length === 1 ? '0' + (fn.getMonth() + 1) : (fn.getMonth() + 1)
        let day = fn.getDate().toString().length === 1 ? '0' + fn.getDate() : fn.getDate()
        return fn.getFullYear() + '-' + month + '-' + day
    }
    export default {
        name: "Cart",
        components: {UnsplashGallery, CheckmarkAnimated, UploadFile, TermsAndConditions, DateTimePicker},
        data() {
            return {
                headers: [
                    {text: 'Order', value: 'domain', width: '70%'},
                    {text: 'Articles', value: 'amount', align: 'center', width: 108},
                    {text: 'Unit price', value: 'kbPrice', align: 'right', width: 120},
                    {text: 'Sub total', value: 'subtotal', align: 'right', width: 140},
                    {text: '', value: 'delete', width: 40},
                ],
                accept: false,
                terms: false,
                conditions: false,
                panels: [],
                validArticles: [],
                termContent: '  ',
                orderConfirmed: false,
                orderProcessing: false,
                errors: [],
                validation: {
                    titleRules: [
                        v => !!v || 'Titel er påkrævet',
                        v => (v && v.length >= 5) || 'Min.  5 chars',
                    ],
                    anchorRules: [
                        v => !!v || 'Anchor required',
                        v => (v && v.length >= 2) || 'Min. 2 chars',
                    ],
                    urlRules: [
                        v => !!v || 'URL required',
                        v => !!this.validUrl(v) || 'Invalid URL',
                    ],
                    dateRules: [
                        v => !!v || 'Publish date required',
                        v => (v && v.length <= 10) || 'Name must be less than 10 characters',
                    ],
                },
            }
        },
        methods: {
            validUrl(url) {
                // URL encode the input
                let encodedUrl = encodeURI(url);
                // Regular expression pattern for URL validation
                let pattern = new RegExp(/^(https?:\/\/)(([a-zA-Z\d-]+\.)+[a-zA-Z]{2,})(\/[-a-zA-Z\d%_.~+\/]*|\/)*(?![?&])?(?:\?[;&a-zA-Z\d%_.~+=\/-]*)?(?:#[-a-zA-Z\d_]*)?(.txt|.js|.html|.png|.jpg|.jpeg|.gif|.doc|.docx)?$/);
                return pattern.test(encodedUrl.trim());
            },
            domainIdent(item) {
                let d = new Date().toRegular()
                return d.Y + d.m + d.d + d.H + d.i + d.s + '-' + item.identifier
            },
            addAttachment(orderItem, articleIndex, file) {
                let domainIndex = this.$store.state.cartModule.orders.findIndex(x => x.id === orderItem.id)
                this.$store.state.cartModule.orders[domainIndex].articles[articleIndex].isFile = true
                this.$store.state.cartModule.orders[domainIndex].articles[articleIndex].file = file.data.location
            },
            removeArticleFromCurrentOrder(orderItem, articleIndex) {
                const orderIndex = this.$store.state.cartModule.orders.findIndex(x => x.id === orderItem.id)
                this.$store.dispatch('cartModule/removeArticleFromCurrentOrder', {orderIndex: orderIndex, articleIndex: articleIndex})

            },
            yyyymmdd(date) {
                let [dd, mm, yyyy] = date.split('-')
                return `${yyyy}-${mm}-${dd}`
            },
            confirmOrder() {
                this.orderProcessing = true
                this.$store.dispatch('cartModule/confirmOrder')
                    .then(response => {
                        this.orderProcessing = false
                        this.orderConfirmed = true
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            removeFromOrder(item) {
                this.$store.state.cartModule.orders.findIndex(x => x.id === item.id)
                this.$emit('domainRemoved', item)
            },
            newTab(fileLocation) {
                window.open(fileLocation, '_blank');
            },
            domainName(url) {
                if (url !== '') {
                    return new URL('https://' + url).host
                }
                return
            },
            toLocaleCurrency(number) {
                return (number * this.$store.state.exchangeRate[this.$store.state.currencyCode]).toLocaleString(this.$store.state.locale, {
                    style: 'currency',
                    currency: this.$store.state.currencyCode
                })
            },
            setPanels() {
            }
        },
        mounted() {
            this.setPanels()
        },
        computed: {
            validOrders() {
                let validOrders = []
                this.$store.state.cartModule.orders.forEach((order, orderIndex) => {
                    let orderArticlesValid = []
                    order.articles.forEach(article => {
                        if (this.validArticles.indexOf(article.articleId) >= 0) {
                            orderArticlesValid.push(true)
                        } else {
                            orderArticlesValid.push(false)
                        }
                    })
                    if (orderArticlesValid.indexOf(false) < 0) {
                        validOrders.push(order.orderId)
                    }
                })
                return validOrders
            },
            allOrdersValid() {
                return this.validOrders.length === this.$store.state.cartModule.orders.length
            },
            urlsProvided() {
                let urlEntered = true
                this.$store.state.cartModule.orders.forEach((order, orderIndex) => {
                    order.articles.forEach(article => {
                        if (!this.validUrl(article.url1)) {
                            urlEntered = false
                            return
                        }
                    })
                })
                return urlEntered
            },
            cartTotal() {
                let total = 0
                const self = this
                self.$store.state.cartModule.orders.forEach(obj => {
                    total = total + (obj.kbPrice * obj.articles.length)
                })
                return total
            },
        },
        watch: {
            '$store.state.cartModule.orders': {
                handler() {
                    if (typeof this.$store.state.cartModule.orders === 'undefined') {
                        return;
                    }
                    this.errors = {}
                    this.$store.dispatch('cartModule/updateOrder', this.$store.state.cartModule)

                    this.$store.state.cartModule.orders.forEach((obj, oidx) => {
                        this.errors[oidx] = {}
                        obj.articles.forEach((article, aidx) => {
                            this.errors[oidx][aidx] = {
                                error: false,
                                message: '',
                                anchor1Error: false,
                                url1Error: false,
                            }
                            let hasFile = this.validUrl(article.file)
                            let hasLogo = this.validUrl(article.logo)
                            let hasImage = this.validUrl(article.image)
                            let hasUrl1 = this.validUrl(article.url1)
                            let hasAnchor1 = article.anchor1.trim() !== ""
                            let validArticle = (this.validArticles.findIndex(x => x === article.articleId) >= 0)
                            let dkDomain = obj.domain.indexOf('.dk') >= 0
                            let articleIndex = this.validArticles.findIndex(x => x === article.articleId)
                            if (hasFile || (hasUrl1 && hasAnchor1)) {
                                switch (true) {
                                    case dkDomain && hasImage && hasLogo:
                                        !validArticle && this.validArticles.push(article.articleId)
                                        this.errors[oidx][aidx].error = false
                                        break;
                                    case dkDomain && !hasImage && hasLogo:
                                        this.errors[oidx][aidx].error = true
                                        this.errors[oidx][aidx].message = 'Danish domains require image and logo: <strong>Upload image</strong>'
                                        break;
                                    case dkDomain && hasImage && !hasLogo:
                                        this.errors[oidx][aidx].error = true
                                        this.errors[oidx][aidx].message = 'Danish domains require image and logo: <strong>Upload logo</strong>'
                                        break;
                                    case dkDomain && !hasImage && !hasLogo:
                                        this.errors[oidx][aidx].error = true
                                        this.errors[oidx][aidx].message = 'Danish domains require image and logo: <strong>Upload image and logo</strong>'
                                        break;
                                    case dkDomain && hasUrl1 && hasAnchor1:
                                        !validArticle && this.validArticles.push(article.articleId)
                                        this.errors[oidx][aidx].error = false
                                        break;
                                    case hasUrl1 && hasAnchor1:
                                        !validArticle && this.validArticles.push(article.articleId)
                                        this.errors[oidx][aidx].error = false
                                        break;
                                    case !dkDomain && hasFile:
                                        !validArticle && this.validArticles.push(article.articleId)
                                        this.errors[oidx][aidx].error = false
                                        break;
                                    default:
                                }
                            } else {
                                switch (true) {
                                    case !hasUrl1 && !hasAnchor1:
                                        this.errors[oidx][aidx].error = true
                                        this.errors[oidx][aidx].anchor1Error = true
                                        this.errors[oidx][aidx].url1Error = true
                                        this.errors[oidx][aidx].message = 'Input required: URL 1 and Anchor 1 or upload article'
                                        break;
                                    case hasUrl1 && !hasAnchor1:
                                        this.errors[oidx][aidx].error = true
                                        this.errors[oidx][aidx].anchor1Error = true
                                        this.errors[oidx][aidx].message = 'Missing "Anchor 1" or article upload'
                                        break;
                                    case !hasUrl1 && hasAnchor1:
                                        this.errors[oidx][aidx].error = true
                                        this.errors[oidx][aidx].url1Error = true
                                        this.errors[oidx][aidx].message = 'Missing "URL 1" or article upload'
                                        break;
                                    default:
                                        this.errors[oidx][aidx].error = true
                                        this.errors[oidx][aidx].message = 'Must input URL 1, Anchor 1 or upload article'
                                }
                                if (articleIndex >= 0) {
                                    this.validArticles.splice(articleIndex, 1)
                                }
                            }
                        })
                    })
                },
                deep: true,
                immediate: true
            }
        }
    }
</script>

<style>
    .article--counter--field input {
        text-align: center !important;
    }
</style>