<template>
    <v-combobox
        v-model="selected"
        :items="[
            {value:'DKK',text:'DKK - Danish Krone '},
            {value:'USD',text:'USD - $'},
            {value:'EUR',text:'EUR - €'},
        ]"
        item-text="text"
        item-value="value"
        hide-details
        @input="updateCurrency"
        width="260px"
    ></v-combobox>
</template>

<script>
    import userManager from "@/services/userManager";

    export default {
        name: "currencySwitch",
        data() {
            return {
                selected: ''
            }
        },
        methods: {
            updateCurrency(event) {
                this.$store.state.currencyCode = event.value
                userManager.updateSetting({property: 'currencyCode', value: event.value}).then(response => {
                    if (response.data.message == 'user-management/setting-changed') {
                        this.$store.dispatch('toasts/toastSuccess', 'Currency changed to: ' + this.$store.state.currencyCode)
                    }else{
                        this.$store.dispatch('toasts/toastError', 'Argh cap\'n we hit an error')
                    }
                })
            }
        },
        mounted() {
            this.selected = this.$store.state.currencyCode

        }
    }
</script>

<style scoped>

</style>