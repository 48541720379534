<template>
    <v-menu
        offset-y
        style="top: 38px;"
        min-width="300px"
        :close-on-content-click="false"
    >
        <template v-slot:activator="{ on }">
            <v-btn v-on="on"
                   icon
                   dark
                   class="pa-0 mx-3 mt-1">
                <v-badge
                    v-if="$store.state.cartModule.orders.length >= 1"
                    :content="$store.state.cartModule.orders.length"
                    overlap
                    color="secondary"
                    class="ml-4 mr-4"
                >
                    <v-icon color="white" size="28">mdi-cart
                    </v-icon>
                </v-badge>
                <v-icon v-else color="white" size="28">mdi-cart</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-toolbar dense elevation="0">
                <v-toolbar-title class="pl-0 darken-4 v-subheader font-weight-bold ">
                    Orders
                    ({{$store.state.cartModule.orders.length}})
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-menu>
                    <template v-slot:activator="{on,attr}">
                        <v-btn icon>
                            <v-icon
                                v-bind="attr"
                                v-on="on"
                            >mdi-dots-horizontal
                            </v-icon>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-list-item @click="$store.dispatch('cartModule/emptyCart')" link>
                            <v-list-item-content class="caption">
                                Empty cart
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>
                </v-menu>
            </v-toolbar>
            <v-divider/>
            <v-virtual-scroll
                :items="$store.state.cartModule.orders"
                :item-height="56"
                max-height="calc(90vh - 194px)"
                min-height="56"
                width="360px"
                max-width="100vw"
                bench="10"
                v-if="$store.state.cartModule.orders.length >= 1"
            >
                <template v-slot="{item}">
                    <v-list-item
                        elevation="0"
                        link
                        :to="{path:'cart'}"
                    >
                        <v-list-item-content class="pt-0 pb-0">
                            <v-list-item-title class="subtitle-2" color=" grey darken-1">
                                {{item.domain}}
                                <v-list-item-subtitle
                                    class="caption blue--text text--darken-1 text-truncate">
                                    {{itemPrice(item)}}
                                </v-list-item-subtitle>
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action class="ma-0">
                            <v-btn icon>
                                <v-icon
                                    size="14"
                                    color="grey lighten-1"
                                    @click="$store.dispatch('cartModule/removeFromCart',item)"
                                >mdi-delete
                                </v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </template>
            </v-virtual-scroll>
            <v-list-item
                v-else
                class="py-2"
            >
                <v-list-item-content class="pt-0 pb-0">
                    <v-list-item-title class="subtitle-2" color=" grey darken-1">
                        Cart is currently empty
                        <v-list-item-subtitle class="caption blue--text text--darken-1 text-truncate text-right">0 ,-</v-list-item-subtitle>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>


            <v-footer class="pa-0">
                <v-btn class="ma-0" width="100%" tile color="primary" dark link :to="{path:'/cart'}">See cart</v-btn>
            </v-footer>
        </v-card>
    </v-menu>
</template>

<script>
    export default {
        name: "cartBadge",
        data() {
            return {
                items: []
            }
        },
        methods: {
            itemPrice(item) {
                return (item.kbPrice * this.$store.state.exchangeRate[this.$store.state.currencyCode]).toLocaleString(this.$store.state.locale, {style: 'currency', currency: this.$store.state.currencyCode})
            }
        }
    }
</script>

<style scoped>

</style>