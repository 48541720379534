<template>
    <v-combobox
        v-model="selected"
        :items="locales"
        item-text="text"
        item-value="value"
        hide-details
        max-width="160px"
        @input="updateLanguage"
    ></v-combobox>
</template>

<script>
    import userManager from "@/services/userManager";

    export default {
        name: "localeSwitch",
        data() {
            return {
                selected: '',
                locales: [
                    {value: 'da-dk', text: 'Danish'},
                    {value: 'en-us', text: 'English'},
                    {value: 'pl-pl', text: 'Polish'},
                ]
            }
        },
        methods: {
            updateLanguage(event) {
                this.$store.state.locale = event.value
                userManager.updateSetting({property: 'locale', value: event.value}).then(response=>{
                    if (response.data.message == 'user-management/setting-changed') {
                        this.$store.dispatch('toasts/toastSuccess', 'Local changed to: ' + this.$store.state.locale)
                    }else{
                        this.$store.dispatch('toasts/toastError', 'Argh cap\'n we hit an error')
                    }
                })
            }
        },
        mounted() {
            this.selected = this.locales.find(x => x.value === this.$store.state.locale)

        }
    }
</script>

<style scoped>
</style>