<template>
    <div class="dateTimePicker">
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="#aaa"
                >mdi-calendar</v-icon>
                <v-text-field
                    :value="date + ' ' + time"
                    style="padding-left:40px;display:none;"
                    v-bind="attrs"
                    v-on="on"
                    prepend-icon="mdi-calendar"
                    dense
                    outlined
                    :label="label"
                />
            </template>
            <v-card
                width="290"
                max-width="290"
                class="dateTimePickerRounding"
                tile
            >
                <v-tabs
                    v-model="tabIndex"
                    v-if="dateOnly !== true"
                >
                    <v-row class="ma-0">
                        <v-col
                            class="col-6 pa-0 blue"
                            :class="tabIndex === 0 ? ' darken-2':''"
                        >
                            <v-tab class="pa-3">
                                <v-icon color="white">mdi-calendar</v-icon>
                            </v-tab>
                        </v-col>
                        <v-col
                            class="col-6 pa-0 blue"
                            :class="tabIndex === 1 ? ' darken-2':''"
                        >
                            <v-tab class="pa-3">
                                <v-icon color="white">mdi-clock</v-icon>
                            </v-tab>
                        </v-col>
                    </v-row>
                </v-tabs>
                <v-tabs-items v-model="tabIndex" class="pa-0">
                    <v-tab-item>
                        <v-date-picker
                            flat
                            v-model="date"
                            v-on:input="datePicked()"
                            first-day-of-week="1"
                            :locale="$store.state.locale"
                            :min="config.min"
                        />
                    </v-tab-item>
                    <v-tab-item v-if="dateOnly !== true">
                        <v-time-picker
                            format="24hr"
                            flat
                            v-model="time"
                        />
                    </v-tab-item>
                </v-tabs-items>
                <v-card-actions>
                    <v-btn
                        color="primary"
                        @click="menu = false,$emit('chosenDate',returnTime())"
                    >Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
    Date.prototype.isValid = function () {
        return this.getTime() === this.getTime()
    }
    export default {
        name: "dateTimePicker",
        props: ['preSelected', 'dateOnly', 'min', 'label'],
        data() {
            return {
                menu: false,
                tabIndex: 0,
                date: '',
                time: '00:00',
                config: {}
            }
        },
        methods: {
            log(obj) {
                console.log(obj)
            },
            returnTime() {
                let [Y, m, d] = this.date.split('-')
                /*
                d = d.toString().length === 1 ? '0' + d : d
                m = m.toString().length === 1 ? '0' + m : m
                */
                let returnTime = Y + '-' + m + '-' + d + (this.dateOnly ? '' : ' ' + this.time)
                return returnTime
            },
            setPreselectedDate() {
                if (!this.preSelected) {
                    return
                }
                if (typeof this.preSelected === 'undefined' || this.preSelected.trim() === '') {
                    let today = new Date()
                    this.date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
                    this.time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
                } else {
                    if (!(new Date(this.preSelected).isValid())) {
                        if (this.preSelected.split(' ')[0].split('-')[2].length === 4) {
                            let [d, m, Y] = this.preSelected.split(' ')[0].split('-')
                            this.date = Y + '-' + m + '-' + d
                            this.time = this.preSelected.split(' ')[1]
                        }
                    } else {
                        this.date = this.preSelected.split(' ')[0]
                        this.time = this.preSelected.split(' ')[1]
                    }
                }
            },
            datePicked() {
                if (this.dateOnly) {
                    this.$emit('chosenDate', this.returnTime())
                } else {
                    this.tabIndex = 1
                }
            },
            setValue() {
                let [Y, m, d] = this.date.split('-')
                d = d.toString().length === 1 ? '0' + d : d
                m = m.toString().length === 1 ? '0' + m : m
                this.date = Y + '-' + m + '-' + d
                this.time = (this.dateOnly ? '' : ' ' + this.time)
            }
        },
        mounted() {
            this.setPreselectedDate()
        },
        created() {
            this.config = {
                dateOnly: typeof this.$props.dateOnly === "undefined" ? false : this.$props.dateOnly,
                min: this.$props.min,
                default: typeof this.$props.preSelected === 'undefined' ? false : this.$props.preSelected
            }
        },
        computed: {},
        watch: {
            /*
            'date': {
                handler() {
                    this.$emit('chosenDate', this.returnTime())
                }
            },
            'time': {
                handler() {
                    this.$emit('chosenDate', this.returnTime())
                }
            }

             */
        }
    }
</script>

<style scoped>
    .v-card > .v-card__progress + :not(.v-btn):not(.v-chip), .v-card > :first-child:not(.v-btn):not(.v-chip) {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }

    .dateTimePickerRounding {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        overflow: hidden;
    }

    .v-time-picker-title__time .v-picker__title__btn, .v-time-picker-title__time span {
        height: 56px !important;
        font-size: 48px !important;
        text-align: center;
    }

    .v-time-picker-title {
        justify-content: center !important;
    }
</style>