<template>
    <v-dialog
        v-model="orderDetailsDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-toolbar color="primary" dark elevation="0">
                <v-toolbar-title>Order details</v-toolbar-title>
                <v-spacer/>
                <v-btn icon @click="orderDetailsDialog = !orderDetailsDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-sheet class="pa-4">
                <v-row>
                    <v-col cols="12" lg="6" xl="6">
                        <v-row dense>
                            <v-col class="font-weight-bold">Order id</v-col>
                            <v-col>KB-{{order.id}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col class="font-weight-bold">Product</v-col>
                            <v-col>{{order.orderType}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col class="font-weight-bold">Total price</v-col>
                            <v-col>{{toLocaleCurrency(order.orderPrice)}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col class="font-weight-bold">Order status</v-col>
                            <v-col>
                                <v-chip :color="statusColor(order.status).color" :dark="statusColor(order.status).dark" small class="my-0">{{order.status}}</v-chip>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col class="font-weight-bold">Ordered date</v-col>
                            <v-col>{{order.createdDate}}</v-col>
                        </v-row>
                        <v-row dense>
                            <v-col class="font-weight-bold">Order placed by</v-col>
                            <v-col>{{order.username}}</v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-card-subtitle class="font-weight-bold">Article details</v-card-subtitle>
                <v-expansion-panels v-model="panels" class="px-0">
                    <v-expansion-panel v-for="(article,idx) in order.articles" :key="article.id">
                        <v-expansion-panel-header class="font-weight-bold d-flex align-center justify-end dark-expansion-header py-2" color="primary" style="min-height:48px;">
                                <span class="white--text">
                                    <span>{{article.domain}} | Article {{idx + 1}}</span> <v-chip :color="statusColor(article.status).color" :dark="statusColor(article.status).dark" small class="my-0 mx-2">{{article.status}}</v-chip>
                                </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="py-4 px-0">
                            <v-row>
                                <v-col cols="6">
                                    <v-row dense>
                                        <v-col cols="12" lg="6" xl="6" class="font-weight-bold">Domain</v-col>
                                        <v-col cols="12" lg="6" xl="6">{{article.domain}}</v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="12" lg="6" xl="6" class="font-weight-bold">Anchor 1</v-col>
                                        <v-col cols="12" lg="6" xl="6">{{article.anchor_1}}</v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="12" lg="6" xl="6" class="font-weight-bold">Anchor 2</v-col>
                                        <v-col cols="12" lg="6" xl="6">{{article.anchor_2}}</v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="12" lg="6" xl="6" class="font-weight-bold">URL 1</v-col>
                                        <v-col cols="12" lg="6" xl="6">{{article.url_1}}</v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="12" lg="6" xl="6" class="font-weight-bold">URL 2</v-col>
                                        <v-col cols="12" lg="6" xl="6">{{article.url_2}}</v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="12" lg="6" xl="6" class="font-weight-bold">Focus keyword</v-col>
                                        <v-col cols="12" lg="6" xl="6">{{article.keyword}}</v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col clas="12" lg="6" xl="6" class="font-weight-bold">Article notes</v-col>
                                        <v-col clas="12" lg="6" xl="6">{{article.userComment}}</v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="6">
                                    <v-row dense>
                                        <v-col cols="12" lg="6" xl="6" class="font-weight-bold">Price</v-col>
                                        <v-col cols="12" lg="6" xl="6">
                                            <span class="currencyFormat">{{toLocaleCurrency(article.price)}}</span>
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="12" lg="6" xl="6" class="font-weight-bold">Desired publication date</v-col>
                                        <v-col cols="12" lg="6" xl="6">{{article.publishDate}}</v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="12" lg="6" xl="6" class="font-weight-bold">Article (download doc)</v-col>
                                        <v-col cols="12" lg="6" xl="6">
                                            <a v-if="article.file !== null && article.file.trim() !== ''" :href="article.file" target="_blank">{{article.file.split('/').pop()}}</a>
                                            <span v-else>-</span>
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="12" lg="6" xl="6" class="font-weight-bold">Article logo</v-col>
                                        <v-col cols="12" lg="6" xl="6">
                                            <a v-if="article.articleLogo !== null && article.articleLogo.trim() !== ''" :href="article.articleLogo" target="_blank">{{article.articleLogo.split('/').pop()}}</a>
                                            <span v-else>-</span>
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="12" lg="6" xl="6" class="font-weight-bold">Article images</v-col>
                                        <v-col cols="12" lg="6" xl="6">
                                            <a v-if="article.articleImages !== null && article.articleImages.trim() !== ''" :href="article.articleImages" target="_blank">
                                                {{article.articleImages.split('/').pop().split('?').shift()}}
                                            </a>
                                            <span v-else>-</span>
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col clas="12" lg="6" xl="6" class="font-weight-bold">Published URL</v-col>
                                        <v-col clas="12" lg="6" xl="6"><a :href="article.publishUrl" target="_blank">{{article.publishUrl}}</a></v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-sheet>
        </v-card>
        <template v-slot:activator="{on,attrs}">
            <v-btn
                v-on="on"
                x-small
                text
                outlined
            >Order details
            </v-btn>
        </template>
    </v-dialog>
</template>

<script>
    export default {
        name: "userOrderDetailsDialog",
        props: ['order'],
        data() {
            return {
                selectedOrder: {},
                orderDetailsDialog: false,
                panels: 0,
            }
        },
        methods: {
            toLocaleCurrency(number) {
                return (number * this.$store.state.exchangeRate[this.$store.state.currencyCode]).toLocaleString(this.$store.state.locale, {
                    style: 'currency',
                    currency: this.$store.state.currencyCode
                })
            },
            statusColor(status) {
                const colors = {
                    received: {
                        color: 'orange darken-1',
                        dark: true
                    },
                    processing: {
                        color: 'blue darken-1',
                        dark: true
                    },
                    published: {
                        color: 'green darken-1',
                        dark: true
                    },
                }
                return colors[status]
            },
        }
    }
</script>

<style scoped>
    .v-expansion-panel-content__wrap {
        padding-left: 0;
        padding-right: 0;
    }

    tbody tr td:first-of-type {
        font-weight: bold;
    }

    .currencyFormat {
        font-variant-numeric: tabular-nums;
    }
</style>