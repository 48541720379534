<template>
  <v-container class="pt-16 fill-height d-flex justify-space-between align-content-center flex-column loginPage">
    <v-sheet
        rounded
        elevation="8"
        class="py-10 px-8  mb-4"
        width="100%"
        max-width="480px"
        color="blue-grey darken-4 loginFront"
    >
      <div class="text-center">
        <a href="https://kaboozt.com">
          <img src="/img/kaboozt_logo.svg" width="250px" class="mx-auto"/>
        </a>
        <p class="pt-6 text-center white--text font-weight-bold">Start your link building journey today</p>
      </div>
      <login-form :dark="true"/>

    </v-sheet>
  </v-container>
</template>

<script>

    import LoginForm from "@/components/user/loginForm";

    export default {
        name: "Login",
        components: {LoginForm},
        data() {
            return {
                tabs: 0
            }
        },
        methods: {},
        beforeMount() {

        }
    }
</script>

<style scoped>
  .v-main {
    background-image: url('https://cdn.kaboozt.com/media/img/worldmap.svg');
    background-size: cover
  }
</style>