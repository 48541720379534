<template>
    <div>
        <v-sheet class="mb-2 py-4 px-4" rounded outlined elevation="1">
            <v-row dense>
                <v-col cols="12" class=" hidden-md-and-up">
                    <user-domains-dropdown class="mr-2"/>
                    <search-console-upload/>
                </v-col>
                <v-row dense>
                    <v-col cols="12" xl="3" lg="4" sm="6" class="py-1">
                        <v-text-field
                            hide-details
                            dense
                            outlined
                            label="Search domains..."
                            v-model="search"
                            color="orange"
                            class="searchInput"
                            :prepend-inner-icon="loading ? 'mdi-loading mdi-spin' :'mdi-magnify'"
                            :append-icon="search.trim().length >= 1 ? 'mdi-close':''"
                            @click:append="search = ''"
                        />
                    </v-col>
                    <v-col cols="12" xl="3" lg="4" sm="6" class="py-1">
                        <v-combobox
                            :items="specialsItems"
                            v-model="options.specials"
                            small-chips
                            clearable
                            multiple
                            dense
                            outlined
                            hide-details
                            :color="$vuetify.theme.dark ? 'white': 'primary'"
                            :disabled="loading"
                            label="Niches"
                            item-text="name"
                            item-value="id"
                            prepend-inner-icon="mdi-target"
                            @change="getSites"
                            :return-object="false"
                        >
                            <template v-slot:selection="{ attrs, item, select, selected }">
                                <v-chip
                                    v-bind="attrs"
                                    small
                                    @click="select"
                                    class="mb-1"
                                    :disabled="loading"
                                    label
                                    close
                                    outlined
                                    @click:close="removeNicheFromFilter(item)"
                                >
                                    <v-icon small class="mr-1">{{ specialsItems.find(x => x.id === item).icon }}</v-icon>
                                    {{ specialsItems.find(x => x.id === item).name }}
                                </v-chip>
                            </template>
                        </v-combobox>
                    </v-col>
                    <v-col cols="12" xl="3" lg="4" sm="6" class="py-1">
                        <v-combobox
                            :items="comboCategories"
                            v-model="selectedCategories"
                            small-chips
                            clearable
                            multiple
                            hide-details
                            :color="$vuetify.theme.dark ? 'white': 'primary'"
                            dense
                            outlined
                            :disabled="loading"
                            label="Categories"
                            class="categoryFilter"
                            prepend-inner-icon="mdi-checkbox-multiple-marked"
                            @change="updateCategoryFilter"
                        >
                            <template v-slot:selection="{ attrs, item, select, selected }">
                                <v-chip
                                    v-bind="attrs"
                                    :input-value="selected"
                                    small
                                    close
                                    :disabled="loading"
                                    @click="select"
                                    @click:close="removeCategory(item)"
                                    class="mb-1"
                                    label
                                    outlined
                                >
                                    <span v-if="typeof item === 'string'">{{ item }}</span>
                                    <span v-else-if="categories.find(x => x.id === item)">{{ categories.find(x => x.id === item).name}}</span>
                                </v-chip>
                            </template>
                        </v-combobox>
                    </v-col>
                    <v-col cols="12" xl="3" lg="4" sm="6" class="py-1">
                        <div class="d-flex flex-nowrap">
                            <div class="pr-1" style="flex-grow:1">
                                <v-combobox
                                    :items="countries"
                                    v-model="options.countries"
                                    small-chips
                                    clearable
                                    multiple
                                    dense
                                    outlined
                                    hide-details
                                    :disabled="loading"
                                    label="Target country"
                                    item-text="name"
                                    item-value="name"
                                    prepend-inner-icon="mdi-web"
                                    :color="$vuetify.theme.dark ? 'white': 'primary'"
                                    :return-object="false"
                                    @change="getSites"
                                >
                                    <template v-slot:selection="{ attrs, item, select, selected }">
                                        <v-chip
                                            v-bind="attrs"
                                            small
                                            @click="select"
                                            class="mb-1"
                                            :disabled="loading"
                                            label
                                            outlined
                                            close
                                            v-if="typeof countries.find(x => x.country == item) == 'object'"
                                            @click:close="removeLanguageFromFilter(item)"
                                        >
                                            <img
                                                :src="'https://cdn.kaboozt.com/media/img/flags/4x3/'+countries.find(x => x.country == item).code.toLowerCase()+'.svg'"
                                                :alt="item.country" height="10px" style="border:1px solid #dadada;"/>

                                            <span class="ml-1">{{ item }}</span>
                                        </v-chip>
                                    </template>
                                </v-combobox>
                            </div>
                            <span>&nbsp;</span>
                            <div class="pl-1" style="flex-grow:1">
                                <v-combobox
                                    :items="countries"
                                    v-model="options.languages"
                                    small-chips
                                    clearable
                                    multiple
                                    dense
                                    outlined
                                    hide-details
                                    :disabled="loading"
                                    label="Language"
                                    item-text="lang"
                                    item-value="lang"
                                    prepend-inner-icon="mdi-account-voice"
                                    :return-object="false"
                                    @change="getSites"
                                >
                                    <template v-slot:selection="{ attrs, item, select, selected }">
                                        <v-chip
                                            v-bind="attrs"
                                            small
                                            @click="select"
                                            class="mb-1"
                                            :disabled="loading"
                                            label
                                            outlined
                                            close
                                            v-if="typeof countries.find(x => x.lang == item) == 'object'"
                                            @click:close="removeLanguageFromFilter(item)"
                                        >
                                            <span class="ml-1">{{ item }}</span>
                                        </v-chip>
                                    </template>
                                </v-combobox>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12" xl="3" lg="4" sm="6" class="py-1">
                        <div class="d-flex flex-nowrap">
                            <div class="pr-1" style="flex-grow:1">
                                <v-text-field
                                    label="Min domain Rating (0-100)"
                                    placeholder="Min DR"
                                    type="number"
                                    min="0"
                                    max="99"
                                    :color="$vuetify.theme.dark ? 'white': 'primary'"
                                    v-model="options.domainRating[0]"
                                    @keydown.enter="setDomainRating(options.domainRating)"
                                    dense outlined hide-details
                                    prepend-inner-icon="mdi-star"
                                >
                                </v-text-field>
                            </div>
                            <div class="d-flex align-items-center">
                                <span>-</span>
                            </div>
                            <div class="pl-1" style="flex-grow:1">
                                <v-text-field
                                    label="Max domain Rating (0-100)"
                                    placeholder="Max DR"
                                    type="number"
                                    :min="isNullOrUndefined(options.domainRating[0]) ? 1 : options.domainRating[0]"
                                    max="100"
                                    :color="$vuetify.theme.dark ? 'white': 'primary'"
                                    v-model="options.domainRating[1]"
                                    @keydown.enter="setDomainRating(options.domainRating)"
                                    dense outlined hide-details
                                    prepend-inner-icon="mdi-star"
                                />
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" xl="3" lg="4" sm="6" class="py-1">
                        <div class="d-flex flex-nowrap ">
                            <div class="pr-1 flex-grow-0 " style="flex-basis: 50%;">
                                <v-text-field
                                    label="Min Referring domains (0)"
                                    placeholder="Min Referring domains"
                                    type="number"
                                    min="0"
                                    :color="$vuetify.theme.dark ? 'white': 'primary'"
                                    v-model="options.referringDomains[0]"
                                    @keydown.enter="setReferringDomains"
                                    dense outlined hide-details
                                    prepend-inner-icon="mdi-sitemap"
                                >
                                </v-text-field>
                            </div>
                            <div class="d-flex align-items-center">
                                <span>-</span>
                            </div>
                            <div class="pl-1 flex-grow-0 " style="flex-basis: 50%;">
                                <v-text-field
                                    :label="'Max referring domains ('+maxReferringDomains.toLocaleString()+')'"
                                    placeholder="Max Referring domains"
                                    type="number"
                                    :min="isNullOrUndefined(options.referringDomains[0]) ? 1 : options.referringDomains[0]"
                                    :color="$vuetify.theme.dark ? 'white': 'primary'"
                                    v-model="options.referringDomains[1]"
                                    @keydown.enter="setReferringDomains"
                                    dense outlined hide-details
                                    prepend-inner-icon="mdi-sitemap"
                                />
                            </div>
                        </div>
                        <!--v-select
                            :items="referringDomainsOptions"
                            v-model="selectedReferringDomains"
                            small-chips
                            clearable
                            dense
                            outlined
                            hide-details
                            :disabled="loading"
                            label="Referring domains"
                            prepend-inner-icon="mdi-sitemap"
                            :return-object="false"
                            @change="setReferringDomains"
                        >
                            <template v-slot:append-item>

                            </template>
                            <template v-slot:item="{item}">
                                <span class="ml-1">{{ item[0] }} - {{ item[1] }}</span>
                            </template>
                            <template v-slot:selection="{item}">
                                <span class="ml-1">{{ item[0] }} - {{ item[1] }}</span>
                            </template>
                        </v-select-->
                    </v-col>
                    <v-col cols="12" xl="3" lg="4" sm="6" class="py-1">

                        <div class="d-flex flex-nowrap ">
                            <div class="pr-1 flex-grow-0 " style="flex-basis: 50%;">
                                <v-text-field
                                    label="Organic traffic (0)"
                                    placeholder="Min Organic traffic"
                                    type="number"
                                    min="0"
                                    :color="$vuetify.theme.dark ? 'white': 'primary'"
                                    v-model="options.organicTraffic[0]"
                                    @keydown.enter="setOrganicTraffic"
                                    dense outlined hide-details
                                    prepend-inner-icon="mdi-account-group"
                                >
                                </v-text-field>
                            </div>
                            <div class="d-flex align-items-center">
                                <span>-</span>
                            </div>
                            <div class="pl-1 flex-grow-0 " style="flex-basis: 50%;">
                                <v-text-field
                                    :label="'Max organic traffic ('+maxOrganicTraffic.toLocaleString()+')'"
                                    placeholder="Max Organic traffic"
                                    type="number"
                                    :min="isNullOrUndefined(options.organicTraffic[0]) ? 1 : options.organicTraffic[0]"
                                    :color="$vuetify.theme.dark ? 'white': 'primary'"
                                    v-model="options.organicTraffic[1]"
                                    @keydown.enter="setOrganicTraffic"
                                    dense outlined hide-details
                                    prepend-inner-icon="mdi-account-group"
                                />
                            </div>
                        </div>
                        <!--v-select
                            :items="organicTrafficOptions"
                            v-model="selectedOrganicTraffic"
                            clearable
                            dense
                            outlined
                            hide-details
                            :disabled="loading"
                            label="Traffic"
                            prepend-inner-icon="mdi-account-group"
                            :return-object="false"
                            @change="setOrganicTraffic"
                        >
                            <template v-slot:item="{item}">
                                <span class="ml-1">{{ item[0] }} - {{ item[1] }}</span>
                            </template>
                            <template v-slot:selection="{item}">
                                <span class="ml-1">{{ item[0] }} - {{ item[1] }}</span>
                            </template>

                        </v-select-->

                    </v-col>
                    <v-col cols="12" xl="3" lg="4" sm="6" class="py-1">
                        <div class="d-flex flex-nowrap px-0">
                            <div class="pr-1" style="flex-grow:1">
                                <v-text-field
                                    prepend-inner-icon="mdi-cash-multiple"
                                    dense
                                    outlined
                                    hide-details
                                    v-model="options.price[0]"
                                    placeholder="Min price"
                                    label="Min price"
                                    @keydown.enter="getSites"
                                    type="number"/>
                            </div>
                            <div class="d-flex align-items-center">
                                <span>-</span>
                            </div>
                            <div class="pl-1" style="flex-grow:1">
                                <v-text-field
                                    prepend-inner-icon="mdi-cash-multiple"
                                    dense
                                    outlined
                                    hide-details
                                    v-model="options.price[1]"
                                    placeholder="Max price"
                                    label="Max price"
                                    @keydown.enter="getSites"
                                    type="number"/>
                            </div>
                            <!--div class="pl-1" style="flex-basis:50px">
                                <v-btn width="100%" color="primary" @click="getSites">
                                    <v-icon>mdi-check</v-icon>
                                </v-btn>
                            </div-->
                        </div>
                    </v-col>
                    <!--v-col cols="12" xl="3" lg="4" sm="6" class="py-1">
                        <v-select
                            :items="priceOptions"
                            v-model="selectedPrice"
                            small-chips
                            clearable
                            dense
                            outlined
                            hide-details
                            :disabled="loading"
                            label="Price range"
                            prepend-inner-icon="mdi-cash-multiple"
                            :return-object="false"
                            @change="setPriceRange"
                        >
                            <template v-slot:append-item>
                                <div class="d-flex flex-nowrap px-4" style="max-width: 493px;">
                                    <div class="pa-1" style="flex-grow:1">
                                        <v-text-field dense outlined hide-details v-model="options.price[0]" placeholder="Min price" label="From" @keydown.enter="getSites"/>
                                    </div>
                                    <div class="pa-1" style="flex-grow:1">
                                        <v-text-field dense outlined hide-details v-model="options.price[1]" placeholder="Max price" label="To" @keydown.enter="getSites"/>
                                    </div>
                                    <div class="pa-1" style="flex-basis:50px">
                                        <v-btn width="100%" color="primary" @click="getSites">
                                            <v-icon>mdi-check</v-icon>
                                        </v-btn>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:item="{item}">
                                <span class="ml-1">{{ item[0] }} - {{ item[1] }}</span>
                            </template>
                            <template v-slot:selection="{item}">
                                <span class="ml-1">{{ item[0] }} - {{ item[1] }}</span>
                            </template>
                        </v-select>
                    </v-col-->
                </v-row>
            </v-row>
        </v-sheet>
        <v-row class="pb-2" dense>
            <v-col cols="12" xl="3" lg="4" sm="6">
            </v-col>
            <v-col cols="12" xl="3" lg="4" sm="6"></v-col>
            <v-col cols="12" xl="3" lg="4" sm="6"></v-col>
            <v-col cols="12" xl="3" lg="4" sm="6">
                <v-tooltip top>
                    <template v-slot:activator="{on,attrs}">
                        <v-icon v-on="on" v-bind="attrs" class="float-right pa-1">mdi-information-outline</v-icon>
                    </template>
                    <span>Slow filter results, may occur.<br>If you experience this, try adding language and / or country filter.</span>
                </v-tooltip>
                <v-btn v-if="Object.entries(savedOptions).length === 0" text @click="saveOptions()" class="float-right" small outlined>
                    <v-icon left>mdi-floppy</v-icon>
                    Save current filters
                </v-btn>
                <v-btn v-if="Object.entries(savedOptions).length > 0" text @click="clearSavedOptions()" class="float-right" small outlined>
                    <v-icon left>mdi-refresh</v-icon>
                    Clear saved filters
                </v-btn>

                <v-tooltip top>
                    <template v-slot:activator="{on,attrs}">
                        <v-btn v-on="on" text @click="resetFilters" class="float-right mr-1" small outlined>
                            <v-icon left>mdi-replay</v-icon>
                            Reset filters
                        </v-btn>
                    </template>
                    This will NOT reset "saved filters".
                </v-tooltip>
            </v-col>
        </v-row>
        <v-sheet class="mb-0 pa-0" outlined rounded elevation="1">

            <v-row>
                <v-col>
                    <v-data-table
                        :footer-props="{
                            itemsPerPageOptions:[18, 36, 50, 100, 200],
                            showFirstLastPage: true,
                            firstIcon: 'mdi-chevron-double-left',
                            lastIcon: 'mdi-chevron-double-right',
                            'show-current-page':true,
                            '#show-first-last-page':true,
                        }"
                        :headers="_headers"
                        :items="domainList"
                        :loading="loading"
                        :options.sync="options"
                        :search="options.search"
                        :server-items-length="totalItems"
                        selectable-key="isSelectable"
                        class="overview-table transparent"
                        sort-by="organicTraffic"
                        sort-desc
                        dense
                        show-select
                        v-model="selected"
                        :mobile-breakpoint="$store.state.dataTables.breakPoint"
                    >
                        <template v-slot:item.actions="{ item }">
                            <site-editor :selected="item" :countries="countries"/>
                        </template>
                        <!-- HEADERS -->
                        <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }">

                            <div v-if="header.value === 'order'">
                                <add-multiple-to-cart
                                    :selected="selected"
                                    @domainRemoved="updateSelected"
                                    @addedToCart="selected = []"
                                />
                            </div>

                            <div class="py-2 d-inline-block" v-if="header.value !== 'order'">
                                <v-tooltip bottom v-if="header.tip">
                                    <template v-slot:activator="{ on }">
                                        <div>
                                            <div
                                                v-if="header.value === 'domainRating' || header.value === 'referringDomains'"
                                                class="ahrefsLogoWrap">
                                                <img
                                                    v-if="$vuetify.theme.dark"
                                                    src="/img/ahrefs-logo.svg"
                                                    class="d-inline-block"
                                                    v-on="on"
                                                    alt="Ahrefs logo"
                                                />
                                                <img
                                                    v-else
                                                    src="/img/ahrefs-logo_dark.svg"
                                                    class="d-inline-block"
                                                    v-on="on"
                                                    alt="Ahrefs logo"
                                                />
                                                <span class="d-inline-block" v-on="on" style="font-size: 14px">&nbsp;{{ header.text }}</span>
                                            </div>
                                            <div v-if="header.value === 'morningScore'">
                                                <img
                                                    v-if="$vuetify.theme.dark"
                                                    src="/img/morningscore-logo.svg" height="12px"
                                                    class="d-inline-block"
                                                    v-on="on"
                                                    alt="Morningscore logo"
                                                />
                                                <img
                                                    v-else
                                                    src="/img/morningscore-logo_inverse.svg" height="12px"
                                                    class="d-inline-block"
                                                    v-on="on"
                                                    alt="Morningscore logo"
                                                />
                                            </div>

                                        </div>
                                    </template>
                                    <span>{{ header.tip }}</span>
                                </v-tooltip>
                                <v-tooltip v-if="header.value === 'price'" bottom>
                                    <template v-slot:activator=" {on}">
                                        <span v-on="on" style="font-size: 14px;">Price *</span>
                                    </template>
                                    Prices other than DKK are calculated by indicative exchange rates in relation to DKK. <br>
                                    All purchases will be charged in DKK.<br>
                                    Prices listed as 0 (zero), are uncertain, and not free ;)
                                </v-tooltip>
                                <span v-if="!header.tip && header.value !== 'price'" style="font-size: 14px">{{ header.text }}</span>
                            </div>
                        </template>

                        <!-- BUY BUTTON -->
                        <template v-slot:item.order="{item}">
                            <add-to-cart-button :orderItem="item" v-if="!!domainAlreadyInCart(item)"/>
                            <span v-else>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{on}">
                                        <v-btn x-small elevation="0" color="blue lighten-2" dark :ripple="false"
                                               v-on="on">
                                            <v-icon size="16">mdi-alert</v-icon>&nbsp;In cart
                                        </v-btn>
                                    </template>
                                    Add to, remove from and otherwise modify your order in the cart
                                </v-tooltip>
                            </span>
                        </template>

                        <!-- Domain note -->
                        <template v-slot:item.note="{item}">
                            <span
                                style="white-space: nowrap;text-overflow: ellipsis;overflow:hidden;">{{ item.note }}</span>
                        </template>

                        <!-- DOMAIN NAME -->
                        <template v-slot:item.domain="{item}">
                            <v-tooltip
                                bottom
                                v-if="item.note !== null && item.note.trim().length >= 2"
                            >
                                <template v-slot:activator="{ on }">
                                    <span v-on="on" class="d-flex flex-nowrap">
                                        <a
                                            :href="'https://'+item.domain"
                                            target="_blank"
                                            class="text-decoration-none">
                                            <v-icon small>mdi-open-in-new</v-icon>
                                        </a>
                                        <span>&nbsp;{{ item.domain }}*</span>
                                    </span>
                                </template>
                                <span v-html="domainNotes(item)"></span>
                            </v-tooltip>
                            <span v-else class="d-flex flex-nowrap">
                                <a
                                    :href="'https://'+item.domain"
                                    target="_blank"
                                    class="text-decoration-none"
                                >
                                    <v-icon small>mdi-open-in-new</v-icon>
                                </a>
                                <span>&nbsp;{{ item.domain }}</span>
                            </span>
                        </template>

                        <template v-slot:item.backlink="{item}">
                            <v-tooltip bottom max-width="300px">
                                <template v-slot:activator="{on,attrs}">
                                    <v-chip
                                        v-if="typeof item.linkType === 'undefined' || item.linkType == null"
                                        v-on="on"
                                        color="purple"
                                        dark
                                        x-small
                                    >No backlink
                                    </v-chip>

                                    <v-chip v-else
                                            v-on="on"
                                            :color="item.linkType === 'nofollow' ? 'red' : 'green'"
                                            small
                                            dark>{{ item.linkType }}
                                    </v-chip>
                                </template>

                                <div
                                    v-html="typeof item.linkType !== 'undefined' && item.linkType !== null ? linkTypes.find(x => x.type == item.linkType).description : 'Domain does not have links to you website'"
                                    style="font-size:16px"
                                ></div>
                            </v-tooltip>
                        </template>

                        <!-- CATEGORIES -->
                        <template v-slot:item.categories="{item}">
                            <span v-if="item.categories.split(',').length === 1" style="white-space: nowrap">
                                {{ categoryNameById(item.categories) }}
                            </span>
                            <v-tooltip bottom v-else>
                                <template v-slot:activator="{ on }">
                                <span
                                    v-on="on"
                                    v-if="(item.categories.split(',').length > 1)"
                                    style="white-space: nowrap">
                                    {{ categoryNameById(item.categories.split(',').sort()[0]) }}...
                                </span>
                                </template>
                                <span>{{ categoryNameById(item.categories) }}</span>
                            </v-tooltip>
                        </template>


                        <!-- PRICE -->
                        <template v-slot:item.kbPrice="{item}">
                            <span v-if="item.confirmArticle == 0" class="currencyFormat">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{on,attrs}">
                                        <span v-on="on" v-bind="attrs">* {{ toLocaleCurrency(item.kbPrice) }}</span>
                                    </template>
                                    This price is estimated, and may vary<br> considerably from the listed price.
                                </v-tooltip>
                            </span>
                            <span v-else class="currencyFormat">
                                {{ toLocaleCurrency(item.kbPrice) }}
                            </span>
                        </template>

                        <!-- PRICE  -->
                        <template v-slot:item.price="{item}">
                            <span class="currencyFormat">
                                {{ toLocaleCurrency(item.price) }}
                            </span>
                        </template>

                        <!-- PURCHASE PRICE -->
                        <template v-slot:item.purchasePrice="{item}">
                            <span class="currencyFormat">
                                {{ toLocaleCurrency(item.purchasePrice) }} <v-chip :color="item.confirmArticle == 0 ? 'red' : 'green'" x-small>{{item.confirmArticle == 0 ? 'U' : 'C'}}</v-chip>
                            </span>
                        </template>

                        <!-- NICHES -->
                        <template v-slot:item.topics="{item}">

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" :color="item.casinoAllowed ? 'primary':'grey'">mdi-dice-6</v-icon>
                                </template>
                                <span v-if="item.casinoAllowed">Casino allowed</span>
                                <span v-else>Casino not allowed</span>
                                <span v-if="nicheMultiplier(item.kbPrice,item.casinoPrice) !== false">
                                    <br>Price x{{ nicheMultiplier(item.kbPrice, item.casinoPrice) }}
                                </span>

                            </v-tooltip>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" :color="item.loanAllowed ? 'primary':'grey'">mdi-cash-multiple
                                    </v-icon>
                                </template>
                                <span v-if="item.loanAllowed">Loans allowed</span>
                                <span v-else>Loans not allowed</span>
                                <span v-if="nicheMultiplier(item.kbPrice,item.loanPrice) !== false">
                                    <br>Price x{{ nicheMultiplier(item.kbPrice, item.loanPrice) }}
                                </span>
                            </v-tooltip>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" :color="item.cbdAllowed ? 'primary':'grey'">mdi-cannabis</v-icon>
                                </template>
                                <span v-if="item.cbdAllowed">CBD allowed</span>
                                <span v-else>CBD not allowed</span>
                                <span v-if="nicheMultiplier(item.kbPrice,item.cbdPrice) !== false">
                                    <br>Price x{{ nicheMultiplier(item.kbPrice, item.cbdPrice) }}
                                </span>
                            </v-tooltip>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" :color="item.cryptoAllowed ? 'primary':'grey'">mdi-bitcoin
                                    </v-icon>
                                </template>
                                <span v-if="item.cryptoAllowed">Crypto allowed</span>
                                <span v-else>Crypto not allowed</span>
                                <span v-if="nicheMultiplier(item.kbPrice,item.cryptoPrice) !== false">
                                    <br>Price x{{ nicheMultiplier(item.kbPrice, item.cryptoPrice) }}
                                </span>
                            </v-tooltip>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" :color="item.eroticAllowed ? 'primary':'grey'">mdi-handcuffs
                                    </v-icon>
                                </template>
                                <span v-if="item.eroticAllowed">Erotica allowed</span>
                                <span v-else>Erotica not allowed</span>
                                <span v-if="nicheMultiplier(item.kbPrice,item.eroticPrice) !== false">
                                    <br>Price x{{ nicheMultiplier(item.kbPrice, item.eroticPrice) }}
                                </span>
                            </v-tooltip>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" :color="item.datingAllowed ? 'primary':'grey'">mdi-heart</v-icon>
                                </template>
                                <span v-if="item.datingAllowed">Dating allowed</span>
                                <span v-else>Dating not allowed</span>
                                <span v-if="nicheMultiplier(item.kbPrice,item.datingPrice) !== false">
                                    <br>Price x&nbsp;{{ nicheMultiplier(item.kbPrice, item.datingPrice) }}
                                </span>
                            </v-tooltip>

                        </template>

                        <template v-slot:item.textInclusive="{item}">
                            <div class="overflow-x-hidden text-no-wrap">{{ item.textInclusive }}</div>
                        </template>

                        <template v-slot:item.createdDate="{item}">
                            {{ formatDate(item.createdDate, 'da-DK') }}
                        </template>

                        <template v-slot:item.country="{item}">
                            <span>{{item.country}}</span>
                            <!--img :src="countryFlag(item.domain)" :alt="item.country"
                                 @error="onImageError"
                                 style="width:20px;border:1px solid #dadada;"/-->
                        </template>

                        <template v-slot:item.organicTraffic="{item}">
                            {{ item.organicTraffic.toLocaleString('da-DK') }}
                        </template>

                        <template v-slot:item.domainRating="{item}">
                            <v-chip small dark color="orange darken-3">{{ item.domainRating }}</v-chip>
                        </template>

                        <template v-slot:item.referringDomains="{item}">
                            <v-chip small dark color="orange darken-3">
                                {{ item.referringDomains.toLocaleString($store.state.locale) }}
                            </v-chip>
                        </template>

                        <template v-slot:item.morningScore="{item}">
                            {{ item.morningScore.toLocaleString($store.state.locale) }}
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-sheet>
    </div>
</template>

<script>
    import api from "@/services/api";
    import TableView from "../tableView";
    import AddToCartButton from "@/components/cart/addToCartButton";
    import UserDomainsDropdown from "@/components/sites/backlinkProfile/userDomainsDropdown";
    import AddMultipleToCart from "@/components/cart/addMultipleToCart";
    import SearchConsoleUpload from "@/components/sites/backlinkProfile/search-console/searchConsoleUpload";
    import SiteEditor from "@/components/sites/siteEditor";


    export default {
        name: "siteOverview",
        components: {SiteEditor, SearchConsoleUpload, AddMultipleToCart, UserDomainsDropdown, AddToCartButton, TableView},
        data() {
            return {
                headers: [
                    {text: "", value: "order", width: '90px', sortable: false, show: true,},
                    /* {text: "Price", value: "price", width: '125px', align: 'right', show: true,},*/
                    {text: "Price", value: "kbPrice", width: '175px', align: 'right', show: true,},
                    /* {text: "discount price", value: "priceDiscount", align: 'right', show: true,},*/
                    {text: "Domain", value: "domain", show: true,},
                    {text: "Traffic", value: "organicTraffic", width: '110px', show: true,},
                    {text: "DR", value: "domainRating", tip: 'Ahrefs: Domain Rating', width: '115px', align: 'center', show: true,},
                    {text: "RD", value: "referringDomains", tip: 'Ahrefs: Referring domains', width: '115px', align: 'center', show: true,},
                    {text: "Morningscore", value: "morningScore", tip: 'MorningScore.io', width: '140px', show: true,},
                    {text: "Country", value: "country", width: '155px', show: true,},
                    {text: "Language", value: "lang", width: '140px', show: true,},
                    {text: "Category", value: "categories", width: '130px', sortable: false, show: true,},
                    {text: "Niche topics", value: "topics", width: '180px', sortable: false, show: true,},
                ],
                items: [],
                selected: [],
                totalItems: 0,
                loading: false,
                search: '',
                timeout: null,
                options: {
                    search: '',
                    categories: [],
                    specials: [],
                    price: [],
                    domainRating: [],
                    referringDomains: [],
                    organicTraffic: [],
                    countries: [],
                    languages: [],
                    page: 1,
                    itemsPerPage: 18,
                    sortBy: ['organicTraffic'],
                    sortDesc: [false],
                    mustSort: false,
                    multiSort: false,
                },
                specialsItems: [
                    {id: 1, name: 'Gambling', icon: 'mdi-dice-6'},
                    {id: 2, name: 'Loan', icon: 'mdi-cash-multiple'},
                    {id: 3, name: 'Erotic', icon: 'mdi-handcuffs'},
                    {id: 4, name: 'Dating', icon: 'mdi-heart'},
                    {id: 5, name: 'CBD', icon: 'mdi-cannabis'},
                    {id: 6, name: 'Crypto', icon: 'mdi-bitcoin'},
                ],
                categories: [],
                countries: [],
                languages: [],
                selectedCategories: [],
                selectedPrice: [],
                selectedReferringDomains: [],
                selectedOrganicTraffic: [],
                selectedDomainRating: [],
                priceOptions: [
                    ['0', '5.000'],
                    ['5.000', '10.000'],
                    ['10.000', '50.000'],
                    ['50.000', '100.000'],
                    ['100.000', 'And above']
                ],
                domainRatingOptions: [
                    [0, 20],
                    [20, 40],
                    [40, 60],
                    [60, 80],
                    [80, 100],
                ],
                referringDomainsOptions: [
                    [0, '1.000'],
                    ['1.000', '5.000'],
                    ['5.000', '10.000'],
                    ['10.000', '50.000'],
                    ['500.00', '100.000'],
                    ['100.000', 'And above']
                ],
                organicTrafficOptions: [
                    [0, '10.000'],
                    ['10.000', '25.000'],
                    ['25.000', '50.000'],
                    ['50.000', '100.000'],
                    ['100.000', '500.000'],
                    ['500.000', '1.000.000'],
                    ['1.000.000', '50.000.000'],
                    ['50.000.000', '100.000.000'],
                    ['100.000.000', 'And above']

                ],
                linkTypesLoading: false,
                resetLoad: false,
                linkTypes: [
                    {type: "dofollow", description: 'By default, all links are dofollow links unless modified to become nofollow.  <br>Dofollow links are commonly considered to pass "weight" and help a target website or URL to rank better in organic search results.'},
                    {type: "redirect", description: 'A redirect is a way to send both users and search engines to a different URL from the one they originally requested/want to visit.'},
                    {type: "governmental", description: 'Governmental links are links from .gov sites.'},
                    {type: "educational", description: 'Educational links are links from .edu sites.'},
                    {type: "nofollow", description: 'A nofollow link is a link with the attribute rel="nofollow". <br>This tells search engines to not crawl them or count them as votes of quality.'},
                    {type: "ugs", description: ' User Generated Content link is a link with the attribute rel= "ugc".<br> This attribute value is recommended for links within user-generated content,<br> such as comments and forum posts.'},
                    {type: "sponsored", description: 'A sponsored link is a link with the attribute rel="sponsored". <br>This includes links that were created as part of advertisements, sponsorships, or other compensation agreements.'},
                    {type: "gscBacklink", description: 'Google Search Console loaded backlink<br>Backlink type unvailable'},
                ],
                savableOptions: [
                    'countries',
                    'categories',
                    'specials',
                    'domainRating',
                    'referringDomains',
                    'organicTraffic',
                    'price'
                ],
                savedOptions: {},
                counters: {}
            }
        },
        methods: {
            removeHeader() {
                this.headers.pop()
            },
            sortBy(header) {
                if (header.sortable === false) {
                    return
                }
                if (header.value === this.options.sortBy[0]) {
                    this.options.sortDesc[0] = !this.options.sortDesc[0]
                } else {
                    this.options.sortBy = [header.value]
                    this.options.sortDesc[0] = true
                }
                this.getSites()
            },
            getSites(resetPage = false) {
                if (this.loading) {
                    return
                }
                this.loading = true
                if (!!resetPage) {
                    this.options.page = 1
                }
                api.get('/sites', {
                    params: this.options
                })
                    .then(response => {
                        this.items = response.data.items
                        this.addLinkTypes()
                        this.totalItems = response.data.totalItems
                        this.allItems = response.data.totalItems
                        this.counters = response.data.counters
                        this.$emit('publishersLoaded', this.totalItems)
                        this.loading = false
                        this.resetLoad = false
                        if (this.items.length >= 1 && typeof this.items[0].purchasePrice !== 'undefined' && typeof this.headers.find(x => x.value === 'purchasePrice') === 'undefined') {
                            this.headers.splice(2, 0, {
                                text: "pPrice",
                                value: "purchasePrice",
                                width: '125px',
                                align: 'right',
                                show: true,
                            },)
                            this.headers.splice(1, 0, {
                                text: "",
                                value: "actions",
                                width: '40px',
                                show: true,
                                sortable: false,
                            })
                        }
                    })
            },
            getCategories() {
                api.get('categories')
                    .then(response => {
                        // Sort by name before setting categories
                        this.categories = response.data.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0)
                    })
            },
            categoryNameById(ids) {
                let categories = []
                let category
                ids.split(',').forEach(id => {
                    category = this.categories.find(x => x.id === id * 1) // * 1 = cast to int
                    typeof category !== 'undefined' ? categories.push(category.name) : ''
                })
                return categories.join(', ')

            },
            setPriceRange(e) {
                this.options.price = e
                this.getSites(true)
            },
            setDomainRating(e) {
                let min = isNaN(e[0] * 1) ? 0 : (e[0] * 1)
                let max = isNaN(e[1] * 1) ? 100 : (e[1] * 1)
                min = min < 0 || min > 99 ? 0 : min
                max = max > 100 || max < min ? 100 : max
                this.options.domainRating = [
                    isNaN(min) ? 0 : min > max ? max : min,
                    isNaN(max) ? 100 : max,
                ]
                this.getSites(true)
            },
            setReferringDomains(e) {
                //this.options.referringDomains = e
                this.getSites(true)
            },
            setOrganicTraffic(e) {
                //this.options.organicTraffic = e
                this.getSites(true)
            },
            randomColor() {
                return (function lol(m, s, c) {
                    return s[m.floor(m.random() * s.length)] +
                        (c && lol(m, s, c - 1));
                })(Math, '3456789ABCDEF', 6)
            },
            removeCategory(item) {
                let category
                if (typeof item === 'string') {
                    category = this.categories.find(x => x.name === item)
                } else {
                    category = this.categories.find(x => x.id === item)
                }
                let optionsIndex = this.options.categories.indexOf(category.id)
                let options = this.options.categories.splice(optionsIndex + 1, 1)
                this.selectedCategories = this.selectedCategories.filter(category => category !== item)
                this.options.categories = options
            },
            removeLanguageFromFilter(item) {
                this.options.languages = this.options.languages.filter(x => x !== item)
                this.getSites(true)
            },
            removeCountryFromFilter(item) {
                this.options.countries = this.options.countries.filter(x => x !== item)
                this.getSites(true)
            },
            removeNicheFromFilter(nicheId) {
                let optionsIndex = this.options.specials.indexOf(nicheId)
                let options = this.options.specials.splice(optionsIndex + 1, 1)
                this.options.special = this.selectedCategories.filter(niche => niche !== item)
                this.options.specials = options
            },
            updateCategoryFilter() {
                if (this.resetLoad) {
                    return
                }
                let allCategories = this.categories
                this.options.categories = this.selectedCategories.map(function (e) {
                    return allCategories.find(x => x.name === e) ? allCategories.find(x => x.name === e).id : ''
                })
                this.getSites(true)
            },
            domainNotes(item) {
                let parts = item.domain.split('Info: ')
                return item.note + (parts.length > 1 ? '<br>\r\n' + parts[1] : '')
            },
            formatDate(dateString) {
                const [Y, m, d] = dateString.split(' ')[0].split('-')
                return `${d}-${m}-${Y}`
            },
            newOrder(item) {
                this.currentOrder = item
            },
            countryFlag(domain) {
                let url = new URL('https://' + domain)
                return 'https://cdn.kaboozt.com/media/img/flags/4x3/' + url.host.split('.').pop() + '.svg'
            },
            onImageError(event) {
                event.target.src = 'https://cdn.kaboozt.com/media/img/flags/4x3/earth.svg';
            },

            log(obj) {
                console.log(obj)
            },
            getcountries() {
                api.get('/sites/countries')
                    .then(response => {
                        this.countries = response.data
                    })
            },
            resetFilters() {
                this.resetLoad = true
                this.search = ''
                this.selectedCategories = []
                this.selectedPrice = []
                this.selectedReferringDomains = []
                this.selectedOrganicTraffic = []
                this.selectedDomainRating = []
                this.options = {
                    search: '',
                    categories: [],
                    specials: [],
                    price: [],
                    domainRating: [],
                    referringDomains: [],
                    organicTraffic: [],
                    countries: [],
                    languages: [],
                    page: 1,
                    itemsPerPage: 18,
                    sortBy: ['organicTraffic'],
                    sortDesc: [false],
                    mustSort: false,
                    multiSort: false,
                }
                this.loadOptions()
                this.$store.dispatch('backlinkModule/unsetBacklinks')
                this.getSites(true)
            },
            updateSelected(item) {
                this.selected = this.selected.filter(x => x.id !== item.id)
            },
            domainAlreadyInCart(item) {
                return (this.$store.state.cartModule.orders.findIndex(x => x.domain === item.domain) < 0)
            },
            toLocaleCurrency(number) {
                return (number * this.$store.state.exchangeRate[this.$store.state.currencyCode]).toLocaleString(this.$store.state.locale, {
                    style: 'currency',
                    currency: this.$store.state.currencyCode
                })
            },
            nicheMultiplier(price, nichePrice) {
                if (nichePrice !== 0) {
                    return (nichePrice / price).toFixed(2)
                }
                return false
            },
            addLinkTypes() {
                this.linkTypesLoading = true
                const referringDomains = this.$store.state.backlinkModule.referringDomains
                if (referringDomains.length > 0) {
                    if (this.headers.findIndex(x => x.value == 'backlink') < 0) {
                        const domainIndex = this.headers.findIndex(x => x.value === 'domain')
                        this.headers.splice(domainIndex, 0,
                            {text: "Backlink", value: "backlink", width: '125px', align: 'center', sortable: false, show: true,},
                        )
                    }
                    this.items.forEach((obj, index) => {
                        referringDomains.forEach((referringDomain, idx) => {
                            obj.domain = obj.domain.toLowerCase()
                            if (this.matchesURL(obj.domain.toLowerCase(), referringDomain.refdomain.toLowerCase())) {
                                this.items[index].linkType = referringDomain.link_type
                            }
                        })
                    })

                }
                this.linkTypesLoading = false
            },
            escapeRegExp(string) {
                return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
            },
            matchesURL(url, searchString) {
                const pattern = '^' + this.escapeRegExp(searchString) + '(\/.*)?$';
                const regex = new RegExp(pattern);
                return regex.test(url);
            },
            saveOptions() {
                let save = {}
                this.options.forEach((property, value) => {
                    if (this.savableOptions.includes(property)) {
                        save[property] = this.options[property]
                    }
                })
                this.savedOptions = save
                this.$store.dispatch('toasts/toastSuccess', 'Filters saved.')
                localStorage.setItem('overviewOptions', JSON.stringify(save))
            },
            clearSavedOptions() {
                localStorage.setItem('overviewOptions', JSON.stringify({}))
                this.savedOptions = JSON.parse(localStorage.getItem('overviewOptions'))
                this.$store.dispatch('toasts/toastSuccess', 'Saved filters cleared.')
                this.resetFilters()
            },
            loadOptions() {
                let savedOptions = JSON.parse(localStorage.getItem('overviewOptions'))
                if (savedOptions === null) {
                    savedOptions = {}
                }
                this.savedOptions = savedOptions
                savedOptions.forEach((property, value) => {
                    switch (property) {
                        case 'categories':
                            this.selectedCategories = value
                            break;
                        case 'domainRating':
                            this.selectedDomainRating = value;
                            break;
                        case 'organicTraffic':
                            this.selectedOrganicTraffic = value;
                            break;
                        case 'price':
                            this.selectedPrice = value;
                            break;
                        case 'referringDomains':
                            this.selectedReferringDomains = value;
                            break;
                        default:
                            break;
                    }
                    this.options[property] = value
                })
            },
            isNullOrUndefined(val) {
                return typeof val === 'undefined' || typeof val === null ? true : false
            }

        },
        mounted() {
            this.getSites()
            this.getCategories()
            this.getcountries()
        },
        beforeMount() {
            this.loadOptions()
        },
        computed: {
            comboCategories: function () {
                return this.categories.map(e => {
                    return e.name
                }).sort()
            },
            domainList() {
                return this.items.map(x => ({...x, isSelectable: this.domainAlreadyInCart(x)}))
            },
            _headers() {
                return this.headers.filter(x => x.show === true)
            },
            maxReferringDomains() {
                return typeof this.counters.max_referringDomains !== 'undefined' ? this.counters.max_referringDomains : 0
            },
            maxOrganicTraffic() {
                return typeof this.counters.max_organicTraffic !== 'undefined' ? this.counters.max_organicTraffic : 0
            }
        },
        watch: {
            'options.page': {
                handler() {
                    if (this.resetLoad) {
                        return
                    }
                    clearTimeout(this.timeout)
                    let self = this;
                    let pageValue = self.options.page
                    this.timeout = setTimeout(() => {
                        self.options.page = pageValue
                        this.getSites()
                    }, 250);
                }
            },
            search: {
                handler() {
                    if (this.resetLoad) {
                        return
                    }
                    clearTimeout(this.timeout)
                    let self = this;
                    if (self.options.search === self.search) {
                        return
                    }
                    let searchValue = self.search
                    this.timeout = setTimeout(() => {
                        self.options.page = 1
                        self.options.search = searchValue
                        this.getSites()
                    }, 750);
                },
                immediate: true,
            },
            selectedCategories: {
                handler() {
                    this.updateCategoryFilter()
                }
            },
            'options.specials': {
                handler() {
                    if (this.resetLoad) {
                        return
                    }
                    this.getSites()
                }
            },
            '$store.state.backlinkModule.referringDomains': {
                handler() {
                    this.getSites()
                }
            },
        },
    }
</script>

<style>
    .tableHeader {
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
    }

    .ahrefsLogoWrap {
        text-align: center;
    }

    .ahrefsLogoWrap img {
        height: 11px;
        margin: 0 auto;
    }

    .theme--light .activeSort,
    .theme--light .activeSort + .sortingArrow {
        color: #000 !important;
        opacity: 1;
    }

    .theme--dark .activeSort,
    .theme--dark .activeSort + .sortingArrow {
        color: #fff !important;
        opacity: 1;
    }

    .sortingArrow {
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
        position: absolute !important;
        opacity: 0;
    }

    .tableHeader:hover .sortingArrow {
        opacity: 1;
    }

    .sortingArrow.asc {
        transform: rotateZ(180deg);
    }

    .currencyFormat {
        font-variant-numeric: tabular-nums;
    }

    /*
    .overview-table thead:first-of-type th:first-child {
        border-top-left-radius: 4px;
    }

    .overview-table thead:first-of-type th:last-child {
        border-top-right-radius: 4px;
    }

    .v-list--dense .v-list-item {
        min-height: 32px;
    }

    input {
        min-height: 32px !important;
    }

    .v-text-field.v-input--dense .v-input__append-inner, .v-text-field.v-input--dense .v-input__prepend-inner {
        margin-top: 4px;
    }

     */
    .v-list-item__action {
        margin: 0 10px 0 0 !important;
    }

    .searchInput fieldset {
        border-color: rgba(255, 85, 41, 1) !important;
    }

</style>