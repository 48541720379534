<template>
    <v-card>
        <v-toolbar class="ahrefsBg" dense dark elevation="0">
            <v-toolbar-title>
                <img
                src="/img/ahrefs-logo.svg"
                height="16px"
                style="margin:-2px 0"
                alt="ahrefs logo"
                /><span>&nbsp;|&nbsp;Backlink data for your website</span>
            </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="text--primary flex-grow-1 pt-4" style="font-size:15px;line-height: 1.6">
            <p class="font-weight-bold">With Kaboozt, utilize Ahrefs backlink data to analyze which websites have linked to your domain and which have not.</p>
            <ol style="line-height: 1.8">
                <li>In the drop-down below click
                    <v-btn x-small color="primary" dark>
                        <v-icon>mdi-plus</v-icon>
                        Add domain
                    </v-btn>
                </li>
                <li>Type in your domain, eg.: kaboozt.com</li>
                <li>Hit
                    <v-btn color="primary" dark x-small>save</v-btn>
                </li>
                <li>Choose your domain in the drop-down</li>
            </ol>
            <v-sheet elevation="2" rounded class="mt-6">
                <user-domains-dropdown/>
            </v-sheet>
        </v-card-text>
        <div class="flex-grow-0 pa-4 d-flex">
            <v-spacer/>
            <v-btn :to="{path:'publishers'}" right color="warning">
                Head to the Market
                <v-icon right>mdi-chevron-right</v-icon>
            </v-btn>
        </div>
    </v-card>
</template>

<script>
    import UserDomainsDropdown from "@/components/sites/backlinkProfile/userDomainsDropdown";

    export default {
        name: "ahrefsDashboardWidget",
        components: {UserDomainsDropdown},
        data() {
            return {}
        }
    }
</script>

<style scoped>
    .ahrefsBg {
        background-color: #2e3c56;
    }

</style>