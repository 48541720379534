<template>
    <v-container>
        <v-row>
            <v-col class="col-12 col-xl-12  mx-auto">
                <v-row>
                    <v-col class="col-12 col-sm-6 col-lg-4">
                        <v-card
                            class="orange"
                            dark
                            @click="tabIdx = 0"
                        >
                            <v-card-title>
                                New orders
                            </v-card-title>
                            <v-card-text class="text-h1 text-right white--text">
                                {{ordersReceived}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col class="col-12 col-sm-6 col-lg-4">
                        <v-card
                            class="orange"
                            dark
                            @click="tabIdx = 1"
                        >
                            <v-card-title>
                                Orders being processed
                            </v-card-title>
                            <v-card-text class="text-h1 text-right white--text">
                                {{ordersProcessing}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col class="col-12 col-sm-6 col-lg-4">
                        <v-card
                            class="orange"
                            dark
                            @click="tabIdx = 2"
                        >
                            <v-card-title>
                                Published orders
                            </v-card-title>
                            <v-card-text class="text-h1 text-right white--text">
                                {{ordersCompleted}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-0">
                        <v-tabs-items v-model="tabIdx" class="transparent pa-0">
                            <v-tab-item eager>
                                <orders-received @ordersFetched="ordersReceived = $event"/>
                            </v-tab-item>
                            <v-tab-item eager>
                                <orders-processing @ordersFetched="ordersProcessing = $event"/>
                            </v-tab-item>
                            <v-tab-item eager>
                                <orders-completed @ordersFetched="ordersCompleted = $event"/>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {bus} from '@/main';
    import ReviewOrder from "../components/orders/reviewOrder";
    import CompleteOrder from "../components/orders/completeOrder";
    import OrdersReceived from "../components/orders/ordersReceived";
    import OrdersProcessing from "../components/orders/ordersProcessing";
    import OrdersCompleted from "../components/orders/ordersCompleted";

    export default {
        name: "Orders.vue",
        components: {OrdersCompleted, OrdersProcessing, OrdersReceived, CompleteOrder, ReviewOrder},
        data() {
            return {
                orders: [],
                ordersReceived: [],
                ordersProcessing: [],
                ordersCompleted: [],
                cvr: '',
                statusMessage: {
                    received: 'Received',
                    processing: 'Processing',
                    completed: 'Published',
                },
                tabIdx: 0,
            }
        },
        methods: {
            refreshOrders() {
                bus.$emit('orderUpdate', true)
            }
        },
        mounted() {
        },
        computed: {},
        watch: {}
    }
</script>

<style >

    td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow:ellipsis;
    }
</style>