<template>
    <div>
        <v-dialog
            v-model="dialog"
            transition="dialog-bottom-transition"
            scrollable
            width="960px"
            max-width="100%"
        >
            <v-sheet>
                <v-toolbar
                    color="primary"
                    dark
                    dense
                    tile
                >
                    <v-toolbar-title>
                        Order: {{currentOrder.domain}}
                    </v-toolbar-title>
                    <v-spacer/>
                    <v-btn
                        @click="dialog = false"
                        icon>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-sheet class="px-4 pt-8">
                    <v-row dense>
                        <v-col cols="12" xl="6" lg="6">
                            <v-row dense>
                                <v-col cols="12" class="py-0 mb-1">
                                    <span class="font-weight-bold">Domain</span>
                                    <br>
                                    <span>{{currentOrder.domain}}</span>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="12" class="py-0 mb-1">
                                    <span class="font-weight-bold">Price per article</span><br>
                                    <span>{{toLocaleCurrency(currentOrder.kbPrice)}}</span>
                                </v-col>
                            </v-row>
                            <!--v-row dense>
                                <v-col cols="12" class="py-0 mb-4">
                                    <span class="font-weight-bold">Publisher note (external data)</span><br>
                                    <span v-if="orderItem.note === ''">-</span>
                                    <span v-else>{{orderItem.note}}</span>
                                </v-col>
                            </v-row-->
                        </v-col>
                        <v-col cols="12" xl="6" lg="6" class="py-0 mb-1">
                            <span class="font-weight-bold">Required information</span><br>
                            <span class="text--primary">Each article order must contain a minimum of 1 URL or 1 article upload</span><br>
                            <br>
                            <span class="text--primary">Danish domains (.dk) require both image and logo upload, due to marketing laws.</span>
                        </v-col>
                    </v-row>
                    <v-divider class="my-4"/>
                    <v-expansion-panels v-model="panels">
                        <v-expansion-panel
                            v-for="(article,idx) in currentOrder.articles"
                            :key="article.articleId"
                            class="elevation-2 mt-0"
                        >
                            <v-expansion-panel-header
                                class="font-weight-bold d-flex align-center justify-end dark-expansion-header py-2"
                                color="primary" style="min-height:48px;">
                                <span class="white--text">
                                    {{currentOrder.domain}} | Article {{idx + 1}}
                                </span>
                                <v-spacer/>
                                <div v-if="validArticles.indexOf(idx) >= 0" class="flex-grow-0 mr-4">
                                    <v-icon color="white" class="green pa-1" style="border-radius: 50%;">mdi-check
                                    </v-icon>
                                </div>
                                <div v-else class="flex-grow-0 mr-4">
                                    <v-icon color="orange" class=" pa-1" style="border-radius: 50%;">mdi-alert</v-icon>
                                    <span class="white--text">Required info missing</span>
                                </div>
                                <v-btn icon dark @click="removeArticleFromCurrentOrder(idx)"
                                       :disabled="currentOrder.articles.length === 1" class="flex-grow-0 ma-0">
                                    <v-icon color="white">mdi-trash-can</v-icon>
                                </v-btn>

                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="pt-6">

                                <v-row dense>
                                    <v-col cols="6">
                                        <v-text-field v-model="article.anchor1" label="Anchor 1" placeholder="Anchor 1" outlined dense hide-details append-icon="mdi-anchor"/>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            v-model="article.url1"
                                            label="URL 1"
                                            placeholder="URL 1"
                                            outlined
                                            dense
                                            hide-details
                                            :error="article.url1.length !== 0 && !validUrl(article.url1)"
                                            append-icon="mdi-link"
                                        >
                                            <template v-slot:prepend-inner>
                                                <v-tooltip
                                                    v-if="article.url1.length !== 0 && !validUrl(article.url1)">
                                                    <template v-slot:activator="{on,attrs}">
                                                        <v-icon>mdi-alert</v-icon>
                                                    </template>
                                                    Invalid URL
                                                </v-tooltip>
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col cols="6">
                                        <v-text-field v-model="article.anchor2" label="Anchor 2" placeholder="Anchor 2" outlined dense hide-details append-icon="mdi-anchor"/>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            v-model="article.url2"
                                            label="URL 2"
                                            placeholder="URL 2"
                                            outlined
                                            dense
                                            hide-details
                                            :error="article.url2.length !== 0 && !validUrl(article.url2)"
                                            append-icon="mdi-link"
                                        >
                                            <template v-slot:prepend-inner>
                                                <v-tooltip
                                                    v-if="article.url2.length !== 0 && !validUrl(article.url2)">
                                                    <template v-slot:activator="{on,attrs}">
                                                        <v-icon>mdi-alert</v-icon>
                                                    </template>
                                                    Invalid URL
                                                </v-tooltip>
                                            </template>
                                        </v-text-field>

                                    </v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col cols="6">
                                        <v-text-field v-model="article.keyword" label="Focus keyword" placeholder="Focus keyword" outlined dense hide-details append-icon="mdi-bullseye"/>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            v-model="article.publishDate"
                                            outlined
                                            label="Desired publication date. No guarantees."
                                            dense
                                            hide-details
                                            readonly
                                        >
                                            <template v-slot:append>
                                                <date-time-picker
                                                    :dateOnly="true"
                                                    :min="new Date().daysFromNow(20,true)"
                                                    :preSelected="new Date().daysFromNow(20,true)"
                                                    @chosenDate="setDate(idx,$event)"
                                                    class="mt-1"
                                                />
                                            </template>
                                        </v-text-field>

                                    </v-col>

                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="article.userComment"
                                            :label="'Article comments ('+ article.userComment.length +'/ 2000)' "
                                            :error="article.userComment.length > 2000"
                                            placeholder="Article comments"
                                            outlined
                                            hide-details
                                            dense
                                            append-icon="mdi-comment"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col cols="6">

                                        <upload-file
                                            type="article"
                                            logo="mdi-text-box-plus-outline"
                                            label="Upload article"
                                            :identifier="article.articleId"
                                            :dialog-header="'' +
                                                 '<p><strong>Document name must be:</strong> Domain - article<br>' +
                                                 '<strong>Example:</strong> ' + domainName(currentOrder.domain) + ' - ' + 'Article name</p>' +
                                                 '<p><strong>Accepted file types:</strong> doc, docx </p>'"
                                            @uploaded="addAttachment(idx,$event,'file')"
                                            @removeUpload="removeAttachment(idx,$event,'file')"
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <unsplash-gallery
                                            @image-selected="addUnsplashAttachment(idx,$event,'image')"
                                            @removeUpload="removeUnsplashAttachment(idx,$event,'image')"
                                            :key="'us-'+article.articleId"
                                            :disabled="currentOrder.articles.find(x => x.articleId === article.articleId).image !== ''"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col cols="6">
                                        <upload-file
                                            type="articleLogo"
                                            label="Upload logo"
                                            :identifier="article.articleId"
                                            :dialog-header="'' +
                                                 '<p><strong>Logo name should be the same as company name</strong><br>' +
                                                 '<strong>Example:</strong>&nbsp;kaboozt-logo.jpg</p>' +
                                                 '<p><strong>Accepted file types:</strong> jpg, png, gif</p>'"
                                            @uploaded="addAttachment(idx,$event,'logo')"
                                            @removeUpload="removeAttachment(idx,$event,'logo')"
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <upload-file
                                            type="articleImage"
                                            label="Upload image"
                                            logo="mdi-image-plus-outline"
                                            :identifier="article.articleId"
                                            :dialog-header="'' +
                                                 '<p><strong>Image name must contain publication domain</strong><br>' +
                                                 '<strong>Example:</strong> ' + domainName(currentOrder.domain) + '-' + 'flowers.jpg</p>' +
                                                 '<p><strong>Accepted file types:</strong> jpg, png, gif</p>'"
                                            @uploaded="addAttachment(idx,$event,'image')"
                                            @removeUpload="removeAttachment(idx,$event,'image')"
                                            :key="'ul-'+article.articleId"
                                            :disabled="currentOrder.articles.find(x => x.articleId === article.articleId).image !== ''"
                                        />

                                    </v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col cols="12">
                                        <v-alert
                                            v-if="orderItem.domain.indexOf('.dk') >= 0 && validArticles.findIndex(x => x === idx) < 0"
                                            type="warning"
                                            dense
                                        >
                                            <span><strong>NB:</strong> Danish domains (.dk) require both image and logo upload, due to marketing laws.</span>
                                        </v-alert>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <div>
                        <v-btn
                            @click="addArticleToCurrentOrder()"
                            color="teal"
                            dark
                            outlined
                            width="100%"
                            class="my-4 mx-auto"
                        >
                            <v-icon left>
                                mdi-plus
                            </v-icon>
                            Add article to order
                        </v-btn>
                    </div>
                </v-sheet>
                <v-footer class="py-4 d-flex justify-end">
                    <v-alert
                        dense
                        type="info"
                        color="info"
                        text
                        class="mb-0 align-self-start"
                    >Order customization also available in <span class="font-weight-bold">cart</span>
                    </v-alert>
                    <v-spacer/>
                    <div style="font-size:16px;">
                        <span class="font-weight-bold">Total price:&nbsp;</span>
                        <span v-html="toLocaleCurrency(currentOrder.articles.length * currentOrder.kbPrice)"></span>
                    </div>
                    <v-btn
                        :loading="orderLoad"
                        color="primary"
                        class="ml-4"
                        :disabled="!orderIsValid"
                        @click="sendOrderToCart()"
                    >
                        <v-icon left small>mdi-cart</v-icon>
                        Add to cart
                    </v-btn>
                    <v-btn
                        color="red"
                        outlined
                        @click="dialog = false"
                        class="ml-4"
                    >
                        Cancel
                    </v-btn>
                </v-footer>
            </v-sheet>
        </v-dialog>

        <v-btn
            color="primary"
            dark
            x-small
            width="100%"
            @click="dialog = true"
        >
            <v-icon left x-small>mdi-cart</v-icon>
            Buy
        </v-btn>
    </div>

</template>

<script>
    import DateTimePicker from "../dateTimePicker/dateTimePicker";
    import UploadFile from "@/components/fileManagement/uploadFile";
    import CheckmarkAnimated from "@/components/UI/elements/checkmarkAnimated";
    import UnsplashGallery from "@/components/UI/gallery/unsplashGallery";

    Date.prototype.daysFromNow = function (days) {
        let fn = new Date();
        fn.setDate(fn.getDate() + days);
        let month = (fn.getMonth() + 1).toString().length === 1 ? '0' + (fn.getMonth() + 1) : (fn.getMonth() + 1)
        let day = fn.getDate().toString().length === 1 ? '0' + fn.getDate() : fn.getDate()
        return fn.getFullYear() + '-' + month + '-' + day
    }

    export default {
        name: "addToCartButton",
        props: ['orderItem'],
        components: {UnsplashGallery, CheckmarkAnimated, UploadFile, DateTimePicker},
        data() {
            return {
                dialog: false,
                orderLoad: false,
                currentOrder: {},
                headers: [
                    /*  {text: '', value: 'index', width: '20px', sortable: false},*/
                    {text: 'Preferred anchor value **', value: 'anchor'},
                    {text: 'URL for link', value: 'url'},
                    {text: 'Preferred publish date', value: 'publishDate'},
                    /* {text: '', value: 'remove'},*/
                ],
                orderId: '',
                panels: 0,
                validArticles: [],
                disableImageUpload: {},
            }
        },
        methods: {
            domainIdent(item) {
                return crypto.randomUUID()
            },
            addAttachment(articleIndex, file, type) {
                if (typeof file.data !== 'undefined') {
                    if (type === 'file') {
                        this.currentOrder.articles[articleIndex].isFile = true
                    }
                    this.currentOrder.articles[articleIndex][type] = file.data.location
                }
            },
            addUnsplashAttachment(articleIndex, file, type) {
                if (typeof file !== 'undefined') {
                    this.currentOrder.articles[articleIndex][type] = file.urls.regular
                    this.currentOrder.articles[articleIndex].optionalData = {
                        image: {
                            author: file.user.name,
                            link: file.user.links.html,
                            credit: 'Photo by <a href="' + file.user.links.html + '?utm_source=unsplash&utm_medium=referral" target="_blank">' + file.user.name + '</a> on <a href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral">Unsplash</a>'
                        }
                    }
                }
            },
            removeUnsplashAttachment(articleIndex) {
                this.currentOrder.articles[articleIndex].image = ''
                delete this.currentOrder.articles[articleIndex].optionalData.image
            },
            removeAttachment(articleIndex, file, type) {
                if (type === 'file') {
                    this.currentOrder.articles[articleIndex].isFile = false
                }
                this.currentOrder.articles[articleIndex][type] = ''
            },
            removeArticleFromCurrentOrder(articleId) {
                this.currentOrder.articles.length > 1 && this.currentOrder.articles.splice(articleId, 1)
            },
            addArticleToCurrentOrder() {
                this.currentOrder.articles.push({
                        articleId: crypto.randomUUID(),
                        identifier: this.orderItem.identifier,
                        domain: this.orderItem.domain,
                        price: this.orderItem.kbPrice,
                        kbPrice: this.orderItem.kbPrice,
                        salesPrice: this.orderItem.kbPrice,
                        name: '',
                        anchor1: '',
                        anchor2: '',
                        url1: '',
                        url2: '',
                        keyword: '',
                        file: '',
                        image: '',
                        logo: '',
                        userComment: '',
                        optionalData: {},
                        publishDate: new Date().daysFromNow(3, true),
                    }
                )
            },
            setArticleDate(articleId, newDate) {
                this.currentOrder.articles[articleId].publishDate = newDate
            },
            newOrder() {
                let newOrder = Object.assign({}, this.orderItem)
                newOrder.articles = []
                newOrder.orderId = crypto.randomUUID()
                this.currentOrder = newOrder
                this.addArticleToCurrentOrder()
            },
            sendOrderToCart() {
                this.orderLoad = true
                setTimeout(() => {
                    this.orderLoad = false
                    this.dialog = false
                    this.$store.dispatch('cartModule/addToCart', this.currentOrder)
                }, 125)
            },
            setDate(articleIndex, date) {
                this.currentOrder.articles[articleIndex].publishDate = date
            },
            domainName(url) {
                if (url !== '') {
                    return new URL('https://' + url).host
                }
                return ''
            },
            toLocaleCurrency(number) {
                return (number * this.$store.state.exchangeRate[this.$store.state.currencyCode]).toLocaleString(this.$store.state.locale, {
                    style: 'currency',
                    currency: this.$store.state.currencyCode
                })
            },
            validUrl(url) {
                // Regular expression pattern for URL validation
                var pattern = new RegExp(
                    "^(https?:\\/\\/)?"
                    + "(([a-zA-Z\\d-]+\\.)+[a-zA-Z]{2,})"
                    + "(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+\\/]*|\\/)*" // Modified to allow forward slashes in query parameters
                    + "(\\?[;&a-zA-Z\\d%_.~+=\\/-]*)?" // Modified to allow forward slashes in query parameters
                    + "(\\#[-a-zA-Z\\d_]*)?$"
                );

                // Test the pattern against the given URL
                return pattern.test(url.trim());
            },
        }
        ,
        created() {
            this.newOrder()
        }
        ,
        computed: {
            orderIsValid() {
                return (this.validArticles.length === this.currentOrder.articles.length)
            }
        }
        ,
        watch: {
            'currentOrder':
                {
                    handler() {
                        if (typeof this.currentOrder.articles === 'undefined') {
                            return;
                        }
                        this.currentOrder.articles.forEach((article, idx) => {
                            let hasFile = (article.file !== "")
                            let hasLogo = (article.logo !== "")
                            let hasImage = (article.image !== "")
                            let hasUrl = this.validUrl(article.url1)
                            let inValidArticles = (this.validArticles.findIndex(x => x === idx) >= 0)
                            if (hasFile || hasUrl) {
                                if (this.currentOrder.domain.indexOf('.dk') >= 0 && hasImage && hasLogo) {
                                    !inValidArticles && this.validArticles.push(idx)
                                } else {
                                    !inValidArticles && this.validArticles.push(idx)
                                }
                            } else {
                                let articleIndex = this.validArticles.findIndex(x => x === idx)
                                if (articleIndex >= 0) {
                                    this.validArticles.splice(articleIndex, 1)
                                }
                            }
                        })
                    }
                    ,
                    deep: true,
                    immediate: true
                }
        }
    }
</script>

<style>
    .dark-expansion-header > .v-expansion-panel-header__icon > i {
        color: #fff !important;
    }
</style>