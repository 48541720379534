<template>
    <div>
        <page-header :content-left="'Dashboard: '+$store.state.auth.user.name"/>
        <v-container fluid>
            <v-row>
                <v-col cols="12" xl="4" lg="4" md="6">
                    <bestsellers/>
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="6">
                    <new-domains-widget/>
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="6">
                    <ahrefs-dashboard-widget/>
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="6">
                    <user-orders/>
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="6">
                    <news-widget/>
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="6">
                    <support-widget/>
                </v-col>
            </v-row>
        </v-container>
    </div>

</template>

<script>
    import api from "@/services/api";
    import Bestsellers from "@/components/UI/widgets/bestsellerWidget";
    import UserOrders from "@/components/UI/widgets/userOrders";
    import NewsWidget from "@/components/UI/widgets/newsWidget";
    import DomainExport from "@/components/sites/export/domainExport";
    import NewDomainsWidget from "@/components/UI/widgets/newDomainsWidget";
    import AhrefsDashboardWidget from "@/components/UI/widgets/ahrefsDashboardWidget";
    import SupportWidget from "@/components/UI/widgets/supportWidget";
    import PageHeader from "@/components/UI/elements/pageHeader";

    export default {
        name: 'Home',
        components: {PageHeader, SupportWidget, AhrefsDashboardWidget, NewDomainsWidget, DomainExport, NewsWidget, UserOrders, Bestsellers},
        data() {
            return {
                quickPayMsg: '',
                invoices: [],
                orders: [],
                response: '',
            }
        },
        methods: {
            quickpayTest() {
                api.post('/quickpay/test')
                    .then(response => {
                        this.quickPayMsg = response.data
                        console.log(response.data)
                    })
            },
            mozAPIRequest() {
                api.get('/moz-api/' + 'kaboozt.com').then(response => {
                    console.log(response.data)
                })
            },
            wdTest() {
                api.post('/wd/order').then(response => {
                    console.log(response.data)
                    this.response = response.data
                })
            },
            ahrefsTest() {
                api.get('https://api.kaboozt.com/ahrefs/refdomains?domain=kaboozt.com').then(response => {
                    this.response = response.data
                })
            },
            updateFromAPI() {
                api.get('/wd/update-domains').then(response => {
                    this.response = response.data
                })
            }

        },
        mounted() {

        }
    }
</script>


<style>
    .v-sheet--offset {
        top: -24px;
        position: relative;
    }

    .terminal {
        background-color: #000000;
        background-image: radial-gradient(#11581E, #041607), url("https://media.giphy.com/media/oEI9uBYSzLpBK/giphy.gif");
        background-repeat: no-repeat;
        background-size: cover;
        padding: 25px;
        font-family: Consolas, monospace;
        font-size: 14px;
        background: #000;
        color: rgba(128, 255, 128, 0.8);
        text-shadow: 0 0 1ex rgba(51, 255, 51, 1), 0 0 2px rgba(255, 255, 255, 0.8);
    }
</style>