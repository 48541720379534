import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import auth from "../store/authModule";
import toasts from './toastModule'
import cartModule from "@/store/cartModule";
import backlinkModule from "./backlinkModule"


const userSettings = JSON.parse(localStorage.getItem('user'))
const userIsSet = !(userSettings == null || typeof userSettings == 'undefined')
let userLocale = {
    locale: userIsSet && typeof userSettings.settings.locale !== 'undefined' ? userSettings.settings.locale : 'en-us',
    currencyCode: userIsSet && typeof userSettings.settings.currencyCode !== 'undefined' ? userSettings.settings.currencyCode : 'DKK'
}
export default new Vuex.Store({
    state: {
        nav: {
            drawer: {
                show: false,
                mini: false,
            },
        },
        dataTables: {
            breakPoint: 0
        },
        pageState: 200,
        locale: userLocale.locale,
        currencyCode: userLocale.currencyCode,
        exchangeRate: {
            DKK: 1,
            USD: 0.1443,
            EUR: 0.1343,
        },

    },
    mutations: {},
    actions: {},
    modules: {
        auth,
        toasts,
        cartModule,
        backlinkModule
    },

    watch: {
        $route(to, from) {
            this.state.page = this.$router.currentRoute
        },
    },
})
