<template>
    <v-toolbar dense>
        <v-toolbar-title>{{pageName}}</v-toolbar-title>
        <v-spacer/>
        <v-toolbar-items>
            <user-domains-dropdown/>
        </v-toolbar-items>
    </v-toolbar>
</template>

<script>
    import UserDomainsDropdown from "@/components/sites/backlinkProfile/userDomainsDropdown";

    export default {
        name: "metaToolbar",
        components: {UserDomainsDropdown},
        data() {
            return {
                pageName: '',
            }
        },
        methods: {
            getPageName() {
                console.log(this.$router.currentRoute.name)
                this.pageName = this.$router.currentRoute.name
            }
        },
        mounted() {
            this.getPageName()
        },
        watch: {
            $route(to, from) {
                this.getPageName()
            }
        }
    }
</script>

<style scoped>

</style>