<template>
    <v-sheet elevation="2">
        <v-toolbar dense color="primary" dark elevation="0">
            <v-toolbar-title>
                My colleagues
            </v-toolbar-title>
        </v-toolbar>
        <v-list>
            <v-list-item
                v-for="contact in contacts"
                :key="'contact-'+contact.id"
                dense
            >
                <v-list-item-avatar>
                    <v-avatar color="secondary" size="32">
                        <span class="white--text">{{initials(contact.name)}}</span>
                    </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{contact.name}}</v-list-item-title>
                    <v-list-item-subtitle><a :href="'mailto:'+contact.mail">{{contact.mail}}</a></v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-sheet>
</template>

<script>
    import api from "@/services/api";

    export default {
        name: "companyContacts",
        props:['identifier'],
        data() {
            return {
                contacts: []
            }
        },
        methods: {
            getCompanyContacts() {
                api.get(`companies/${this.identifier}/contacts`).then(response => {
                    this.contacts = response.data
                })
            },
            initials(name) {
                let n = name
                return n.slice(0, 1) + n.split(' ')[n.split(' ').length - 1].slice(0, 1)
            },
        },
        mounted() {
        },
        watch:{
            'identifier':{
                handler(){
                    typeof this.identifier !== 'undefined' && this.getCompanyContacts()
                },
                immediate:true
            },

        }
    }
</script>

<style scoped>

</style>