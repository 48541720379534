/**
 * @deprecated: Moved to routerService.js for async route loading
 */
import {deprecate} from "vuetify/lib/util/console";
import Vue from 'vue'
import VueRouter from 'vue-router'
import api from "../services/api";
import store from '../store/index'
import goTo from 'vuetify/lib/services/goto'
import Home from "@/views/Home";
import Market from "@/views/Market";
import Cart from "@/views/Cart";
import Checkout from "@/views/Checkout";
import Orders from "@/views/Orders";
import Cookies from "@/views/Cookies";
import Terms from "@/views/Terms";
import Settings from "@/views/Settings";
import MyPage from "@/views/MyPage";
import Login from "@/views/Login";

Vue.use(VueRouter)

let routes = [
    {
        name: "Dashboard",
        path: "/",
        meta: {
            en: {
                title: 'Kaboozt | Linkbuilding made easy',
            },
            da: {
                title: 'Kaboozt | Nem linkbuilding',
            },
            icon: "mdi-format-list-bulleted",
            parent: 0,
            guarded: false,
            naviHide: false,
            menuTitle: 'Dashboard'
        },
        component: Home,
    },
    {
        path: '/cookies',
        name: 'Cookies',
        meta: {
            en: {
                title: 'Cookie policy | Kaboozt',
            },
            da: {
                title: 'Cookiepolitik | Kaboozt',
            },
            icon: 'mdi-cookie',
            naviHide: true,
            guarded: false,
            menuTitle: 'Cookie policy'
        },
        component: Cookies,
    },

    {
        path: '/terms',
        name: 'Terms',
        meta: {
            en: {
                title: 'Terms and condition | Kaboozt',
            },
            da: {
                title: 'Handelsbetingelser | Kaboozt',
            },
            icon: 'mdi-cookie',
            naviHide: true,
            guarded: false,
            menuTitle: 'Terms'
        },
        component: Terms,
    },
    {
        name: 'Login',
        path: '/login',
        meta: {
            en: {
                title: 'Log in to Kaboozt',
            },
            da: {
                title: 'Log ind på Kaboozt',
            },
            title: 'Login',
            icon: 'mdi-account',
            naviHide: true,
            guarded: false,
            menuTitle: ''
        },
        component: Login,
    }, {
        name: 'Auth',
        path: '/auth',
        meta: {
            en: {
                title: 'Auth | Kaboozt',
            },
            da: {
                title: 'Auth | Kaboozt',
            },
            title: 'User authorization',
            icon: 'mdi-account',
            naviHide: true,
            guarded: false,
            menuTitle: 'Auth'
        },
        component: () => import('@/views/Auth.vue'),
    },
    {
        name: 'error404',
        path: '*',
        meta: {
            en: {
                title: '404 | Page not found',
            },
            da: {
                title: '404 | Siden blev ikke fundet',
            },
            title: '404 page',
            icon: 'mdi-error',
            naviHide: true,
            guarded: false,
            menuTitle: '404'
        },
        component: () => import('@/views/errorPages/error404.vue'),
    }
    /*
   {
       name: "Market",
       path: "/market",
       meta: {
           en: {
               title: 'Kaboozt | Linkbuilding made easy',
           },
           da: {
               title: 'Kaboozt | Nem linkbuilding',
           },
           icon: "mdi-format-list-bulleted",
           parent: 0,
           guarded: true,
           naviHide: false,
           menuTitle: 'Market'
       },
       component: Market,

   },
   {
       path: '/cart',
       name: 'Cart',
       meta: {
           en: {
               title: 'Cart | Kaboozt',
           },
           da: {
               title: 'Kurv | Kaboozt',
           },
           icon: 'mdi-account',
           naviHide: true,
           guarded: true,
           menuTitle: 'Cart'
       },
       component: Cart,
   },
   {
       path: '/checkout',
       name: 'Checkout',
       meta: {
           en: {
               title: 'Checkout | Kaboozt',
           },
           da: {
               title: 'Checkout | Kaboozt',
           },
           icon: 'mdi-credit-card',
           naviHide: true,
           guarded: false,
           menuTitle: 'Checkout'
       },
       component: Checkout,
   },
   {
       path: '/orders',
       name: 'Orders',
       meta: {
           en: {
               title: 'Orders | Kaboozt',
           },
           da: {
               title: 'Bestillinger | Kaboozt',
           },
           title: 'Orders',
           icon: 'mdi-account',
           naviHide: false,
           guarded: true,
           menuTitle: 'Orders'
       },
       component: Orders,
   },
   {
       path: '/settings',
       name: 'Settings',
       meta: {
           en: {
               title: 'Settings | Kaboozt',
           },
           da: {
               title: 'Indstillinger | Kaboozt',
           },
           icon: 'mdi-cogs',
           naviHide: true,
           guarded: true,
           menuTitle: 'Settings'
       },
       component: Settings,
   },
   {
       path: '/profile',
       name: 'MyPage',
       meta: {
           en: {
               title: 'My page | Kaboozt',
           },
           da: {
               title: 'Min side | Kaboozt',
           },
           icon: 'mdi-account',
           naviHide: false,
           guarded: true,
           menuTitle: 'Profile'
       },
       component: MyPage,
   },
   {
       path: '/admin',
       name: 'Admin',
       meta: {
           en: {
               title: 'Admin area | Kaboozt',
           },
           da: {
               title: 'Adminside | Kaboozt',
           },
           title: 'Admin area',
           icon: 'mdi-cogs',
           naviHide: false,
           guarded: true,
           menuTitle: 'Admin'
       },
       component: () => import('@/views/Admin.vue'),
   },

   {
       path: '/checkout/success',
       name: 'CheckoutSuccess',
       meta: {
           en: {
               title: 'Checkout | Kaboozt',
           },
           da: {
               title: 'Checkout | Kaboozt',
           },
           icon: 'mdi-credit-card',
           naviHide: false,
           guarded: false,
           menuTitle:''
       },
       component: () => import('@/views/CheckoutSuccess.vue'),
   }, {
       path: '/checkout/failed',
       name: 'CheckoutFailed',
       meta: {
           en: {title: 'Checkout | Kaboozt',},
           da: {title: 'Checkout | Kaboozt',},
           icon: 'mdi-credit-card',
           naviHide: false,
           guarded: false,
           menuTitle:''
       },
       component: () => import('@/views/CheckoutFailed.vue'),
   },

    */

]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

async function isAuthenticated(to, from) {
    return await new Promise((resolve, reject) => {
        api.get('routes/' + to.name)
            .then(response => {
                switch (response.data.message) {
                    case 'routes/authorized':
                        resolve(true)
                        break;
                    default:
                        reject(response.data.message)
                        break;
                }
            })
            .catch(err => {
                store.dispatch('auth/logout')
                resolve(false)
            })
    })
}

router.beforeResolve(async (to, from, next) => {
    await isAuthenticated(to, from)
        .then(authenticated => {
            if (!authenticated && (to.path == '/login' || to.path == '/auth')) {
                next();
            } else if (!authenticated && to.path !== '/login') {
                router.push('/login')
            } else if (authenticated && to.path === '/login') {
                router.push('/')
            } else if (authenticated) {
                next()
            }
        })
        .catch(notAuthenticated => {

        })
})

export default router

