<template>
    <v-container class="py-0 px-8 fill-height" fluid>
        <v-sheet
            class="mx-auto fill-height"
            width="100%"
            tile
        >
            <div id="paymentCheckout" class="fill-height">
                <div id="orderInformation" class="pa-12">
                    <h1 class="mb-12" style="line-height: 1">Order</h1>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                            <tr class="text-left">
                                <th
                                    v-for="(header,idx) in checkoutHeaders"
                                    :key="'header-'+idx"
                                >{{header.text}}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in $store.state.cartModule.orders">
                                <td>{{ item.domain }}</td>
                                <td>{{ item.articles.length || 1 }}</td>
                                <td>{{ item.price }}</td>
                                <td>{{ item.articles.length * item.price }}</td>
                            </tr>
                            </tbody>
                            <tfoot>
                            <tr>
                                <td><span class="grey--text">Vat</span>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                color="grey"
                                                v-bind="attrs"
                                                v-on="on"
                                                x-small
                                            >mdi-information-variant
                                            </v-icon>
                                        </template>
                                        <span>Danish VAT 25%</span>
                                    </v-tooltip>
                                </td>
                                <td></td>
                                <td></td>
                                <td><span class="grey--text">{{(cartTotal * 0.2).toLocaleString('da-DK')}} DKK</span>
                                </td>
                            </tr>
                            <tr>
                                <td>Total</td>
                                <td></td>
                                <td></td>
                                <td>
                                    <span class="font-weight-bold text-decoration-underline">{{cartTotal.toLocaleString('da-DK')}} DKK</span>
                                </td>
                            </tr>
                            </tfoot>
                        </template>
                    </v-simple-table>
                </div>
                <div id="paymentInformation" class="pa-12">
                    <h2 class="mb-12" style="line-height: 1">Pay with Stripe</h2>
                    <v-btn
                        dark
                        width="100%"
                        color="blue"
                        @click="pay"
                        class="mt-12"
                    >
                        <a :href="paymentUrl" target="_blank" class="white--text text-decoration-none">Go to Stripe Payment</a>
                        <v-icon>mdi-exit-to-app</v-icon>
                    </v-btn>
                </div>
            </div>
        </v-sheet>
    </v-container>
</template>

<script>
    import api from "@/services/api";
    import stripeApi from "@/services/stripeApi";

    export default {
        name: "Checkout",
        data() {
            return {
                checkoutHeaders: [
                    {text: 'Domain', value: 'domain', width: '70%'},
                    {text: 'Articles', value: 'amount', align: 'center', width: 108},
                    {text: 'Unit price', value: 'price', align: 'right', width: 120},
                    {text: 'Sub total', value: 'subtotal', align: 'right', width: 140},
                ],
                paymentIntentGenerated: false,
                loading: false,
                elementsOptions: {
                    appearance: {}, // appearance options
                    clientSecret: '',
                    locale: this.$store.state.locale
                },
                confirmParams: {
                    return_url: 'http://localhost:8080/checkout/success', // success url
                },
                pk: 'pk_test_51MTk0WE8TFmArBhDztV971TGUFGKe32Rz2CAGUkT9SJGkMmFmFU61ulc0cVNMBJGKTN4lxfTqSrNfySFpTncDvLb00bzAIfqAE',
                payment_method_types: ['card'],
                createdListings: [],
                alreadyListed: [],
                lineItems: [],
                automatic_tax: {enabled: true},
                mode: 'payment',
                successURL: 'checkout/success',
                cancelURL: 'checkout/failed',
                paymentUrl: '',
            };
        },
        methods: {
            async generatePaymentIntent() {
                const qs = require('qs')
                await new Promise((resolve, reject) => {
                    stripeApi.post('/payment_intents?' + qs.stringify({
                        amount: this.cartTotal * 100,
                        currency: 'dkk',
                        payment_method_types: ['card']
                    }))
                        .then(response => {
                            resolve(response.data)
                            this.elementsOptions.clientSecret = response.data.client_secret;
                            this.paymentIntentGenerated = true

                        })
                })
            },
            async createProductListing() {
                const orders = this.$store.state.cartModule.orders
                const qs = require('qs')
                let sequence = Promise.resolve()
                await orders.forEach((order, index) => {
                    sequence = sequence.then(e => {
                        this.createdListings.push(stripeApi.post('products?' + qs.stringify({
                            id: order.id + '_' + this.sanitizeDomainForID(order.domain),
                            name: order.domain,
                            default_price_data: {
                                currency: 'dkk',
                                unit_amount: order.price * 100
                            },
                            tax_code: 'txcd_10000000',
                        }))
                            .catch(err => {
                                this.alreadyListed.push(order.id + '_' + this.sanitizeDomainForID(order.domain))
                            }))

                    })
                        .finally(() => {
                        })

                })
                return true;
            },
            pay() {
                this.$refs.paymentRef.submit();
            },
            generateId(order) {
                return order.id + '_' + order.domain.replace(/[\W_]+/g, '_')
            },
            generatePaymentLink() {
                let line_items = []
                let orders = JSON.parse(JSON.stringify(this.$store.state.cartModule.orders))
                orders.forEach((order, id) => {
                    order.stripeId = this.generateId(order)
                })
                return new Promise((resolve, reject) => {
                    api.post('/stripe/products', orders)
                        .then(paymentLink => {
                            this.paymentUrl = paymentLink.data.url
                            console.log(paymentLink.data)
                        })
                })

            },
            fetchPaymentLink(line_items) {
                console.log(line_items)
                let qs = require('qs')
                /*
                stripeApi.post('payment_links?' + qs.stringify({
                    line_items: this.lineItems
                }))

                 */
            }
        },
        mounted() {
            //this.generatePaymentIntent()
            this.generatePaymentLink()
                .then(paymentLink => {
                })
                .finally(line_items => {
                    this.fetchPaymentLink(line_items)

                })


            //this.createProductListing()
            // TODO: FINISH THE SHITnpm
        },
        computed: {
            cartTotal: function () {
                let total = 0
                this.$store.state.cartModule.orders.forEach((obj, i) => {
                    total = total + (obj.price * obj.articles.length)
                })
                return total * 1.25
            },
        },
    }

</script>

<style scoped>
    h1, h2 {
        font-size: 32px !important;
    }

    #paymentCheckout {
        display: flex !important;
        justify-content: center;
        flex-wrap: wrap;
        align-content: center;
    }

    #orderInformation, #paymentInformation {
        width: 640px;
        min-width: 360px;
        max-width: 100%;
    }

    @media screen and (min-width: 768px) {
        #orderInformation {
        }
    }
</style>