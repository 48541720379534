<template>
    <v-dialog max-width="980px" v-model="galleryDialog" v-if="!imageChosen" :scrollable="false">
        <v-sheet max-height="90vh">
            <v-toolbar elevation="0" color="primary" dark>
                <v-toolbar-title>Image search</v-toolbar-title>
                <v-spacer/>
                <v-toolbar-items class="d-flex align-items-center align-content-lg-space-between">
                    <div>
                        <v-btn icon @click="galleryDialog = !galleryDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </div>
                </v-toolbar-items>
            </v-toolbar>
            <div class="px-4 pb-2 pt-4 d-flex">
                <v-text-field
                    outlined
                    dense
                    label="Search"
                    placeholder="Search"
                    hide-details
                    append-icon="mdi-magnify"
                    @click:append="getImages"
                    @keydown.enter="getImages"
                    v-model="options.searchTerm"
                    @load="focus()"
                    style="flex-basis: 320px;flex-grow: 1;"
                    :loading="loading"
                >
                </v-text-field>
                <v-select
                    :items="displayOptions"
                    label="Images per page"
                    v-model="options.itemsPerPage"
                    @input="getImages"
                    outlined
                    dense
                    hide-details
                    class="ml-2"
                    style="flex-basis: 120px;flex-grow: 0;"
                    :loading="loading"
                />
            </div>
            <div class="px-2 d-flex child-flex flex-wrap text-center overflow-hidden overflow-y-auto" style="min-height: 440px;max-height: calc(90vh - 64px - 64px - 37px - 48px - 35px) !important;">
                <div v-if="images.length === 0" class="d-flex align-items-center justify-center">
                    <div class="py-4 px-8 blue-grey lighten-5 rounded">Search Unsplash images</div>
                </div>
                <div
                    v-if="images.length >= 1"
                    v-for="image in images"
                    :key="image.id"
                    class="d-flex flex-column text-center pa-2 imageSelectOption"
                    style="flex-basis:20%;min-width:100px; height: 220px"
                    :class="image == selectedImage ? 'selectedImage' : ''"
                >
                    <v-tooltip bottom class="pa-5">
                        <template v-slot:activator="{ on }">
                            <v-img
                                :src="image.urls.thumb"
                                :lazy-src="image.urls.thumb"
                                class="grey lighten-2"
                                aspect-ratio="1"
                                :alt="image.alt_description"
                                style="cursor:pointer"
                                @click="selectImage(image)"
                                v-on="on"
                            >
                                <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                        </template>
                        <div class="text-center">
                            <img :src="image.urls.thumb" :alt="image.alt_description" style="width: 200px !important;">
                            <br>
                            {{image.alt_description}}
                        </div>
                    </v-tooltip>
                    <div class="caption grey--text">
                        <a :href="image.links.html" target="_blank"></a>Photo by <a :href="image.user.links.html+'?utm_source=Kaboozt&utm_medium=referral'" target="_blank" class=" grey--text">{{image.user.name}}</a>
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center justify-center pa-4">
                <v-btn x-small icon color="grey" class="grey lighten-3" @click="prevPage()" :loading="loading">
                    <v-icon small>mdi-chevron-left</v-icon>
                </v-btn>
                <span class="px-2">{{options.page}}</span>
                <v-btn x-small icon color="grey" class="grey lighten-3" @click="nextPage()" :loading="loading">
                    <v-icon small>mdi-chevron-right</v-icon>
                </v-btn>
            </div>
            <div class="text-center grey--text caption">Images provided by <a href="https://unsplash.com/?utm_source=Kaboozt&utm_medium=referral" class="grey--text">Unsplash</a></div>
            <v-footer>
                <span class="">Selected: <span v-if="selectedImage !== null" class="font-weight-bold">{{selectedImage.alt_description}}</span> <span v-else class="font-weight-bold">None</span></span>
                <v-spacer/>
                <v-btn @click="choseImage()" color="primary" class="mr-2 px-8" :disabled="selectedImage === null">Select</v-btn>
                <v-btn @click="cancelSelection" color="red" outlined class="px-8">Cancel</v-btn>
            </v-footer>
        </v-sheet>
        <template v-slot:activator="{on,attrs}">
            <v-btn
                v-model="galleryDialog"
                v-on="on"
                color="primary"
                class="ma-auto"
                width="100%"
                :disabled="buttonDisabled"
            >
                <v-icon left>mdi-image-plus-outline</v-icon>
                Chose image from gallery
            </v-btn>
        </template>
    </v-dialog>
    <div v-else class="d-flex justify-space-around">
        <v-menu
            offset-y
            style="top: 38px;"
            min-width="300px"
            :close-on-content-click="false"
        >
            <template v-slot:activator="{on}">
                <v-btn v-on="on" icon>
                    <v-icon>mdi-paperclip</v-icon>
                </v-btn>
            </template>
            <v-sheet>
                <v-btn text width="100%" @click="resetGallery()">
                    <checkmark-animated :fail="true" :table-cell="true" class="ma-auto mx-1"/>
                    <span class="ml-2">Remove {{selectedImage.urls.regular.split('/').pop().split('?').shift()}}</span>
                </v-btn>
            </v-sheet>
        </v-menu>
        <v-tooltip bottom>
            <template v-slot:activator="{on}">
                <div v-on="on" class="text-no-wrap" style="text-overflow: ellipsis;width: 100%;overflow: hidden;padding: 6px 0;">{{selectedImage.urls.regular.split('/').pop().split('?').shift()}}</div>
            </template>
            {{selectedImage.urls.regular.split('/').pop().split('?').shift()}}
        </v-tooltip>
    </div>
</template>

<script>
    import CheckmarkAnimated from "@/components/UI/elements/checkmarkAnimated";
    import api from "@/services/api";

    export default {
        name: "unsplashGallery",
        components: {CheckmarkAnimated},
        props: ['imagePreChosen', 'disabled'],
        data() {
            return {

                galleryDialog: false,
                options: {
                    searchTerm: 'Animals',
                    page: 1,
                    itemsPerPage: 10,
                },
                displayOptions: [
                    {text: 10, value: 10},
                    {text: 15, value: 15},
                    {text: 30, value: 30},
                ],
                images: [],
                selectedImage: null,
                loading: false,
                imageChosen: false,
            }
        },
        methods: {
            getImages() {
                this.loading = true
                const searchTerm = encodeURIComponent(this.searchTerm);
                api.get(`/image-gallery/search?page=${this.options.page}&per_page=${this.options.itemsPerPage}&query=${this.options.searchTerm}`).then(response => {
                    this.images = response.data.results
                })
                    .catch(err => console.error(err));
                this.loading = false
            },
            sendDownloadRequest() {
                const encodedRequestUrl = encodeURIComponent(this.selectedImage.links.download_location)
                api.post('/image-gallery/download-request', {url: encodedRequestUrl}).then(response => {
                    console.log()
                })
            },
            selectImage(image) {
                this.selectedImage = image
            },
            choseImage() {
                this.$emit('image-selected', this.selectedImage)
                this.galleryDialog = false
                this.imageChosen = true
                this.sendDownloadRequest()
            },
            cancelSelection() {
                this.selectedImage = null
                this.galleryDialog = false
                this.imageChosen = false
            },
            resetGallery() {
                this.$emit('removeUpload', {data: {location: this.selectedImage.urls.regular}})
                this.selectedImage = null
                this.galleryDialog = false
                this.imageChosen = false
            },
            nextPage() {
                this.options.page++
                this.getImages()
            },
            prevPage() {
                if (this.options.page >= 2) {
                    --this.options.page
                    this.getImages()
                }
                return
            }
        },
        computed: {
            buttonDisabled() {
                return typeof this.$props.disabled !== 'undefined' ? this.$props.disabled : false
            }
        },
        mounted() {
            if (typeof this.imagePreChosen === 'string' && this.imagePreChosen.trim() !== "") {
                this.imageChosen = true
                this.selectedImage = {
                    urls: {
                        regular: this.imagePreChosen
                    }
                }
            }
            this.getImages()
        }
    }
</script>

<style scoped>
    .imageSelectOption {
        border: 1px solid rgba(0, 0, 0, 0);
        transition: 125ms ease;
        flex-grow: 0;
    }

    .theme--light .selectedImage {
        border: 1px solid rgba(0, 0, 0, 0.12);
        background-color: rgba(0, 0, 0, 0.05);
    }

    .theme--dark .selectedImage {
        border: 1px solid rgba(255, 255, 255, 0.12);
        background-color: rgba(255, 255, 255, 0.05);
    }

    .v-tooltip__content {
        padding: 8px;
    }
</style>