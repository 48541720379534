<template>
    <v-dialog
        v-model="dialog"
        max-width="960px"
    >
        <v-sheet>
            <v-toolbar color="primary" dark elevation="0">
                <v-toolbar-title>Edit domains</v-toolbar-title>
                <v-spacer/>
                <v-btn @click="dialog = !dialog" icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <div class="pa-4">
                <p>Expand rows to edit publisher pricing, language, country and notes</p>
                <p><strong>NB:</strong> Prices must be entered in DKK</p>
                <v-row>
                    <v-col cols="12">
                        <v-data-table
                            :items="entries"
                            :headers="_headers"
                            dense
                            single-expand
                            show-expand
                            hide-default-footer
                        >
                            <template v-slot:item.deleted="{item}">
                                <v-icon v-if="item.deleted == 1" color="red">mdi-close</v-icon>
                                <v-icon v-else color="green">mdi-check</v-icon>
                            </template>
                            <template v-slot:item.kbPrice="{item}">
                                {{item.kbPrice}}
                                <v-chip :color="item.confirmArticle == 0 ? 'red' : 'green'" x-small>{{item.confirmArticle == 0 ? 'U' : 'C'}}</v-chip>
                            </template>
                            <template v-slot:expanded-item="{item}">
                                <td :colspan="_headers.length + 1">
                                    <v-sheet max-width="1112px" class="pa-4 transparent">
                                        <p><span class="text-h6">Publisher: {{item.publisher}}</span><span class="caption"> (id: {{item.id}})</span></p>
                                        <price-history :identifier="item.identifier" height="200"/>
                                        <v-row dense>
                                            <v-col cols="12">
                                                <v-row>
                                                    <v-col cols="6" xl="6" lg="6">
                                                        <v-text-field dense hide-details v-model="item.domain" label="domain" readonly/>
                                                    </v-col>
                                                    <v-col cols="6" xl="6" lg="6">
                                                        <v-text-field outlined dense hide-details v-model="item.note" @input="updateValue($event,'note',item)" label="note"/>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-row>
                                                    <v-col cols="6">
                                                        <v-text-field dense hide-details v-model="item.kbPrice" @input="updateValue($event,'kbPrice',item)" label="KB PRICE (no discount)"/>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-text-field dense hide-details v-model="item.purchasePrice" :readonly="noEdit(item)" :disabled="noEdit(item)" @input="updateValue($event,'purchasePrice',item)" label="Purchase price"/>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-row>
                                                    <v-col cols="6">
                                                        <v-text-field dense hide-details v-model="item.cbdPrice" @input="updateValue($event,'cbdPrice',item)" label="cbdPrice"/>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-text-field dense hide-details v-model="item.cbdPurchasePrice" :readonly="noEdit(item)" :disabled="noEdit(item)" @input="updateValue($event,'cbdPurchasePrice',item)" label="cbdPurchasePrice"/>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-row>
                                                    <v-col cols="6">
                                                        <v-text-field dense hide-details v-model="item.loanPrice" @input="updateValue($event,'loanPrice',item)" label="loanPrice"/>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-text-field dense hide-details v-model="item.loanPurchasePrice" :readonly="noEdit(item)" :disabled="noEdit(item)" @input="updateValue($event,'loanPurchasePrice',item)" label="loanPurchasePrice"/>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-row>
                                                    <v-col cols="6">
                                                        <v-text-field dense hide-details v-model="item.casinoPrice" @input="updateValue($event,'casinoPrice',item)" label="casinoPrice"/>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-text-field dense hide-details v-model="item.casinoPurchasePrice" :readonly="noEdit(item)" :disabled="noEdit(item)" @input="updateValue($event,'casinoPurchasePrice',item)" label="casinoPurchasePrice"/>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-row>
                                                    <v-col cols="6">
                                                        <v-text-field dense hide-details v-model="item.cryptoPrice" @input="updateValue($event,'cryptoPrice',item)" label="cryptoPrice"/>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-text-field dense hide-details v-model="item.cryptoPurchasePrice" :readonly="noEdit(item)" :disabled="noEdit(item)" @input="updateValue($event,'cryptoPurchasePrice',item)" label="cryptoPurchasePrice"/>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-row>
                                                    <v-col cols="6">
                                                        <v-text-field dense hide-details v-model="item.eroticPrice" @input="updateValue($event,'eroticPrice',item)" label="eroticPrice"/>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-text-field dense hide-details v-model="item.eroticPurchasePrice" :readonly="noEdit(item)" :disabled="noEdit(item)" @input="updateValue($event,'eroticPurchasePrice',item)" label="eroticPurchasePrice"/>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-row>
                                                    <v-col cols="6">
                                                        <v-text-field dense hide-details v-model="item.datingPrice" @input="updateValue($event,'datingPrice',item)" label="datingPrice"/>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-text-field dense hide-details v-model="item.datingPurchasePrice" :readonly="noEdit(item)" :disabled="noEdit(item)" @input="updateValue($event,'datingPurchasePrice',item)" label="datingPurchasePrice"/>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-row>
                                                    <v-col cols="6" xl="6" lg="6">
                                                        <v-select :items="countries" outlined dense hide-details item-text="name" item-value="name" v-model="item.country" label="Country"/>
                                                    </v-col>
                                                    <v-col cols="6" xl="6" lg="6">
                                                        <v-select :items="countries" outlined dense hide-details item-text="lang" item-value="lang" v-model="item.lang" label="Language"/>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-footer>
                                            <v-spacer/>
                                            <v-btn @click="savechanges(item)" color="primary" dark>Save changes</v-btn>
                                            <v-btn @click="cancelChanges(item)" color="red" outlined class="ml-2">Cancel</v-btn>
                                        </v-footer>
                                    </v-sheet>
                                </td>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </div>

        </v-sheet>

        <v-footer>
            <v-spacer/>
            <v-btn @click="dialog = !dialog" color="red" outlined class="ml-2">close</v-btn>
        </v-footer>
        <template v-slot:activator="{on, attrs}">
            <v-btn
                color="green"
                v-on="on"
                x-small
                dark
                style="width:100%"
                @click="openDialog"
            >
                <v-icon left x-small>mdi-pencil</v-icon>
                Edit
            </v-btn>
        </template>
    </v-dialog>
</template>

<script>
    import api from "@/services/api";
    import PriceHistory from "@/components/sites/priceHistory";

    export default {
        name: "siteEditor",
        components: {PriceHistory},
        props: ['selected', 'countries'],
        data() {
            return {
                entries: [],
                item: {},
                selectedRow: {},
                loading: false,
                updated: {},
                response: '',
                dialog: false,
                editRowDialog: false,
                values: [
                    "domain",
                    "note",
                    "kbPrice",
                    "fullPrice",
                    "purchasePrice",
                    "country",
                    "categories",
                    "cbdAllowed",
                    "loanAllowed",
                    "casinoAllowed",
                    "cryptoAllowed",
                    "eroticAllowed",
                    "datingAllowed",
                    "cbdPrice",
                    "loanPrice",
                    "casinoPrice",
                    "cryptoPrice",
                    "eroticPrice",
                    "datingPrice",
                ]
            }
        },
        methods: {
            closeEditRowDialog() {
                this.editRowDialog = false
                this.updated = {}
                this.selectedRow = {}
            },
            openDialog() {
                this.getEntries()
            },
            closeDialog() {
                this.dialog = false
                this.updated = {}
                this.selectedRow = {}
            },
            savechanges(item) {
                let updateIndex = this.updated.findIndex(x => x.id === item.id)
                api.put('/sites/' + item.id, this.updated[updateIndex]).then(response => {
                    switch (response.data.message) {
                        case 'sites/site-updated':
                            this.$store.dispatch('toasts/toastSuccess', 'Site updated succesfully')
                            this.getEntries()
                            break;
                        case 'sites/unauthorized':
                            this.$store.dispatch('toasts/toastError', 'You are not authorized to update site data')
                            break;
                        case 'sites/no-input':
                            this.$store.dispatch('toasts/toastWarning', 'No changes made')
                            break;
                        case 'sites/site-update-failed':
                            this.$store.dispatch('toasts/toastWarning', 'You messed something up!')
                            break;
                    }
                })
            },
            updateValue(evt, field, item) {
                let updateIndex = this.updated.findIndex(x => x.id === item.id)
                this.updated[updateIndex][field] = evt

            },
            getEntries() {
                this.loading = true
                api.get('domain-manager/' + this.selected.id).then(response => {
                    this.entries = response.data
                    this.loading = false
                    this.updated = response.data.map(item => {
                        return {id: item.id}
                    })
                })
            },
            editRow(item) {
                this.selectedRow = item
                this.editRowDialog = true
            },
            noEdit(item) {
                return item.identifier.indexOf('kb') < 0
            },
            cancelChanges(item) {
                let updateIndex = this.updated.findIndex(x => x.id === item.id)
                this.updated[updateIndex] = {id: item.id}
            }
        },
        computed: {
            notOurs() {
                return typeof this.selectedRow.identifier !== 'undefined' && this.selectedRow.identifier.indexOf('kb') < 0
            },
            _headers() {
                return [
                    {text: "", value: "deleted", width: 40},
                    {text: "Publisher", value: "publisher", width: 90, fixed: true},
                    {text: "domain", value: "domain", fixed: true},
                    {text: "Sales price", value: "kbPrice", width: 150},
                    {text: "Purchase price", value: "purchasePrice", width: 150},
                    {text: "Country", value: "country", width: 150},
                    /*
                    {text: "note", value: "note"},
                    {text: "categories", value: "categories"},
                    {text: "cbdAllowed", value: "cbdAllowed"},
                    {text: "cbdPrice", value: "cbdPrice", width: 150},
                    {text: "loanAllowed", value: "loanAllowed"},
                    {text: "loanPrice", value: "loanPrice", width: 150},
                    {text: "casinoAllowed", value: "casinoAllowed"},
                    {text: "casinoPrice", value: "casinoPrice", width: 150},
                    {text: "cryptoAllowed", value: "cryptoAllowed"},
                    {text: "cryptoPrice", value: "cryptoPrice", width: 150},
                    {text: "eroticAllowed", value: "eroticAllowed"},
                    {text: "eroticPrice", value: "eroticPrice", width: 150},
                    {text: "datingAllowed", value: "datingAllowed"},
                    {text: "datingPrice", value: "datingPrice", width: 150},
                    */
                ]
            }
        },
        watch: {
            'dialog': {
                handler() {
                    this.dialog && this.getEntries()
                }
            }
        }
    }
</script>

<style scoped>
    .table--input [type="text"] {
        padding: 0 !important;
    }

</style>