<template>
    <v-dialog max-width="1140" v-model="reviewDialog">
        <v-sheet>
            <v-toolbar dense elevation="0" color="primary" dark>
                <v-toolbar-title>Order for: {{order.userName}}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <p style="font-size: 16px; font-weight:bolder" class="py-4">
                    Article on: {{order.domain}}
                    <v-btn icon x-small @click="newTab(article.domain)">
                        <v-icon x-small>mdi-open-in-new</v-icon>
                    </v-btn>
                </p>
                <v-list
                    color="transparent"
                    style="border:3px solid rgba(255,255,255,0.55) !important"
                    class="mb-4"
                >
                    <v-list-item>
                        <v-list-item-content>
                            <v-form
                                lazy-validation
                                ref="entryForm"
                                @submit.prevent="completeOrder"
                            >
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            v-model="order.anchor"
                                            label="Anchor"
                                            dense
                                            required
                                            validate-on-blur
                                            :rules="validation.anchorRules"
                                        />
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            v-model="order.url"
                                            label="URL"
                                            dense
                                            required
                                            validate-on-blur
                                            :rules="validation.urlRules"
                                        />
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            v-model="order.publishDate"
                                            label="Publish date"
                                            dense
                                            required
                                        >
                                            <template v-slot:prepend-inner>
                                                <date-time-picker
                                                    :dateOnly="true"
                                                    :preSelected="order.publishDate"
                                                    @chosenDate="order.publishDate = $event"
                                                />
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col>
                                        <div v-if="order.file !== '' && order.file !== null" class="d-flex justify-space-around" style="width:100%; ">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{on,attr}">
                                                    <v-btn @click="newTab(order.file)" text small>
                                                        <v-icon>mdi-download</v-icon>
                                                        <span v-on="on">{{order.file.split('/').pop()}}</span>
                                                    </v-btn>
                                                </template>

                                                {{order.file}}
                                            </v-tooltip>
                                        </div>
                                        <div v-else class="d-flex justify-space-around" style="width:100%;">
                                            <v-btn disabled icon x-small>
                                                <v-icon>mdi-disabled</v-icon>
                                            </v-btn>
                                            <span>No upload</span>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <p>An e-email will be sent to the client with this information:</p>
                <v-divider/>
                <v-text-field
                    v-model="confirmComment"
                    outlined
                    label="Comment for the client"
                />
                <v-text-field
                    :rules="validation.urlRules"
                    label="Article URL *"
                    prepend-inner-icon="mdi-web"
                    outlined
                    color="green"
                    v-model="articleUrl"
                    :append-icon="articleURLInserted ? 'mdi-check' : 'mdi-cancel'"
                    ></v-text-field>
            </v-card-text>
            <v-card-actions class="pl-6">
                <v-checkbox
                    label="All information is correct"
                    v-model="infoIsCorrect"
                />
                <v-spacer/>
                <v-btn
                    @click="completeOrder"
                    :loading="confirmationLoading"
                    :disabled="!articleURLInserted || confirmationLoading || !infoIsCorrect"
                >Close order (article published)
                </v-btn>
            </v-card-actions>
        </v-sheet>

        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-on="on"
                @click="reviewDialog"
                x-small
                outlined
            >Complete order
            </v-btn>
        </template>
    </v-dialog>
</template>

<script>
    import api from "../../services/api"
    import DateTimePicker from "../dateTimePicker/dateTimePicker"
    import {bus} from "@/main"
    import UploadFile from "@/components/fileManagement/uploadFile"

    export default {
        name: "completeOrder",
        components: {UploadFile, DateTimePicker},
        props: ['order'],
        data() {
            return {
                confirmComment: 'Your order has been fulfilled',
                originalOrder: [],
                article: {},
                confirmationLoading: false,
                reviewDialog: false,
                infoIsCorrect: false,
                validation: {
                    titleRules: [
                        v => !!v || 'Titel er påkrævet',
                        v => (v && v.length >= 5) || 'Min.  5 chars',
                    ],
                    anchorRules: [
                        v => !!v || 'Anchor required',
                        v => (v && v.length >= 2) || 'Min. 2 chars',
                    ],
                    urlRules: [
                        v => !!v || 'URL required',
                        v => !!v.match(new RegExp(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi)) || 'Invalid URL',
                    ],
                    dateRules: [
                        v => !!v || 'Publish date required',
                        v => (v && v.length <= 10) || 'Name must be less than 10 characters',
                    ],
                },
                articleUrl:'',
            }
        },
        methods: {
            completeOrder() {
                this.confirmationLoading = true
                api.put('/orders/complete', {
                    order: this.order,
                    articleUrl:this.articleUrl,
                    comment: this.confirmComment,
                })
                    .then(response => {
                        console.log(response.data)
                        if (response.status === 200) {

                            this.confirmationLoading = false
                            this.reviewDialog = false
                            if (response.data.message === "orders/published") {
                                this.$emit('orderCompleted')
                            }
                            this.$store.dispatch('toasts/toastSuccess', 'Order completed')
                            bus.$emit('orderUpdate', true)
                        }
                    })
                    .catch(err => {
                        this.$store.dispatch('toasts/toastWarning', 'An error occurred')
                        this.confirmationLoading = false
                        console.log(err)
                    })
            },
            newTab(fileLocation) {
                window.open(fileLocation, '_blank');
            },
            log(obj) {
                console.log(obj)
            }
        },
        computed:{
            articleURLInserted(){
                return this.articleUrl.match(new RegExp(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi))
            }
        },
        created() {
        }
    }
</script>

<style scoped>

</style>