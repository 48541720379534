<template>
    <v-tabs-items
        v-model="tabs"
        class="transparent"
        style="width:100%"
        :dark="dark"
    >
        <v-tab-item>
            <v-card
                elevation="0"
                width="100%"
                id="loginDialog"
                class="transparent"
            >
                <v-card-text>
                    {{$router.currentRoute.query.mode}}
                    <v-form lazy-validation>
                        <v-text-field
                            :rules="[emailValid]"
                            @keydown.enter="login()"
                            autocomplete="username"
                            placeholder="E-mail"
                            name="email"
                            v-model="email"
                        ></v-text-field>
                        <v-text-field
                            :append-icon="hidePass ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:append="hidePass = !hidePass"
                            :type="hidePass ? 'password' : 'text'"
                            @keydown.enter="login()"
                            autocomplete="current-password"
                            placeholder="Password"
                            name="password"
                            v-model="password"
                        ></v-text-field>
                        <span class="red--text" v-if="!!error">Fejl: {{error}}</span>
                    </v-form>
                </v-card-text>
                <v-sheet class="pl-4 pr-4 transparent">
                    <v-btn :loading="loading" @click="login()" class="col-12" color="primary"
                           elevation="0">
                        <v-icon class="mr-2" left>mdi-login</v-icon>
                        Login
                    </v-btn>
                    <p class="text-center subtitle-2"></p>
                    <v-btn
                        class="col-12 mb-4"
                        color="accent"
                        elevation="0"
                        link
                        href="https://kaboozt.com"

                    >
                        <v-icon class="mr-2" left>mdi-account-plus</v-icon>
                        Don't have an account?
                    </v-btn>
                    <v-btn @click="tabs = 1" class="col-12" x-small text>Forgot password</v-btn>
                </v-sheet>
            </v-card>
        </v-tab-item>

        <v-tab-item>
            <v-card
                elevation="0"
                height="330px"
                min-width="360px"
                width="100%"
                v-if="!passwordResetSent"
                class="transparent"

            >
                <v-card-title>Reset password</v-card-title>
                <v-card-text>
                    Send password reset link to:
                    <v-text-field
                        :error-messages="this.passwordResetError"
                        :hint="this.passwordResetError"
                        solo-inverted
                        :value="emailReset"
                        label="E-mail"
                        name="email"
                        v-model="emailReset"
                        :loading="resetLinkLoad"
                        @keydown.enter="sendResetLink"
                    />
                </v-card-text>
                <v-card-actions class="pa-4">
                    <v-btn @click="tabs = 0" color="red" dark
                           text>back
                    </v-btn>
                    <v-spacer/>
                    <v-btn
                        :loading="resetLinkLoad"
                        @click="sendResetLink()"
                        color="primary"
                    >Send link
                    </v-btn>
                </v-card-actions>
            </v-card>
            <v-card
                v-else
                class="transparent"
                elevation="0"
                width="100%"
                min-width="360px"
                height="330px"
            >
                <v-card-title>Reset password</v-card-title>
                <v-card-text class="text-center">
                    <v-icon large color="green">mdi-check</v-icon>
                    <br>A link to reset your password has been sent to the inbox of {{email}}
                    <br>Check your spam folder as well.
                    <br>
                    <v-btn @click="tabs = 0" color="primary" class="mt-4">Back to login</v-btn>
                </v-card-text>
            </v-card>
        </v-tab-item>
    </v-tabs-items>
</template>

<script>

    export default {
        name: "loginForm",
        props: ['dark'],
        computed: {
            darkTheme: {
                get() {
                    return typeof this.$props.dark == 'undefined' ? this.$vuetify.theme.dark : this.$props.dark
                }
            },
            emailValid: function () {
                if (this.email.trim() === '') {
                    return true
                }
                const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(this.email.trim()) || (`Indtast e-mail`)
            }
        },
        data: () => ({
            email: '',
            password: '',
            hidePass: true,
            user: '',
            firebase: '',
            loading: false,
            rules: {
                email: value => {
                    const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                },
            },
            error: false,
            tabs: 0,
            emailReset: '',
            resetLinkLoad: false,
            passwordResetSent: false,
            passwordResetError: ''
        }),
        methods: {
            login() {
                this.loading = true
                let email = this.email.trim()
                let password = this.password
                this.$store.dispatch('auth/login', {email, password})
                    .then(response => {
                        if (response.data.authorized === 1) {
                            this.$emit('loggedIn', true)
                            window.location.href = '/dashboard'
                            //this.$router.push({path: '/'})
                        }
                    })
                    .catch(err => {
                        switch (err.code) {
                            case 'auth/user-not-found':
                                this.error = 'User not found'
                                break;
                            case 'auth/invalid-email':
                            case 'auth/wrong-password':
                                this.error = 'Username or password invalid'
                                break;
                            case 'auth/user-unauthorized':
                                this.error = 'User account unauthorized or inactive'
                                break;
                            default:
                                this.error = false
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            sendResetLink() {
                this.$store.dispatch('auth/sendPasswordReset', this.emailReset)
                    .then(response => {
                        this.passwordResetSent = true
                    })
                    .catch(error => {
                        switch (error) {
                            case 'Firebase: Error (auth/too-many-requests).':
                                this.passwordResetError = 'Too many re-auth requests'
                                break;
                            case 'Firebase: Error (auth/user-not-found).':
                                this.passwordResetError = 'E-mail not found or invalid.'
                                break;
                            default:
                                this.passwordResetError = 'Error in password reset, try again'
                                break;
                        }
                    })
                this.resetLoad = false
            }
        },
    }
</script>

<style>
    input[type="text"], input[type="password"] {
        padding-left: 8px !important;
        font-size: 16px;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:focus {
        transition: background-color 600000s 0s, color 600000s 0s;
    }
</style>