/**
 * Date object extensions
 * --------------------
 * Checks if a valid date is provided
 * Explanation:
 * An invalid date object returns NaN for getTime()
 * and NaN is the only object not strictly equal to itself.
 *
 * It's a hacky solution, but works wonders.
 *
 * @returns {boolean}
 */

if (!Date.prototype.isValid) {
    Date.prototype.isValid = function () {
        return this.getTime() === this.getTime();
    }
}

/**
 * Days From Now extensions
 * -----------------------
 * Simply takes a given date, adds 14 days and returns YYYY-mm-dd
 * @param {int} days
 * @returns {string} YYYY-mm-dd
 */
Date.prototype.daysFromNow = function (days, addLeadingZero = false) {
    let fn = new Date();
    fn.setDate(fn.getDate() + days);
    if (addLeadingZero) {
        let month = (fn.getMonth() + 1).toString().length === 1 ? '0' + (fn.getMonth() + 1) : (fn.getMonth() + 1)
        let day = fn.getDate().toString().length === 1 ? '0' + fn.getDate() : fn.getDate()
        return fn.getFullYear() + '-' + month + '-' + day
    } else {
        let month = fn.getMonth() + 1
        let day = fn.getDate()
        return fn.getFullYear() + '-' + month + '-' + day
    }
}

/**
 * Just-gimme-the-damn-numbers-like-i-want-them Polyfill
 * -----------------------------------------------------
 * * USAGE:
 * const d = new Date().toRegular()
 * console.log(`${c.d}-${c.m}-${c.Y} kl. ${c.H}:${c.i}:${c.s}`)
 * -----------------------------------------------------
 * @param addLeadingZero - Take a wild fucking guess
 * @returns {{s: seconds, d: day, H: hour, Y: year, i: minute, m: month}|boolean}
 */
Date.prototype.toRegular = function (addLeadingZero = true) {
    if (!this.isValid()) {
        return false
    }
    let dd = this.getDate(),
        mm = this.getMonth() + 1,
        yyyy = this.getFullYear(),
        h = this.getHours(),
        i = this.getMinutes(),
        s = this.getSeconds();
    if (addLeadingZero === true) {
        dd = dd < 10 ? '0' + dd : dd
        mm = mm < 10 ? '0' + mm : mm
        h = h < 10 ? '0' + h : h
        i = i < 10 ? '0' + i : i
        s = s < 10 ? '0' + s : s
    }
    return {d: dd, m: mm, Y: yyyy, H: h, i: i, s: s}
}

/**
 * Polyfill for formatting a Date object into a localized string.
 *
 * This function adds a `toLocalFormat()` method to the JavaScript `Date` prototype.
 * The method returns a string representation of the date and time in the specified
 * format and locale.
 *
 * @param {string} format - The format of the output string. Can be one of 'date', 'time', or 'datetime'.
 *                           'date' outputs only the date part.
 *                           'time' outputs only the time part.
 *                           'datetime' outputs both date and time parts.
 * @param {string} [locale=navigator.language] - The locale to use for formatting the date and time.
 *                                               If not specified, the user's browser language is used.
 *
 * @returns {string} A string representing the formatted date and time.
 *
 * @example
 * const date = new Date();
 * console.log(date.toLocalFormat('datetime')); // Outputs: 2023-05-26, 12:35:20 PM
 * console.log(date.toLocalFormat('date'));     // Outputs: 2023-05-26
 * console.log(date.toLocalFormat('time'));     // Outputs: 12:35:20 PM
 */
if (!Date.prototype.toLocalFormat) {
    Date.prototype.toLocalFormat = function (format, locale) {
        locale = locale || navigator.language;

        if (format === 'date') {
            const options = {year: 'numeric', month: '2-digit', day: '2-digit'};
            return new Intl.DateTimeFormat(locale, options).format(this);
        } else if (format === 'time') {
            const options = {hour: '2-digit', minute: '2-digit', second: '2-digit'};
            return new Intl.DateTimeFormat(locale, options).format(this);
        } else {
            const options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            };
            return new Intl.DateTimeFormat(locale, options).format(this);
        }
    };
}

function randomId() {
    let r = (Math.random()).toString(36),
        a = (Math.random()).toString(36),
        n = (Math.random()).toString(36),
        d = r.substr(2, r.length - 2),
        o = a.substr(2, a.length - 2) + n.substr(2, n.length - 2),
        m = d + o
    return m;
}

/**
 * Object foreach polyfill
 */
if (!Object.prototype.forEach) {
    Object.defineProperty(Object.prototype, 'forEach', {
        value: function (callback) {
            // Loop through each key in the object
            for (const key in this) {
                // Check if the key belongs to the object itself (not inherited)
                if (this.hasOwnProperty(key)) {
                    // Call the provided callback function with key and value arguments
                    callback(key, this[key]);
                }
            }
        },
        enumerable: false, // Ensure the method is not enumerable
        writable: true, // Allow the method to be overwritten
        configurable: true // Allow the method to be redefined or removed
    });
}

// This is a polyfill that adds a new method 'rtrim' to the String prototype.
// This method removes all occurrences of the input string 's' from the end of the current string.
//
// Parameter:
// 's' is a string that should be removed from the end of the current string.
//
// Returns:
// The function returns a new string that is a copy of the original string,
// but with all occurrences of 's' removed from the end.

String.prototype.rtrim = function(s) {
    return this.replace(new RegExp(s + "*$"),'');
};

function isNullOrUndefined(val){
    return typeof val === 'undefined' || typeof val === null ? true : false
}