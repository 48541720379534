<template>
    <div>
        <page-header :content-left="user.name"/>
        <v-container fluid>
            <v-row>
                <v-col cols="4">
                    <user-profile-info :user="user"/>
                    <v-sheet class="mb-4" elevation="2">
                        <v-toolbar elevation="0" color="primary" dark>
                            <v-toolbar-title>
                                <v-hover v-slot="{ hover }">
                                    <v-avatar :size="avatarSize">
                                        <v-img
                                            :src="'https://Kaboozt.com/'+user.company.logo"
                                            @load="onAvatarLoad"
                                            style="display:none;"
                                            :height="avatarSize"
                                        />
                                        <v-skeleton-loader type="image" :width="avatarSize"/>
                                        <v-img
                                            :src="'https://Kaboozt.com/'+user.company.logo"
                                            :height="avatarSize"
                                        />
                                        <div
                                            class="d-flex justify-center align-center transition-fast-in-fast-out  white--text"
                                            style="height: 100%;width:100%;position:absolute;bottom:0;transition: 225ms ease;background:rgba(0,0,0,0.25)"
                                            :style="hover ? 'opacity:1':'opacity:0'"
                                        >
                                            <company-logo-upload @uploadSuccess="getUser"/>
                                        </div>
                                    </v-avatar>
                                </v-hover>
                                <span class="ml-2">{{user.company.name}}</span>
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-simple-table dense>
                            <tbody>
                            <tr>
                                <td>Main office</td>
                                <td>{{user.company.city + ', '+ user.company.country}}</td>
                            </tr>
                            <tr>
                                <td>Contact</td>
                                <td><a :href="'mailto:'+user.mail" style="text-decoration: none;">
                                    <v-icon small>mdi-email-outline</v-icon>
                                    {{user.name}}</a></td>
                            </tr>
                            <tr>
                                <td>Main office</td>
                                <td><span>{{user.company.postCode}} {{user.company.city}}</span><br><span>{{user.company.street}}</span><br><span>DK-{{user.company.cvr}}</span></td>
                            </tr>
                            <tr>
                                <td>Website</td>
                                <td><span>{{user.company.website}}</span></td>
                            </tr>
                            <tr>
                                <td>Industry</td>
                                <td><span>{{user.company.business}}</span></td>
                            </tr>
                            <tr>
                                <td>Type</td>
                                <td><span>{{user.company.type}}</span></td>
                            </tr>
                            <tr>
                                <td>Founded</td>
                                <td><span>{{user.company.founded}}</span></td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-sheet>
                    <discount-widget class="mb-4"/>
                </v-col>
                <v-col cols="8">
                    <user-order-history/>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import api from "../services/api";
    import CompanyLogoUpload from "../components/fileManagement/companyLogoUpload";
    import userManager from "@/services/userManager";
    import DiscountWidget from "@/components/sites/discounts/discountWidget";
    import UserOrderHistory from "@/components/user/userOrderHistory";
    import CompanyContacts from "@/components/UI/profileWidgets/companyContacts";
    import PageHeader from "@/components/UI/elements/pageHeader";
    import UserProfileInfo from "@/components/user/userProfileInfo";

    export default {
        name: "Profile",
        components: {UserProfileInfo, PageHeader, CompanyContacts, UserOrderHistory, DiscountWidget, CompanyLogoUpload},
        data() {
            return {
                editing: false,
                user: {
                    company: {}
                },
                background: '',
                activeTab: 0,
                avatarLoaded: false,
                items: []
            }
        },
        methods: {
            getUser() {
                userManager.getUser(this.$store.state.auth.user.id)
                    .then(response => {
                        this.user = response.data
                    })
                    .catch(err => {
                        this.$store.dispatch('toasts/toastError', err.response.message)
                    })
            },
            updateUserInfo() {
                api.put('users/' + this.$store.state.auth.user.id, this.user)
                    .then(response => {
                        this.$store.dispatch('auth/refreshUserInfo')
                        this.$store.dispatch('toasts/toastSuccess', 'User information updated')
                    })
                    .catch(err => {
                        this.$store.dispatch('toasts/toastError', 'Error updating user information')
                    })
            },
            randomBackground() {
                let backgrounds = [
                    "/img/59-1920x420.webp",
                    "/img/123-1920x420.webp",
                    "/img/186-1920x420.webp",
                    "/img/221-1920x420.webp",
                    "/img/259-1920x420.webp",
                    "/img/658-1920x420.webp",
                    "/img/823-1920x420.webp",
                    "/img/1070-1920x420.webp",
                ];
                return backgrounds[Math.floor(Math.random() * backgrounds.length)];
            },
            onAvatarLoad() {
                this.avatarLoaded = true
            },

        },
        mounted() {
            this.$store.dispatch('auth/refreshUserInfo')
            this.getUser()
            this.background = this.randomBackground()
        },
        computed: {
            initials: function () {
                const name = this.$store.state.auth.user.name
                const parts = name.split(' ')
                return parts[0].slice(0, 1) + parts[parts.length - 1].slice(0, 1)
            },
            avatarSize: function () {
                let size = 64
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                    case 'sm':
                        size = 48
                        break;
                    case 'md':
                        size = 48
                        break;
                    case 'lg':
                    case 'xl':
                        size = 48
                        break;

                }
                return size
            }
        }
    }
</script>

<style>
</style>